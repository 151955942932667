import { Col, Row } from 'antd'
import Button from 'app/components/UI-Elements/Controls/buttons/Button'
import { Routes } from 'app/constants/Routes'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import ProductName from './ProductName'

const selectedParentItem = ({ status, response, parent_id }: IProductRequestItem) =>
  status == 'selected' && response === 'confirmed' && !parent_id

const Header: React.FC<MemberProps<IPurchasingRequest>> = ({ member: purchasingRequest }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const onClick = () => {
    navigate(Routes.OrderRequest.member(String(purchasingRequest.id)).finalize)
  }

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <h5 className="text-black mb-0">{formatMessage({ id: 'rental-show-action-title' })}</h5>
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col span={16}>{formatMessage({ id: 'rental-show-action-description' })}</Col>
          <Col span={8} className="text-right">
            <Button
              onClick={onClick}
              className="ant-btn-secondary-border"
              title={formatMessage({ id: 'rental-show-action-button-title' })}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <h5 className="text-black mb-0">{formatMessage({ id: 'rental-show-machines-title' })}</h5>
      </Col>
      <Col span={24}>
        {purchasingRequest.product_request_items
          ?.filter(selectedParentItem)
          ?.map((requestItem) => <ProductName key={requestItem.id} member={requestItem} />)}
      </Col>
    </Row>
  )
}

export default Header
