import { Badge, Menu } from 'antd'
import * as React from 'react'

const { Item } = Menu

type Props = {
  icon?: React.ReactNode
  children: React.ReactNode
  badgeCount?: number
  sidebarOpen?: boolean
}

const MenuItemWithBadge: React.FC<Props> = ({
  icon,
  children,
  badgeCount,
  sidebarOpen,
  ...others
}) => {
  return (
    <Item {...others} className="menu-item-with-badge">
      {sidebarOpen ? (
        <>
          {icon}
          <span>
            <Badge offset={[20, 5]} count={badgeCount}>
              {children}
            </Badge>
          </span>
        </>
      ) : (
        <Badge offset={[5, 30]} count={badgeCount}>
          {icon}
          <span>{children}</span>
        </Badge>
      )}
    </Item>
  )
}

export default MenuItemWithBadge
