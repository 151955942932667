import { Layout } from 'antd'
import { useCurrent } from 'app/lib/hooks/useCurrent'
import { toggleSidebar } from 'app/redux/actions/AppActions'
import { MenuInfo } from 'rc-menu/lib/interface'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import Items from './Items'

const Sider: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const sidebarOpen = useSelector<AppState, boolean>((state) => state.appReducer.sidebarOpen)
  const { requested_requests_count } = useCurrent<IUser>('User')
  const { rental_park_opt_in } = useCurrent<IContact>('Contact')

  const onSelect = (param: MenuInfo) => {
    const currentLocation = location.pathname
    const desiredLocation = param.key
    if (currentLocation !== desiredLocation) {
      navigate(`${param.key}`)
    }
  }

  const onCollapse = (): void => {
    dispatch(toggleSidebar(!sidebarOpen))
  }

  const defaultValue = (): string => {
    return `/${location.pathname.split('/')[1]}`
  }

  return (
    <Layout.Sider width={240} collapsible collapsed={!sidebarOpen} onCollapse={onCollapse}>
      <Items
        onSelect={onSelect}
        defaultValue={defaultValue()}
        sidebarOpen={sidebarOpen}
        rentalParkOptIn={rental_park_opt_in}
        requestedCount={requested_requests_count}
      />
    </Layout.Sider>
  )
}

export default Sider
