import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Skeleton } from 'antd'
import { ColProps } from 'antd/lib/col'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../Controls/buttons/Button'

interface Props extends Omit<ColProps, 'title'> {
  render?: boolean
  loading?: boolean
  card?: boolean
  title?: React.ReactNode | string
  backIcon?: boolean
}

const KCol: React.FC<Props> = ({
  loading = false,
  render = true,
  card = false,
  title,
  backIcon = false,
  ...others
}) => {
  const navigate = useNavigate()
  const getTitle = () => {
    return (
      <Choose>
        <When condition={backIcon}>
          <span>
            <Button className="text-lg" type="link" onClick={() => navigate(-1)}>
              <ArrowLeftOutlined className="mr-3" />
            </Button>
            <span>{title}</span>
          </span>
        </When>
        <Otherwise>{title}</Otherwise>
      </Choose>
    )
  }

  return (
    <Choose>
      <When condition={loading}>
        <Choose>
          <When condition={card}>
            <Col {...others}>
              <KCard bordered={false} title={!loading && getTitle()}>
                <Skeleton active={loading} />
              </KCard>
            </Col>
          </When>
          <Otherwise>
            <Col {...others}>
              <Skeleton active={loading} />
            </Col>
          </Otherwise>
        </Choose>
      </When>
      <When condition={render}>
        <Choose>
          <When condition={card}>
            <Col {...others}>
              <KCard bordered={false} title={!loading && getTitle()}>
                {others.children}
              </KCard>
            </Col>
          </When>
          <Otherwise>
            <Col {...others} />
          </Otherwise>
        </Choose>
      </When>
      <Otherwise>{null}</Otherwise>
    </Choose>
  )
}

export default KCol
