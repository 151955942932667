import * as React from 'react'
import { Input, Form, Select, Col } from 'antd'
import { FormInstance } from 'antd/lib/form'
import FormItem from 'app/components/UI-Elements/Forms/_FormItem'
import { ToolBox } from 'app/lib/hooks/toolBox'

type Props = {
  form?: FormInstance
  categoryAttributes: ICategoryAttribute[]
}

const CategoryAttributesFormList: React.FC<Props> = ({ categoryAttributes }) => {
  const { humanize } = ToolBox.useString()

  const renderInput = (categoryAttribute: ICategoryAttribute) => {
    switch (categoryAttribute.attribute_type) {
      case 'list':
        return (
          <Select
            options={categoryAttribute.feature_values.map((str) => ({
              label: humanize(str),
              value: str
            }))}
          />
        )
      default:
        return <Input />
    }
  }

  const getLabel = (categoryAttribute: ICategoryAttribute) => {
    return categoryAttribute.question_text
  }

  return (
    <Form.List name="attribute_values_attributes">
      {() =>
        categoryAttributes.map((categoryAttribute, index) => (
          <Col span={24} key={`question-${index}-${categoryAttribute.id}`}>
            <FormItem
              name={[index, 'attribute_detail_id']}
              className="d-none"
              initialValue={categoryAttribute.attribute_detail_id}>
              <Input hidden />
            </FormItem>
            <FormItem label={getLabel(categoryAttribute)} name={[index, 'display_value']}>
              {React.cloneElement(renderInput(categoryAttribute))}
            </FormItem>
          </Col>
        ))
      }
    </Form.List>
  )
}

export default CategoryAttributesFormList
