import * as React from 'react'
import KCard from '../UI-Elements/DataDisplay/KCard'
import { FormattedHTMLMessage } from 'react-intl'

const GeneralTermsCard: React.FC = () => {
  return (
    <KCard>
      <p className="text-gray mb-0">
        <FormattedHTMLMessage id="general.terms" />
      </p>
    </KCard>
  )
}

export default GeneralTermsCard
