import { InfoCircleOutlined } from '@ant-design/icons'
import { Select, Spin } from 'antd'
import { SelectProps } from 'antd/es/select'
import Tooltip from 'app/components/UI-Elements/Feedback/Tooltip'
import * as React from 'react'

const { Option } = Select

interface Props<T> extends SelectProps<T> {
  entries: T[]
  value?: T
  selectValueBy?: string
  selectValueByFallback?: string
  optionDescriptions?: string[]
  onChange?: (record: T) => void
  fallbackOptionLabelProp?: string
  customOptionRenderer?: (record: T) => React.ReactNode
  dropDownFooter?: React.ReactNode
  remote?: boolean
}

function SimpleSelect<T extends { id?: number | string; [key: string]: string | number | any }>({
  defaultActiveFirstOption,
  onBlur,
  remote,
  notFoundContent,
  onFocus,
  dropdownMatchSelectWidth = false,
  open,
  dropDownFooter,
  onSearch,
  onChange,
  allowClear,
  showSearch,
  value,
  className = '',
  optionDescriptions,
  disabled = false,
  loading = false,
  optionLabelProp,
  customOptionRenderer,
  fallbackOptionLabelProp,
  entries,
  placeholder,
  selectValueBy = 'id',
  selectValueByFallback,
  id
}: Props<T>) {
  const getOptionDescriptionFor = (index: number) => {
    return optionDescriptions[index]
  }

  const filterOption = (input: string, option: any): boolean => {
    return (
      remote ||
      entries
        .find((entry) => entry.id === option.value)
        [optionLabelProp].toLowerCase()
        .includes(input.toLowerCase())
    )
  }

  const onChangeEvent = (value: number) => {
    onChange(entries.find((entry) => entry.id === value))
  }

  const renderOptions = (): React.ReactNode => {
    return entries.map((entry, index) => (
      <Option key={`${entry.id}-${index}`} value={entry.id}>
        {customOptionRenderer ? (
          customOptionRenderer(entry)
        ) : (
          <div>
            <span>{entry[optionLabelProp]?.trim() || entry[fallbackOptionLabelProp]}</span>
            {optionDescriptions && (
              <Tooltip placement="left" title={getOptionDescriptionFor(index)}>
                <InfoCircleOutlined className="float-right option-info-icon" />
              </Tooltip>
            )}
          </div>
        )}
      </Option>
    ))
  }

  const getValue = (): string | number => {
    return (
      value?.id ||
      entries.find((entry) => {
        if (entry[selectValueBy]) {
          return entry[selectValueBy] === value
        } else if (entry[selectValueByFallback]) {
          return entry[selectValueByFallback] === value
        }
        return false
      })?.id
    )
  }

  return (
    <Select
      defaultActiveFirstOption={defaultActiveFirstOption}
      onBlur={onBlur}
      notFoundContent={notFoundContent}
      onFocus={onFocus}
      open={open}
      onSearch={onSearch}
      showSearch={showSearch}
      allowClear={allowClear}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
      loading={loading}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      value={getValue()}
      filterOption={filterOption}
      onChange={onChangeEvent}
      dropdownRender={(menu) => (
        <>
          <Spin spinning={loading}>{menu}</Spin>
          {dropDownFooter}
        </>
      )}
      getPopupContainer={(target) => target}
      id={id}>
      {renderOptions()}
    </Select>
  )
}

export default SimpleSelect
