import { CATEGORY_ATTRIBUTES_URL } from '../../constants/Url'
import Api from '../../lib/api'
import BaseNormalizer from '../reducers/normalizers/BaseNormalizer'
import { parseError } from 'app/lib/handler'

export const fetchCategoryAttributes = (categoryId: number): Promise<ICategoryAttribute[]> => {
  return new Promise((resolve, reject) => {
    Api.get<CollectionSerializerResponse<ICategoryAttribute>>(CATEGORY_ATTRIBUTES_URL(categoryId))
      .then((response) => {
        resolve(BaseNormalizer.mapAttributesOfCollection(response.data.data))
      })
      .catch((error) => {
        reject(parseError(error))
      })
  })
}
