/* eslint-disable react/display-name */
import { Input as AntInput, InputRef } from 'antd'
import * as React from 'react'
import { WithIntlProps } from 'react-intl'

export interface CustomInputProps extends WithIntlProps<any> {
  type?: string
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  placeholder?: string
  prefix?: React.ReactElement
  className?: string
  required?: boolean
  allowClear?: boolean
  defaultValue?: string | number
  suffix?: React.ReactNode
  addonBefore?: React.ReactNode
}

const Input: React.FC<CustomInputProps> = React.forwardRef(
  (
    {
      addonBefore,
      type = 'text',
      disabled = false,
      onChange,
      placeholder = '',
      required = false,
      className = '',
      allowClear = false,
      ...others
    },
    ref: React.Ref<InputRef>
  ) => {
    const { ...props } = others

    return (
      <AntInput
        addonBefore={addonBefore}
        type={type}
        disabled={disabled}
        placeholder={`${placeholder} ${required ? '*' : ''}`}
        onChange={onChange}
        allowClear={allowClear}
        className={className}
        ref={ref}
        {...props}
      />
    )
  }
)

export default Input
