import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Col, Row, Space } from 'antd'
import * as React from 'react'
import { useIntl } from 'react-intl'

import ProductRequestItemListItem from './ListItem'

const ProductRequestItemList: React.FC<CollectionProps<IProductRequestItem>> = ({ collection }) => {
  const { formatMessage } = useIntl()

  if (!collection.length) {
    return null
  }

  const response = collection[0].response

  return (
    <Space direction="horizontal" align="start" size="large">
      <Choose>
        <When condition={response === 'confirmed'}>
          <CheckCircleOutlined style={{ fontSize: '20px' }} className="success" />
        </When>
        <When condition={response === 'declined'}>
          <CloseCircleOutlined style={{ fontSize: '20px' }} className="danger" />
        </When>
      </Choose>
      <Row gutter={[0, 24]}>
        <If condition={response === 'confirmed' || response === 'declined'}>
          <Col key="header" span={24}>
            <h4 className="mb-0">
              {formatMessage({ id: `change-request-message-${response}-title` })}
            </h4>
          </Col>
        </If>
        {collection.map((item) => (
          <Col key={item.id} span={24}>
            <ProductRequestItemListItem member={item} />
          </Col>
        ))}
      </Row>
    </Space>
  )
}

export default ProductRequestItemList
