import { allLocales } from 'app/constants/Enums'
import BrowserService from './BrowserService'
import EnvironmentService from './EnvironmentService'

class IntlService {
  static isLanguageValid(langCode: string) {
    return allLocales.filter((locale) => locale.language === langCode).length > 0
  }

  static getLocaleWithHost(locale: string) {
    return `${locale}_${
      EnvironmentService.isDevelopment() ? 'DE' : BrowserService.getDomainName().toUpperCase()
    }`
  }
}

export default IntlService
