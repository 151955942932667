import PageWrapper from 'app/components/UI-Elements/Layout/PageWrapper'
import { Routes } from 'app/constants/Routes'
import ErrorNotFound from 'app/pages/404/ErrorNotFound'
import { getBaseRoutePath, retry } from 'app/util/Utilities'
import { lazy, Suspense } from 'react'
import * as React from 'react'
import { Navigate, Route, Routes as Switch } from 'react-router-dom'

import RentalRequestRoutes from './RentalRequest'

const UserPage = lazy(() => retry(() => import('../pages/Users/Views')))
const FaqPage = lazy(() => retry(() => import('../pages/Faq/Views')))
const RolesPage = lazy(() => retry(() => import('../pages/Roles/Views')))
const DashboardPage = lazy(() => retry(() => import('../pages/Dashboard/Views/Dashboard')))
const FleetReviewRoutes = lazy(() => retry(() => import('./FleetReview/Common')))
const RentalParkListPage = lazy(() => retry(() => import('../pages/RentalPark/Views/list')))
const RentalParkTimelinePage = lazy(() => retry(() => import('../pages/RentalPark/Views/timeline')))

const MainRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        path={Routes.Default}
        element={<Navigate to={Routes.Dashboard.collection().index} />}
        key="redirection"
      />
      <Route
        path={Routes.Dashboard.collection().index}
        element={<DashboardPage />}
        key="dashboard-page"
      />
      <Route
        path={Routes.Role.collection().index}
        element={
          <Suspense fallback={null}>
            <RolesPage />
          </Suspense>
        }
        key="roles-page"
      />
      <Route
        path={Routes.User.collection().index}
        element={
          <PageWrapper>
            <UserPage />
          </PageWrapper>
        }
        key="user-page"
      />
      <Route
        path={Routes.FAQ.collection().index}
        element={
          <PageWrapper>
            <FaqPage />
          </PageWrapper>
        }
        key="faq-page"
      />
      <Route
        path={Routes.RentalPark.collection().liste}
        element={
          <PageWrapper>
            <RentalParkListPage />
          </PageWrapper>
        }
        key="rental-park-list-page"
      />
      <Route
        path={Routes.RentalPark.collection().timeline}
        element={
          <PageWrapper>
            <RentalParkTimelinePage />
          </PageWrapper>
        }
        key="rental-park-timeline-page"
      />
      <Route
        path={getBaseRoutePath(Routes.Fleet.Base)}
        element={
          <Suspense fallback={null}>
            <FleetReviewRoutes />
          </Suspense>
        }
        key="fleet-review"
      />
      <Route
        key="rental-request-routes"
        path={getBaseRoutePath(Routes.OrderRequest.Base)}
        element={<RentalRequestRoutes />}
      />
      <Route path={getBaseRoutePath('')} element={<ErrorNotFound />} />
    </Switch>
  )
}

export default MainRoutes
