import { useLocation } from 'react-router-dom'

export const useOrderRequest = () => {
  const location = useLocation()
  // TODO: remove all the occurences of isLegacy
  const isLegacy = () => location.pathname.includes('rental_request')

  return {
    isLegacy
  }
}
