import { InputNumber } from 'antd'
import { FormInstance, FormItemProps } from 'antd/lib/form'
import CreateProductForm from 'app/components/Core/Product/Form'
import ModalView from 'app/components/UI-Elements/Feedback/modals/Modal/ModalView'
import FormItem from 'app/components/UI-Elements/Forms/_FormItem'
import { useState } from 'react'
import * as React from 'react'
import { useIntl } from 'react-intl'

import Products from './Products'

// TODO: Refactor this type after rental request migration
type ProductItem = Partial<
  Pick<
    IProduct,
    'category_segment_id' | 'category_id' | 'category_segment_name' | 'supplier_station_id'
  >
>

type Props = {
  extraOptions?: IProduct[]
  openNewProductModal?: () => void
  required?: boolean
  name?: FormItemProps['name']
  form?: FormInstance
  index?: number
  formListName?: string
  item: ProductItem
  product?: Pick<IProduct, 'id' | 'name' | 'data_sheet_url'>
}

const ProductsModal: React.FC<Props> = ({
  extraOptions: lastProducts,
  index,
  item,
  formListName,
  product,
  ...others
}) => {
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState<boolean>(false)
  const [newProducts, setNewProducts] = useState<IProduct[]>([])
  const { form } = others

  const toggleOpen = () => setOpen(!open)

  const extra: IProduct[] = [...lastProducts, ...newProducts]

  const onNewProductFromSubmitted = (product: IProduct) => {
    toggleOpen()
    setNewProducts([...newProducts, product])
    onProductChange(product)
  }

  const onProductChange = (product: IProduct) => {
    form.setFields([
      {
        name: [formListName, index, 'product_id'],
        value: product.id
      },
      {
        name: [formListName, index, 'products_supplier_id'],
        value: product.products_supplier_id
      }
    ])
  }

  return (
    <>
      <Products
        categoryId={item.category_id}
        supplierStationId={item.supplier_station_id}
        extraOptions={extra}
        onChange={onProductChange}
        openNewProductModal={toggleOpen}
        product={product}
        {...others}
        name={[index, 'product_id']}
      />
      <FormItem name={[index, 'products_supplier_id']} hidden>
        <InputNumber />
      </FormItem>
      <ModalView
        footer={null}
        title={formatMessage({ id: 'rental.request.new.product.title' })}
        onClose={toggleOpen}
        open={open}>
        <CreateProductForm onSubmit={onNewProductFromSubmitted} product={item} />
      </ModalView>
    </>
  )
}

export default ProductsModal
