import { CSSProperties } from 'react'
import * as React from 'react'

type Props = {
  type: string
  extension?: 'svg' | 'jpeg' | 'png' | 'jpg'
  styles?: CSSProperties
  className?: string
}

const CustomIcon: React.FC<Props> = ({ className = '', type, extension = 'svg', styles = {} }) => {
  return (
    <img src={`/assets/icons/${type}.${extension}`} alt=" " style={styles} className={className} />
  )
}

export default CustomIcon
