import { dayjs } from '@2wunder/klarx-tool'
import * as React from 'react'
import { useIntl } from 'react-intl'

const DeliveryRequestItemDate: React.FC<MemberProps<IDeliveryRequestItem>> = ({
  member: deliveryRequestItem
}) => {
  const { formatMessage } = useIntl()

  const { item } = deliveryRequestItem

  const getDate = () =>
    item.delivery_type === 'delivery'
      ? dayjs(item.delivery_date).toLocaleDate()
      : dayjs(item.pick_up_date).toLocaleDate()

  return (
    <>
      {formatMessage(
        { id: 'delivery-request-item-name' },
        {
          name: item.name,
          date: getDate()
        }
      )}
    </>
  )
}

export default DeliveryRequestItemDate
