import { ReactNode } from 'react'
import * as React from 'react'

type Props = {
  children: ReactNode
}

const PageWrapper: React.FC<Props> = ({ children }) => {
  return <div className="page-wrapper">{children}</div>
}

export default PageWrapper
