import { Col, Row } from 'antd'
import * as React from 'react'

import ProductRequestItemList from '../ProductRequestItem/List'
import ChangeRequestStatus from '../Status'

const ChangeRequestAnsweredList: React.FC<CollectionProps<IChangeRequest>> = ({ collection }) => {
  const productRequestItems = collection
    .map((changeRequest) => changeRequest.product_request_items)
    .flat()

  const confirmedRequests = productRequestItems.filter(
    (productRequestItem) => productRequestItem.response === 'confirmed'
  )

  const declinedRequests = productRequestItems.filter(
    (productRequestItem) => productRequestItem.response === 'declined'
  )

  const hasConfirmedRequests = Boolean(confirmedRequests.length)

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <ChangeRequestStatus type={hasConfirmedRequests ? 'positive' : 'negative'} />
      </Col>
      <If condition={hasConfirmedRequests}>
        <Col span={24}>
          <ProductRequestItemList collection={confirmedRequests} />
        </Col>
      </If>
      <If condition={declinedRequests.length}>
        <Col span={24}>
          <ProductRequestItemList collection={declinedRequests} />
        </Col>
      </If>
    </Row>
  )
}

export default ChangeRequestAnsweredList
