import { GenericObject } from 'app/constants/GlobalTypes'
import { capitalize as _capitalize, snakeCase, trim } from 'lodash'
import { useIntl } from 'react-intl'

export const ToolBox = {
  useString: () => {
    const intl = useIntl()

    const getInitials = (text: string): string => {
      const initials = text?.match(/\b\w/g) || []
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    }

    const currencify = (price: number, currency: 'EUR' | 'USD' = 'EUR'): string => {
      return (Number(price) || 0).toLocaleString(intl.locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: currency,
        style: 'currency'
      })
    }

    const capitalize = (string: string) => {
      return _capitalize(string)
    }

    const constantize = (string: string) => {
      return string.replace(/(^|_)./g, (s) => s.slice(-1).toUpperCase())
    }

    const smartTranslation = ({
      recordType,
      id,
      values
    }: {
      recordType: string
      id: string
      values?: GenericObject<string>
    }) => {
      return intl.formatMessage(
        {
          id,
          defaultMessage: intl.formatMessage({ id: id.replace(recordType, 'default') }, values)
        },
        values
      )
    }

    const tAttribute = ({
      recordType,
      attribute,
      values
    }: {
      recordType: string
      attribute: string
      values?: GenericObject<string>
    }) => {
      return intl.formatMessage(
        { id: `record.${recordType}.attributes.${attribute}`, defaultMessage: humanize(attribute) },
        values
      )
    }

    const tRecordType = (recordType: string) => {
      return intl.formatMessage({
        id: `record.${recordType}.${recordType}`,
        defaultMessage: humanize(recordType)
      })
    }

    const tButtonTitle = ({ recordType, action }: { recordType: string; action: string }) => {
      return intl.formatMessage({
        id: `component.general.button.title.${recordType}.${action}`,
        defaultMessage: humanize(action)
      })
    }

    const humanize = (str: string) => {
      return capitalize(trim(snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')))
    }

    return {
      getInitials,
      currencify,
      capitalize,
      constantize,
      smartTranslation,
      tAttribute,
      humanize,
      tRecordType,
      tButtonTitle
    }
  },
  parameterize: (params?: CollectionArgs): string => {
    if (params) {
      const { includes, filters, ...others } = params
      const full = [
        ToolBox.includefize(includes),
        ToolBox.filterize(filters),
        new URLSearchParams(others as any).toString()
      ]
      return `?${full.filter(Boolean).join('&')}`
    } else {
      return ''
    }
  },
  includefize: (includes: string[]): string => {
    if (!includes) return ''
    return `includes[]=${includes.join('&includes[]=')}`
  },
  filterize: (filters: FilterType): string => {
    if (!filters) return ''
    const multiple = 'filter[#][]=$'
    const single = 'filter[#]=$'
    let initials = ''
    Object.keys(filters).forEach((key: any) => {
      const filterValue: any = filters[key]
      if (Array.isArray(filterValue)) {
        initials =
          initials.concat(
            filterValue.map((val: string) => multiple.replace('#', key).replace('$', val)).join('&')
          ) + '&'
      } else {
        initials = initials.concat(single.replace('#', key).replace('$', filterValue)).concat('&')
      }
    })
    return initials
  }
}

export const useId = () => {
  const generateId = ({
    recordType,
    componentType,
    id,
    suffix
  }: {
    recordType: string
    componentType: string
    id: string
    suffix?: string | number
  }) => {
    if (!recordType && !id) {
      return undefined
    }
    const _suffix = suffix ? `-${suffix}` : ''
    return `${recordType}-${componentType}-${id}${_suffix}`
  }

  const buttonId = ({
    recordType,
    action,
    suffix
  }: {
    recordType: string
    action: string
    suffix?: string | number
  }) => {
    return generateId({ recordType, id: action, componentType: 'button', suffix })
  }

  return { generateId, buttonId }
}
