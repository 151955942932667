export default class RentalRequestItemNormalizer {
  normalizeRentalRequestItem(rentalRequestItem: any): IRentalRequestItem {
    rentalRequestItem.line_item = {
      ...rentalRequestItem.line_item.attributes
    }

    rentalRequestItem.sub_rental_request_items = rentalRequestItem.sub_rental_request_items.map(
      (sub_rental_request_item: { attributes: IRentalRequestItem }) => {
        return this.normalizeRentalRequestItem(sub_rental_request_item.attributes)
      }
    )

    return rentalRequestItem
  }
}
