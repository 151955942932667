import * as React from 'react'
import { Link } from 'react-router-dom'

import Button, { ButtonProps } from './Button'

interface Props extends ButtonProps {
  url: string
}

const LinkButton: React.FC<Props> = ({ url, ...others }) => {
  return (
    <Link to={url}>
      <Button {...others} />
    </Link>
  )
}

export default LinkButton
