import { DASHBOARD } from '../../constants/ActionTypes'

const initialState: DashboardReducerState = {
  reviews: {
    name: '',
    shortened_name: '',
    machine_reviews_avg: 0,
    delivery_reviews_avg: 0,
    supplier_station_id: 0
  },
  revenue: [],
  topProducts: [],
  mostRentedProducts: [],
  requests: {
    medium: [],
    reaction: [],
    response: [],
    confirmation: []
  },
  stations_requests: {
    medium: [],
    reaction: [],
    response: [],
    confirmation: []
  },
  invoices: [],
  stations_revenue: [],
  stations_invoices: [],
  stations_reviews: []
}

const dashboard = (state: DashboardReducerState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case DASHBOARD.FETCH_REVENUE:
      return {
        ...state,
        revenue: action.payload
      }
    case DASHBOARD.FETCH_REVIEWS:
      return {
        ...state,
        reviews: action.payload
      }
    case DASHBOARD.FETCH_TOP_PRODUCTS:
      return {
        ...state,
        topProducts: action.payload
      }
    case DASHBOARD.MOST_RENTED_PRODUCTS:
      return {
        ...state,
        mostRentedProducts: action.payload
      }
    case DASHBOARD.FETCH_REQUESTS:
      return {
        ...state,
        requests: action.payload
      }
    case DASHBOARD.FETCH_STATIONS_REQUESTS:
      return {
        ...state,
        stations_requests: action.payload
      }
    case DASHBOARD.FETCH_INVOICES:
      return {
        ...state,
        invoices: action.payload
      }

    case DASHBOARD.FETCH_STATIONS_REVENUE:
      return {
        ...state,
        stations_revenue: action.payload
      }
    case DASHBOARD.FETCH_STATIONS_INVOICES:
      return {
        ...state,
        stations_invoices: action.payload
      }
    case DASHBOARD.FETCH_STATIONS_REVIEWS:
      return {
        ...state,
        stations_reviews: action.payload
      }
    default:
      return state
  }
}

export default dashboard
