import EnvironmentService from './EnvironmentService'
import { browserVersion, isIE } from 'react-device-detect'

class ScriptService {
  static embedHubspotScript = (
    identify_email_with_hubspot: string,
    hubspot_sync: boolean = true
  ) => {
    if (EnvironmentService.isProduction()) {
      if (!hubspot_sync) return

      const el = document.getElementById('hs-script-loader')
      if (el) {
        el.remove()
      }
      const script = document.createElement('script')
      script.id = 'hs-script-loader'
      script.type = 'text/javascript'
      script.async = true
      script.defer = true
      const hubspotId = EnvironmentService.getEnvValue('HUBSPOT_ID')
      script.src = `//js.hs-scripts.com/${hubspotId}.js`
      document.getElementsByTagName('body')[0].appendChild(script)
      script.onload = function () {
        setTimeout(() => {
          if (identify_email_with_hubspot) {
            ScriptService.setHubspotEmail(identify_email_with_hubspot)
          }
        }, 1000)
      }
    }
  }

  static setHubspotEmail = (identify_email_with_hubspot: string, retryCount = 2) => {
    if (window._hsq) {
      var _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: identify_email_with_hubspot
        }
      ])
    } else if (retryCount > 1) {
      setTimeout(() => {
        ScriptService.setHubspotEmail(identify_email_with_hubspot, retryCount - 1)
      }, 1000)
    }
  }

  static initMouseflow = (email: string = 'undefined') => {
    if (EnvironmentService.isProduction() && !(isIE && Number(browserVersion) < 11)) {
      window._mfq = window._mfq || []
      ;(function () {
        const mf = document.createElement('script')
        mf.type = 'text/javascript'
        mf.async = true
        mf.src = '//cdn.mouseflow.com/projects/c30bab16-3dca-445e-9eb9-d2ccbd42513d.js'
        document.getElementsByTagName('head')[0].appendChild(mf)
        window._mfq.push(['setVariable', 'email', email])
      })()
    }
  }

  static initGoogleTagManager = () => {
    if (EnvironmentService.isProduction()) {
      const tagManagerID = EnvironmentService.getEnvValue('TAG_MANAGER_ID')

      ;(function (w: any, d: any, s, l, i) {
        w[l] = w[l] || []
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', tagManagerID)
    }
  }

  static initServiceWorker = () => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker
        .register('/serviceworker.js')
        .then(() => console.log('service worker registered'))
        .catch((err) => console.log({ err }))
    } else {
      console.error('Service worker is not supported in this browser')
    }
  }
}

export default ScriptService
