import * as React from 'react'
import { Alert, Col, Row } from 'antd'

interface Props {
  errors?: string | string[]
  children: React.ReactNode
  closeable?: boolean
}

const FormWrapper: React.FC<Props> = ({ errors, children, closeable = false }) => {
  const getErrors = () => {
    if (errors instanceof Array) {
      return errors.map((err, index) => (
        <Alert
          closable={closeable}
          key={`err-${index}`}
          message={err}
          type="error"
          showIcon
          className="fade-in mb-2"
        />
      ))
    } else {
      return (
        <Alert
          closable={closeable}
          message={errors}
          type="error"
          className="fade-in mb-2"
          showIcon
        />
      )
    }
  }

  return (
    <>
      {errors && (
        <Row className="mb-3 fade-in">
          <Col span={24}>{getErrors()}</Col>
        </Row>
      )}
      {children}
    </>
  )
}

export default FormWrapper
