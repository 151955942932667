import {
  DamageResponseEnumSelectOptionType,
  LanguageSelectOptionType,
  PeriodEnumSelectOptionType,
  RoleSelectOptionType,
  SubItemTypeEnumSelectOptionType,
  SupplierResponseSelectOptionType
} from './GlobalTypes'

export const allLocales: LanguageSelectOptionType[] = [
  {
    id: 1,
    language: 'de',
    label: 'Deutsch',
    icon: 'de'
  },
  {
    id: 2,
    language: 'en',
    label: 'English',
    icon: 'us'
  }
]
export const ALL_ROLES: RoleSelectOptionType[] = [
  {
    id: 1,
    value: 'director',
    label: 'director',
    description: 'role.description.director'
  },
  {
    id: 2,
    value: 'station_manager',
    label: 'station_manager',
    description: 'role.description.station_manager'
  },
  {
    id: 3,
    value: 'dispatcher',
    label: 'dispatcher',
    description: 'role.description.dispatcher'
  }
]

export const ALL_ROLE_DESCRIPTIONS = [
  'role.description.director',
  'role.description.station_manager',
  'role.description.dispatcher'
]
export const ALL_GENDERS = ['m', 'f']

export const TRANSPORT_RESPONSE_OPTIONS_ENUM: SupplierResponseSelectOptionType[] = [
  {
    id: 1,
    value: 'confirmed',
    label: 'confirmed'
  },
  {
    id: 2,
    value: 'declined',
    label: 'declined'
  }
]

export const RESPONSE_OPTIONS_ENUM: SupplierResponseSelectOptionType[] = [
  {
    id: 1,
    value: 'confirmed',
    label: 'confirmed'
  },
  {
    id: 2,
    value: 'declined',
    label: 'declined'
  }
]

export const UNIT_ENUM: {
  service: PeriodEnumSelectOptionType[]
  consumables: PeriodEnumSelectOptionType[]
  other: PeriodEnumSelectOptionType[]
} = {
  service: [
    {
      id: 1,
      label: 'days',
      value: '1'
    },
    {
      id: 4,
      label: 'hours',
      value: '4'
    },
    {
      id: 9,
      label: 'lump_sum',
      value: '9'
    },
    {
      id: 10,
      label: 'piece',
      value: '10'
    }
  ],
  consumables: [
    {
      id: 9,
      label: 'lump_sum',
      value: '9'
    },
    {
      id: 10,
      label: 'piece',
      value: '10'
    },
    {
      id: 7,
      label: 'liter',
      value: '7'
    },
    {
      id: 11,
      label: 'meter',
      value: '11'
    },
    {
      id: 15,
      label: 'kilometer',
      value: '15'
    },
    {
      id: 8,
      label: 'm3',
      value: '8'
    },
    {
      id: 5,
      label: 'kg',
      value: '5'
    },
    {
      id: 6,
      label: 't',
      value: '6'
    }
  ],
  other: [
    {
      id: 1,
      label: 'days',
      value: '1'
    },
    {
      id: 2,
      label: 'weeks',
      value: '2'
    },
    {
      id: 3,
      label: 'months',
      value: '3'
    },
    {
      id: 4,
      label: 'hours',
      value: '4'
    },
    {
      id: 9,
      label: 'lump_sum',
      value: '9'
    },
    {
      id: 10,
      label: 'piece',
      value: '10'
    }
  ]
}

export const _SUB_ITEM_TYPES_ENUM: SubItemTypeEnumSelectOptionType[] = [
  {
    id: 1,
    value: 'consumables',
    label: 'verschleiß',
    name: 'Verschleiß'
  },
  {
    id: 2,
    value: 'ballast',
    label: 'ballast',
    name: 'Ballast'
  },
  {
    id: 3,
    value: 'service',
    label: 'cleaning'
  },
  {
    id: 4,
    value: 'other',
    label: 'other'
  }
]

export const LINE_ITEM_TYPES_ENUM = {
  PRODUCT: 'product',
  INSURANCE: 'insurance',
  FUEL: 'fuel',
  CLEANING: 'cleaning',
  DAMAGE: 'damage',
  IDLE: 'idle',
  OVERUSE: 'overuse',
  ADJUSTMENT: 'adjustment',
  OTHER: 'other',
  DELIVERY: 'delivery',
  RETURN_DELIVERY: 'return_delivery',
  CONSUMABLES: 'consumables',
  SERVICE: 'service'
}

export const DOCUMENT_TYPES_ENUM: {
  [key: string]: IDocumentType
} = {
  DAMAGES: 'damages',
  RENTAL_QUOTE: 'rental_quote',
  CHECKIN_IMAGE: 'checkin_image',
  CHECKOUT_IMAGE: 'checkout_image',
  CHECKOUT_DOCUMENT: 'checkout_document',
  CHECKIN_DOCUMENT: 'checkin_document'
}

export const DAMAGE_RESPONSE_ENUM: DamageResponseEnumSelectOptionType[] = [
  {
    id: 1,
    label: 'finalize.rental.request.has.no.damage',
    value: 'no_damage'
  },
  {
    id: 2,
    label: 'finalize.rental.request.has.damage',
    value: 'has_damage'
  }
]
