import { DOCUMENT_TYPES_ENUM } from '../../constants/Enums'
import { DOCUMENTS_URL, DOCUMENT_DETAILS_URL, FETCH_DOCUMENTS_URL } from '../../constants/Url'
import Api from '../../lib/api'
import { parseError } from '../../lib/handler'
import BaseNormalizer from '../reducers/normalizers/BaseNormalizer'

export const getFormDataInfo = ({
  file,
  rental_id,
  supplier_station_id,
  document_type = DOCUMENT_TYPES_ENUM.RENTAL_QUOTE,
  model_type = 'Rental',
  line_item_id
}: IDocumentUpload) => {
  let formData = new FormData()

  formData.append('document[file]', file)
  formData.append('document[model_type]', model_type)
  formData.append('document[document_type]', document_type)
  formData.append('document[model_id]', rental_id.toString())
  formData.append('document[supplier_station_id]', supplier_station_id.toString())
  line_item_id && formData.append('document[line_item_id]', line_item_id.toString())
  return formData
}

export const uploadRentalDocument = (formData: FormData): Promise<IDocument> => {
  return new Promise((resolve, reject) => {
    Api.postWithMultipart<IDocument>(DOCUMENTS_URL, formData)
      .then((response) => {
        resolve(BaseNormalizer.mapAttributesOfMember<IDocument>(response.data.data))
      })
      .catch((error) => {
        reject(parseError(error))
      })
  })
}

export const deleteRentalDocument = (documentId: string | number): Promise<void> => {
  return new Promise((resolve, reject) => {
    Api.delete<IDocument>(DOCUMENT_DETAILS_URL(documentId))
      .then(() => {
        resolve()
      })
      .catch((error) => {
        reject(parseError(error))
      })
  })
}

export const fetchRentalDocuments = (
  rentalId: number,
  supplier_station_id: number,
  line_item_id?: number,
  document_type?: string
): Promise<IDocument[]> => {
  return new Promise((resolve, reject) => {
    Api.get<CollectionSerializerResponse<IDocument>>(
      FETCH_DOCUMENTS_URL(rentalId, supplier_station_id, document_type, line_item_id)
    )
      .then((response) => {
        resolve(BaseNormalizer.mapAttributesOfCollection<IDocument>(response.data.data))
      })
      .catch((error) => {
        reject(parseError(error))
      })
  })
}
