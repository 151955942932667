import { QuestionCircleOutlined } from '@ant-design/icons'
import { Col, Form, Row, Tooltip } from 'antd'
import CategoryAttributesFormList from 'app/components/Core/CategoryAttribute/FormList'
import Button from 'app/components/UI-Elements/Controls/buttons/Button'
import Input from 'app/components/UI-Elements/Controls/inputs/Input'
import FormItem from 'app/components/UI-Elements/Forms/_FormItem'
import FormWrapper from 'app/components/UI-Elements/Forms/FormWrapper'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import { fetchCategoryAttributes } from 'app/redux/actions/CategoryAttribute'
import { createProduct } from 'app/redux/actions/Product'
import { useEffect, useState } from 'react'
import * as React from 'react'
import { useIntl } from 'react-intl'

type Props = {
  product: Omit<IProduct, 'id'>
  onSubmit?: (product: IProduct) => void
}

const NewProductForm: React.FC<Props> = ({ product, onSubmit }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [initalLoading, setInitalLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>(null)
  const [categoryAttributes, setCategoryAttributes] = useState<ICategoryAttribute[]>([])
  const { formatMessage } = useIntl()

  useEffect(() => {
    loadCategoryAttributes()
  }, [])

  const getInitialValues = () => {
    return {
      name: product.name,
      category_id: product.category_id
    }
  }

  const loadCategoryAttributes = () => {
    setInitalLoading(true)
    fetchCategoryAttributes(product.category_id)
      .then((data) => {
        setInitalLoading(false)
        setCategoryAttributes(data)
      })
      .catch((errors) => setErrors(errors))
      .finally(() => setLoading(false))
  }

  const onFinish = (values: IProduct) => {
    setLoading(true)
    const payload = {
      ...values,
      products_suppliers_attributes: [{ supplier_station_id: product.supplier_station_id }]
    }
    createProduct(product.supplier_station_id, payload)
      .then((product) => onSubmit(product))
      .catch((errors) => setErrors(errors))
      .finally(() => setLoading(false))
  }

  const nameLabel = () => (
    <span>
      {formatMessage({ id: 'rental.request.new.product.name.label' })}
      <Tooltip title={formatMessage({ id: 'rental.request.new.product.name.hint' })}>
        <QuestionCircleOutlined className="text-lg text-black ml-1" />
      </Tooltip>
    </span>
  )

  return (
    <FormWrapper errors={errors}>
      <Form
        name={'create_product'}
        form={form}
        onFinish={onFinish}
        initialValues={getInitialValues()}
        layout="vertical">
        <KCol span={24} loading={initalLoading}>
          <Row gutter={[0, 24]} style={{ marginBottom: 0 }}>
            <Col span={24}>
              <FormItem
                label={formatMessage({
                  id: 'rental.request.new.product.category_segment.requested'
                })}>
                <strong className="text-primary">{product.category_segment_name || '-'}</strong>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem required={true} name="name" label={nameLabel()}>
                <Input
                  placeholder={formatMessage({ id: 'rental.request.new.product.name.placeholder' })}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
              </FormItem>
              <FormItem name="category_id" className="d-none">
                <Input hidden />
              </FormItem>
            </Col>
            <If condition={categoryAttributes.length > 0}>
              <p className="mb-0 mt-4 text-lg text-primary">
                {formatMessage({ id: 'rental.request.new.product.category_attributes.title' })}
              </p>
              <Col span={24}>
                <CategoryAttributesFormList categoryAttributes={categoryAttributes} />
              </Col>
            </If>
            <div className="flex-auto text-right">
              <Button
                loading={loading}
                type="default"
                htmlType="submit"
                title={formatMessage({ id: 'rental.request.new.product.submit.button' })}
              />
            </div>
          </Row>
        </KCol>
      </Form>
    </FormWrapper>
  )
}

export default NewProductForm
