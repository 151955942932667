import { List } from 'antd'
import { Col, Row } from 'antd'
import KList from 'app/components/UI-Elements/DataDisplay/KList'
import CustomIcon from 'app/components/UI-Elements/General/icons/CustomIcon'
import IntlStringMessages from 'app/util/IntlStringMessages'
import * as React from 'react'

import Cargos from '../DeliveryItem/Cargos'
import ProductRequestItemTag from '../ProductRequestItem/Tag'
import DeliveryRequestItemDate from './Date'

const ListItem: React.FC<MemberProps<IDeliveryRequestItem>> = ({ member: deliveryItem }) => {
  const getDeliveryIconName = (): string => {
    if (deliveryItem.item.delivery_type === 'delivery') {
      return 'delivery_icon'
    } else {
      return 'return_delivery_icon'
    }
  }

  return (
    <Row className="full-width my-4" key={deliveryItem.id}>
      <Col span={24}>
        <div className="d-flex">
          <span className="mr-3">
            <CustomIcon type={getDeliveryIconName()} styles={{ height: '60%' }} />
          </span>
          <h6 className="text-black mr-3">
            <DeliveryRequestItemDate member={deliveryItem} />
          </h6>
          <span>
            <ProductRequestItemTag member={deliveryItem} />
          </span>
        </div>
      </Col>
      <Col span={24}>
        <Cargos cargos={deliveryItem.cargos} />
      </Col>
    </Row>
  )
}

const DeliveryRequestItemSummary: React.FC<CollectionProps<IDeliveryRequestItem>> = ({
  collection
}) => {
  return (
    <KList
      dataSource={collection}
      bordered={false}
      locale={{
        emptyText: <IntlStringMessages id="rental-show-no-transport-available" />
      }}
      renderItem={(item) => (
        <List.Item>
          <ListItem member={item} />
        </List.Item>
      )}
    />
  )
}

export default DeliveryRequestItemSummary
