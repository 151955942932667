import { Alert } from 'antd'
import * as React from 'react'

type Props = {
  message?: string | React.ReactNode
  className?: string
  type?: 'success' | 'info' | 'warning' | 'error' | 'default'
  children?: React.ReactNode
  closable?: boolean
  showIcon?: boolean
  onClose?: () => void
}

const Message: React.FC<Props> = ({
  message,
  className,
  type = 'error',
  closable,
  showIcon,
  onClose,
  children
}) => {
  const getType = (): 'success' | 'info' | 'warning' | 'error' => {
    if (type === 'default') {
      return 'info'
    } else {
      return type
    }
  }

  const getClassName = (): string => {
    if (type === 'default') {
      return `${className} ant-alert-default`
    } else {
      return className
    }
  }

  return (
    <Alert
      onClose={onClose}
      showIcon={showIcon}
      closable={closable}
      message={message || children}
      type={getType()}
      className={getClassName()}
    />
  )
}

export default Message
