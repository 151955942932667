import { RecordType } from 'app/constants/GlobalTypes'
import EnvironmentService from 'app/services/EnvironmentService'
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import { MessageDescriptor, useIntl } from 'react-intl'

type Values = Record<string, PrimitiveType | FormatXMLElementFn>

type Props = {
  scope?: string
  recordType?: keyof typeof RecordType | string | 'default'
  componentType?: string
}

type TranslateFunction = (
  descriptor: MessageDescriptor,
  values?: Values
) => string | React.ReactNode

export default function ({ scope, componentType, recordType = 'default' }: Props) {
  const { formatMessage } = useIntl()

  const tComponent: TranslateFunction = ({ id, ...others }, values) => {
    if (!componentType) {
      if (EnvironmentService.isDevelopment()) {
        throw 'Specify Component Type'
      } else {
        console.error('Specify Component Type')
      }
    }

    return formatMessage(
      {
        id: `component.${componentType}.${scope}.${recordType}.${id}`,
        ...others
      },
      values
    )
  }

  const tCore: TranslateFunction = ({ id, ...others }, values) => {
    return formatMessage(
      {
        id: `component.core.${scope}.${recordType}.${id}`,
        ...others
      },
      values
    )
  }

  const tRoute: TranslateFunction = ({ id, ...others }, values) => {
    return formatMessage(
      {
        id: `route.${scope}.${id}`,
        ...others
      },
      values
    )
  }

  const tAttribute: TranslateFunction = ({ id, ...others }, values) => {
    return formatMessage(
      {
        id: `record.${recordType}.attributes.${id}`,
        ...others
      },
      values
    )
  }

  const tRecordType: TranslateFunction = ({ id, ...others }, values) => {
    return formatMessage({ id: `record.${recordType}.${id || recordType}`, ...others }, values)
  }

  const tModal: TranslateFunction = ({ id, ...others }, values) => {
    const prefix = 'component.feedback.modal.content'

    return formatMessage({ id: `${prefix}.${recordType}.${id}`, ...others }, values)
  }

  const tButtonTitle: TranslateFunction = ({ id, ...others }, values) => {
    const prefix = 'component.general.button.title'

    return formatMessage({ id: `${prefix}.${recordType}.${id}`, ...others }, values)
  }

  return { tRoute, tCore, tComponent, tAttribute, tRecordType, tModal, tButtonTitle }
}
