// Authenticate Module
export const USER = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  REGISTER_REQUEST: 'REGISTER_REQUEST'
}

export const APP_ACTIONS = {
  SIDEBAR_TOGGLE: 'SIDEBAR_TOGGLE',
  PROFILE_SIDEBAR_TOGGLE: 'PROFILE_SIDEBAR_TOGGLE'
}

export const CONTACT = {
  FETCH_CONTACTS: 'FETCH_CONTACTS',
  UPDATE_LOCALE: 'UPDATE_LOCALE',
  UPDATE_CONTACT: 'UPDATE_CONTACT',
  CREATE_CONTACT: 'CREATE_CONTACT'
}

export const RENTAL_REQUEST = {
  REQUESTED: 'requested',
  ACTIVE: 'active',
  FINALIZED: 'finalized',
  CANCELED: 'canceled',
  FETCH_RENTAL_REQUEST: 'FETCH_RENTAL_REQUEST',
  FETCH_PURCHASING_REQUEST: 'FETCH_PURCHASING_REQUEST',
  FETCH_RENTAL_REQUESTS: 'FETCH_RENTAL_REQUESTS',
  FETCH_REQUESTED_RENTAL_REQUESTS: 'FETCH_REQUESTED_RENTAL_REQUESTS',
  FETCH_FINALIZED_RENTAL_REQUESTS: 'FETCH_FINALIZED_RENTAL_REQUESTS',
  FETCH_ACTIVE_RENTAL_REQUESTS: 'FETCH_ACTIVE_RENTAL_REQUESTS',
  FETCH_CANCELED_RENTAL_REQUESTS: 'FETCH_CANCELED_RENTAL_REQUESTS',
  FETCH_RENTAL_REQUEST_BY_ID: 'FETCH_RENTAL_REQUEST_BY_ID',
  TOGGLE_RENTAL_REQUEST_FOR_PREVIEW: 'TOGGLE_RENTAL_REQUEST_FOR_PREVIEW',
  SET_SELECTED_TAB: 'SET_SELECTED_TAB',
  ADD_CANCELED_CARGOS: 'ADD_CARGOS',
  REMOVE_CANCELED_CARGOS: 'REMOVE_CARGOS',
  RESPOND_TO_RENTAL_REQUEST: 'RESPOND_TO_RENTAL_REQUEST',
  UPDATE_RENTAL_REQUEST: 'UPDATE_RENTAL_REQUEST',
  FETCH_LAST_PRODUCTS: 'FETCH_LAST_PRODUCTS',
  LIFT_FORMS: 'LIFT_FORMS',
  DESTROY_STATE: 'DESTROY_STATE',
  FETCH_DELIVERY_ITEMS: 'FETCH_DELIVERY_ITEMS'
}

export const DASHBOARD = {
  FETCH_REVENUE: 'FETCH_REVENUE',
  FETCH_STATIONS_REVENUE: 'FETCH_STATIONS_REVENUE',
  FETCH_REVIEWS: 'FETCH_REVIEWS',
  FETCH_STATIONS_REVIEWS: 'FETCH_STATIONS_REVIEWS',
  FETCH_TOP_PRODUCTS: 'FETCH_TOP_PRODUCTS',
  MOST_RENTED_PRODUCTS: 'MOST_RENTED_PRODUCTS',
  FETCH_REQUESTS: 'FETCH_REQUESTS',
  FETCH_STATIONS_REQUESTS: 'FETCH_STATIONS_REQUESTS',
  FETCH_INVOICES: 'FETCH_INVOICES',
  FETCH_STATIONS_INVOICES: 'FETCH_STATIONS_INVOICES'
}

export const FLEET_REVIEW = {
  SET_SELECTED_SUPPLIER_STATION: 'SET_SELECTED_SUPPLIER_STATION',
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  FETCH_STATION_CATEGORIES: 'FETCH_STATION_CATEGORIES',
  SET_TARGET_KEYS: 'SET_TARGET_KEYS'
}
