/* eslint-disable @typescript-eslint/no-explicit-any */
import EnvironmentService, { KLARX_CONNECT_URL_PREFIX } from 'app/services/EnvironmentService'
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

export const parseError = (response: AxiosResponse): string[] => {
  return normalizeErrors(lookUpForErrors(response).errors)
}

const lookUpForErrors = (response: any): any => {
  if (response?.data) {
    if (typeof response.data === 'object') {
      return handleObjectErrors(response.data)
    }
    if (typeof response.data === 'string') {
      return handleStringErrors(response.statusText)
    }
  } else {
    return handleStringErrors(response.statusText)
  }
}

const handleObjectErrors = (errorObject: any): any => {
  if (errorObject?.errors) {
    return errorObject
  } else {
    return { errors: { default: errorObject.error } }
  }
}

const handleStringErrors = (errorString: string): any => {
  return { errors: { default: errorString } }
}

const normalizeErrors = (err: any) => {
  return Object.keys(err).map((key) => err[key])
}

export const withXSRFToken = (config: InternalAxiosRequestConfig) => {
  // return true as port does not match and we dont care about security during development
  if (EnvironmentService.isDevelopment()) return true

  // check if the origin is the same as the base url
  // otherwise we do not send the xsrf token
  // check PR for more info: https://github.com/2wunder/bauclub/pull/11876
  const origin = window.location.origin.replace(KLARX_CONNECT_URL_PREFIX, '')
  const baseUrl = new URL(config.baseURL.replace('www.', ''))
  return baseUrl.origin === origin
}
