import { RENTAL_REQUEST } from 'app/constants/ActionTypes'

import RentalRequestNormalizer from './normalizers/RentalRequestNormalizer'

const initialPagination = (type: string): RentalRequestPaginationMeta => {
  return {
    current_page: 1,
    next_page: null,
    prev_page: null,
    total_pages: 1,
    total_count: 0,
    per_page: 10,
    type
  }
}

const initialState: RentalRequestReducerState = {
  fetching: false,
  selectedTab: 'requested',
  rentalRequest: null,
  purchasingRequest: null,
  rentalRequests: [],
  requestedRentalRequests: [],
  activeRentalRequests: [],
  finalizedRentalRequests: [],
  canceledRentalRequests: [],
  rentalRequestForPreview: null,
  pagination: {
    [RENTAL_REQUEST.REQUESTED]: initialPagination(RENTAL_REQUEST.REQUESTED),
    [RENTAL_REQUEST.ACTIVE]: initialPagination(RENTAL_REQUEST.ACTIVE),
    [RENTAL_REQUEST.FINALIZED]: initialPagination(RENTAL_REQUEST.FINALIZED),
    [RENTAL_REQUEST.CANCELED]: initialPagination(RENTAL_REQUEST.CANCELED)
  },
  lastProducts: null,
  forms: null
}

const rentalRequest = (
  state: RentalRequestReducerState = initialState,
  action: RentalRequestActionType
) => {
  switch (action.type) {
    case RENTAL_REQUEST.FETCH_PURCHASING_REQUEST:
      return {
        ...state,
        rentalRequest: null as unknown,
        purchasingRequest: action.payload
      }
    case RENTAL_REQUEST.FETCH_RENTAL_REQUEST:
      return {
        ...state,
        rentalRequest: action.payload,
        purchasingRequest: null
      }
    case RENTAL_REQUEST.RESPOND_TO_RENTAL_REQUEST:
      return {
        ...state,
        rentalRequest: action.payload
      }
    case RENTAL_REQUEST.UPDATE_RENTAL_REQUEST:
      return {
        ...state,
        rentalRequest: action.payload
      }
    case RENTAL_REQUEST.FETCH_DELIVERY_ITEMS:
      return {
        ...state,
        rentalRequest: { ...state.rentalRequest, rental_request_deliveries: action.payload }
      }
    case RENTAL_REQUEST.FETCH_RENTAL_REQUESTS:
      return {
        ...state,
        rentalRequests: new RentalRequestNormalizer().normalizeRentalRequests(action.payload)
      }
    case RENTAL_REQUEST.FETCH_REQUESTED_RENTAL_REQUESTS:
      return {
        ...state,
        pagination: { ...state.pagination, [RENTAL_REQUEST.REQUESTED]: action.payload.meta },
        requestedRentalRequests: new RentalRequestNormalizer().normalizeRentalRequests(
          action.payload
        )
      }
    case RENTAL_REQUEST.FETCH_ACTIVE_RENTAL_REQUESTS:
      return {
        ...state,
        pagination: { ...state.pagination, [RENTAL_REQUEST.ACTIVE]: action.payload.meta },
        activeRentalRequests: new RentalRequestNormalizer().normalizeRentalRequests(action.payload)
      }
    case RENTAL_REQUEST.FETCH_FINALIZED_RENTAL_REQUESTS:
      return {
        ...state,
        pagination: { ...state.pagination, [RENTAL_REQUEST.FINALIZED]: action.payload.meta },
        finalizedRentalRequests: new RentalRequestNormalizer().normalizeRentalRequests(
          action.payload
        )
      }
    case RENTAL_REQUEST.FETCH_CANCELED_RENTAL_REQUESTS:
      return {
        ...state,
        pagination: { ...state.pagination, [RENTAL_REQUEST.CANCELED]: action.payload.meta },
        canceledRentalRequests: new RentalRequestNormalizer().normalizeRentalRequests(
          action.payload
        )
      }
    case RENTAL_REQUEST.TOGGLE_RENTAL_REQUEST_FOR_PREVIEW:
      return {
        ...state,
        rentalRequestForPreview: action.payload.open ? action.payload.rentalRequest : null
      }
    case RENTAL_REQUEST.SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload.selectedTab
      }
    case RENTAL_REQUEST.FETCH_LAST_PRODUCTS:
      return {
        ...state,
        lastProducts: {
          ...state.lastProducts,
          ...{ [action.payload.rental_request_item_id]: action.payload.lastProducts }
        }
      }
    case RENTAL_REQUEST.LIFT_FORMS:
      return {
        ...state,
        forms: { ...state.forms, ...{ [action.payload.name]: action.payload.form } }
      }
    case RENTAL_REQUEST.DESTROY_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default rentalRequest
