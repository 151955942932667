import { List } from 'antd'
import KList from 'app/components/UI-Elements/DataDisplay/KList'
import * as React from 'react'

import ListItem from './ListItem'

const ProductRequestItemList: React.FC<CollectionProps<IProductRequestItem>> = ({ collection }) => {
  return (
    <KList
      hideIfEmpty
      dataSource={collection}
      renderItem={(item) => (
        <List.Item>
          <ListItem member={item} />
        </List.Item>
      )}
    />
  )
}

export default ProductRequestItemList
