import { Col, Row, Space } from 'antd'
import * as React from 'react'
import { useIntl } from 'react-intl'

import ProductRequestItemChange from '../ChangeRequest/ProductRequestItem/Change'

const ChangesList: React.FC<MemberProps<Pick<IRentalRequestItem, 'requested_changes'>>> = ({
  member
}) => {
  const { requested_changes } = member
  const { formatMessage } = useIntl()
  const attributes = Object.keys(requested_changes || {}) as (keyof PurchasingRequestItemChanges)[]

  return (
    <Row>
      <Col span={24}>
        <p>
          <strong>Änderungen</strong>
        </p>
      </Col>
      {attributes.map((attribute) => (
        <Col key={attribute} span={12}>
          <Space direction="vertical">
            <p className="text-primary mb-0">
              {formatMessage({ id: `change_request.change_attribute.${attribute}` })}
            </p>
            <ProductRequestItemChange attribute={attribute} changes={requested_changes} />
          </Space>
        </Col>
      ))}
    </Row>
  )
}

export default ChangesList
