import { Col, Row } from 'antd'
import * as React from 'react'

import ProductRequestItemList from '../ProductRequestItem/List'

const ChangeRequestSelectedList: React.FC<CollectionProps<IChangeRequest>> = ({ collection }) => {
  const productRequestItems = collection
    .map((changeRequest) => changeRequest.product_request_items)
    .flat()

  return (
    <If condition={collection.length}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <ProductRequestItemList collection={productRequestItems} />
        </Col>
      </Row>
    </If>
  )
}

export default ChangeRequestSelectedList
