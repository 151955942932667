import { priceFormatter } from '@2wunder/klarx-tool'
import { RightOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import KIcon from 'app/components/UI-Elements/General/icons/KIcon'
import * as React from 'react'
import { useIntl } from 'react-intl'

import DeliveryRequestItemDate from './Date'

const DeliveryRequestItemListItem: React.FC<MemberProps<IDeliveryRequestItem>> = ({ member }) => {
  const { formatMessage } = useIntl()

  const renderCargoName = (cargo: ICargo): string => cargo.name

  return (
    <Row wrap={false} className="full-width">
      <Col flex="none">
        <KIcon icon={<RightOutlined />} className="text-gray mt-1 mr-2" />
      </Col>
      <Col flex="auto">
        <h3 className="text-black mb-0">
          <DeliveryRequestItemDate member={member} />
        </h3>
        <span>
          {formatMessage(
            { id: 'delivery-request-item-cargo-names' },
            { names: member.cargos.map(renderCargoName).join(', ') }
          )}
        </span>
      </Col>
      <If condition={member.response === 'confirmed'}>
        <Col flex="none">
          <h6 className="text-black mb-0">{priceFormatter(member.net_price)}</h6>
        </Col>
      </If>
    </Row>
  )
}

export default DeliveryRequestItemListItem
