import { getQueryString } from 'app/lib/utils/search-params'

import { history } from '../redux/store'
import CommonService from './CommonService'

class LoginService {
  loginResponse: MemberSerializerResponse<IUser>
  user: IUser
  contact: IContact
  supplierStations: ISupplierStation[]
  contactOrganizations: IContactOrganization[]

  constructor(loginResponse: MemberSerializerResponse<IUser>) {
    this.loginResponse = loginResponse
  }

  startSession() {
    this.removeTokenFromUrl()
    this.prepareUserContext()
    return this.reducerPayload()
  }

  removeTokenFromUrl() {
    const pathname = history.location.pathname
    const search = CommonService.removeParamFromRoute('login_token')
    history.push({ pathname, search })
  }

  prepareUserContext() {
    this.user = this.loginResponse.data.attributes
    this.contact = this.loginResponse.included.find(
      (included) => included.type === 'contact'
    ).attributes
    this.contactOrganizations = this.loginResponse.included
      .filter((included) => included.type === 'contact_organization')
      .map((co) => co.attributes)
    this.supplierStations = this.loginResponse.included
      .filter((included) => included.type === 'supplier_station')
      .map((st) => st.attributes)
  }

  reducerPayload() {
    return {
      user: this.user,
      contact: this.contact,
      contactOrganizations: this.contactOrganizations,
      supplierStations: this.supplierStations
    }
  }

  static getLoginTokenFromUrl(): string {
    const loc = window.location || { search: '', pathname: '' }
    const loginToken = getQueryString(loc.search, 'login_token')
    if (Array.isArray(loginToken)) {
      return loginToken[0]
    } else {
      return loginToken
    }
  }
}

export default LoginService
