import { useSelector } from 'react-redux'

export const useCurrent = <T extends IContact | IUser>(who: 'User' | 'Contact'): T => {
  const state = useSelector<AppState, AppState>((state) => state)
  switch (who) {
    case 'Contact':
      return state.user.contact as any
    case 'User':
      return state.user.user as any
    default:
      break
  }
}
