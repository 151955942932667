export const valueExist = (value: any) => {
  return value && value.length > 0
}

export const validUrl = (url: string): boolean => {
  try {
    new URL(url)
    return true
  } catch (_) {
    return false
  }
}
