import { Col, Row } from 'antd'
import RentalInfoCard from 'app/components/Core/Rental/InfoCard'
import StatusMessage from 'app/components/Core/Rental/Status/Message'
import { RecordType } from 'app/constants/GlobalTypes'
import { useShow } from 'app/lib/hooks/useApi'
import Loading from 'app/pages/AvailabilityRequest/Pages/Loading'
import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import RequestItems from '../Components/RequestItems'

const PurchasingRequestResponse: React.FC = () => {
  const { purchasing_request_id } = useParams<{ purchasing_request_id: string }>()
  const { execute, response: purchasingRequest } = useShow<IPurchasingRequest>(
    RecordType.purchasing_request
  )

  useEffect(() => {
    execute({
      id: Number(purchasing_request_id)
    })
  }, [purchasing_request_id])

  if (!purchasingRequest) {
    return <Loading />
  }

  const renderStatus = () => (
    <If
      condition={
        purchasingRequest.status === 'discarded' || purchasingRequest.status === 'rejected'
      }>
      <Col span={24}>
        <StatusMessage member={{ status: 'canceled' }} />
      </Col>
    </If>
  )

  const isAllPending = () =>
    purchasingRequest.product_request_items.filter((i) => i.response !== 'pending').length === 0

  return (
    <Row align="top" gutter={[24, 24]}>
      <Col span={16}>
        <Row gutter={[24, 24]}>
          <Choose>
            <When condition={isAllPending()}>
              <RequestItems purchasingRequest={purchasingRequest} responseType="pending">
                {renderStatus()}
              </RequestItems>
            </When>
            <Otherwise>
              <RequestItems purchasingRequest={purchasingRequest} responseType="confirmed">
                {renderStatus()}
              </RequestItems>
              <RequestItems purchasingRequest={purchasingRequest} responseType="declined" />
            </Otherwise>
          </Choose>
        </Row>
      </Col>
      <Col sm={24} md={8}>
        <RentalInfoCard member={purchasingRequest.rental} />
      </Col>
    </Row>
  )
}

export default PurchasingRequestResponse
