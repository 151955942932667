import { ReactElement } from 'react'
import * as React from 'react'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import Timer from 'app/components/UI-Elements/DataDisplay/Timer'
import IntlStringMessages from 'app/util/IntlStringMessages'
import IntlHtmlMessages from 'app/util/IntlHtmlMessages'

type Props = {
  rentalRequest: IRentalRequest
}

const Request: React.FC<Props> = ({ rentalRequest }) => {
  const onExpire = () => {
    window.location.reload()
  }

  const getTitle = (): ReactElement => {
    if (rentalRequest.time_to_expire > 0) {
      return <IntlStringMessages id="rental.request.premium.title" />
    } else {
      return <IntlStringMessages id="rental.request.premium.expired.title" />
    }
  }

  const getDescription = (): ReactElement => {
    if (rentalRequest.time_to_expire > 0) {
      return <IntlHtmlMessages id="rental.request.premium.description" />
    } else {
      return <IntlHtmlMessages id="rental.request.premium.expired.description" />
    }
  }

  const getExpiredClassName = (): string => {
    if (rentalRequest.time_to_expire <= 0) {
      return 'half-opacity'
    }
  }

  return (
    <KCard bodyPadding={0}>
      <div className="d-flex flex-wrap">
        <img
          src="/assets/images/danger-shape.svg"
          className={getExpiredClassName()}
          alt="shape-danger"
        />
        <div className={`my-auto ml-3 ${getExpiredClassName()}`}>
          <Timer onExpire={onExpire} value={rentalRequest.time_to_expire * 1000} />
        </div>
        <div className="my-auto ml-4">
          <h2 className="mb-0">{getTitle()}</h2>
          <p className="mb-0">{getDescription()}</p>
        </div>
      </div>
    </KCard>
  )
}

export default Request
