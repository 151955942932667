import { CONTACT } from '../../constants/ActionTypes'
import ReducerService from '../../services/ReducerService'

const initialState: ContactReducerState = {
  contacts: []
}

const contact = (state: ContactReducerState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case CONTACT.FETCH_CONTACTS:
      return {
        ...state,
        contacts: action.payload
      }
    case CONTACT.UPDATE_CONTACT:
      return {
        ...state,
        contacts: ReducerService.updateItemInArray(state.contacts, action.payload)
      }
    case CONTACT.CREATE_CONTACT:
      return {
        ...state,
        contacts: [action.payload, ...state.contacts]
      }
    default:
      return state
  }
}

export default contact
