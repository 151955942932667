import { dayjs } from '@2wunder/klarx-tool'
import { SwapOutlined } from '@ant-design/icons'
import { Col, FormInstance, Radio, RadioChangeEvent, Row, Typography } from 'antd'
import Price from 'app/components/Core/RentalRequest/Form/Input/Price'
import ProductsModal from 'app/components/Core/RentalRequest/Form/Select/ProductsModal'
import Unit from 'app/components/Core/RentalRequest/Form/Select/Unit'
import Input from 'app/components/UI-Elements/Controls/inputs/Input'
import FormItem from 'app/components/UI-Elements/Forms/_FormItem'
import KFormList from 'app/components/UI-Elements/Forms/_KFormList'
import * as React from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'

type Props = {
  index: number
  supplier_station_id: number
  showFormDetails: boolean
  form?: FormInstance
}

const options: ProductItemResponse[] = ['confirmed', 'declined']

const ProductNewRequestFormItem: React.FC<MemberProps<IProductRequestItem, Props>> = ({
  member,
  props: { index, supplier_station_id, form, showFormDetails }
}) => {
  const { formatMessage } = useIntl()
  const { item, product_id, product_name, included, data_sheet_url } = member
  const [response, setResponse] = useState<ProductItemResponse>(null)

  const onChange = (e: RadioChangeEvent) => setResponse(e.target.value)
  const showForm = showFormDetails && response === 'confirmed'

  const freeText = () => (
    <If condition={included}>
      <Typography.Text disabled className="ml-1">
        ({formatMessage({ id: 'rental-show-invoices-rental-free' })})
      </Typography.Text>
    </If>
  )

  const getStartDate = () =>
    [dayjs(item.delivery_date).toLocaleDate(), item.delivery_time].filter(Boolean).join(' ')

  const getReturnDate = () =>
    [dayjs(item.pick_up_date).toLocaleDate(), item.pick_up_time].filter(Boolean).join(' ')

  return (
    <>
      <FormItem name={[index, 'id']} className="d-none">
        <Input defaultValue={String(member.id)} hidden />
      </FormItem>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Row wrap={false}>
            <Col flex="none" className="pr-3">
              <SwapOutlined style={{ fontSize: '26px' }} />
            </Col>
            <Col flex="auto">
              <h5 className="text-black">
                {item.category_segment_name} {freeText()}
              </h5>
              <If condition={!included}>
                <span>
                  {formatMessage(
                    { id: 'request-line-item-time' },
                    {
                      start_date: getStartDate(),
                      end_date: getReturnDate()
                    }
                  )}
                </span>
                <FormItem name={[index, 'response']} required className={`mt-3 mb-2`}>
                  <Radio.Group onChange={onChange}>
                    {options.map((option) => (
                      <Radio.Button key={option} value={option}>
                        {formatMessage({ id: `availability.change.${option}` })}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </FormItem>
                <If condition={showForm}>
                  <Row gutter={[24, 0]}>
                    <Col className="fade-in" span={24}>
                      <ProductsModal
                        required={true}
                        product={{ id: product_id, name: product_name, data_sheet_url }}
                        extraOptions={[]}
                        formListName="product_request_items"
                        index={index}
                        item={{
                          category_id: item.category_id,
                          category_segment_id: item.catalog_info_id,
                          category_segment_name: item.category_segment_name,
                          supplier_station_id: Number(supplier_station_id)
                        }}
                        form={form}
                      />
                    </Col>
                    <Col className="fade-in" span={12}>
                      <Price name={[index, 'net_price']} required />
                    </Col>
                    <Col className="fade-in" span={12}>
                      <Unit name={[index, 'unit_id']} lineItemType={'product'} required />
                    </Col>
                    <Col className="fade-in" span={24}>
                      <FormItem
                        hasFeedback={true}
                        label={formatMessage({ id: 'rental.request.product.annotation' })}
                        name={[index, 'annotation']}>
                        <Input placeholder={formatMessage({ id: 'rental.request.annotation' })} />
                      </FormItem>
                    </Col>
                  </Row>
                </If>
              </If>
            </Col>
          </Row>
        </Col>
        <If condition={Boolean(member.sub_items?.length)}>
          <Col span={24} className="pl-4">
            <KFormList name={[index, 'sub_items']}>
              {() => (
                <Row gutter={[0, 12]}>
                  {member.sub_items.map((i, index) => (
                    <Col span={24} key={String(i.id)}>
                      <ProductNewRequestFormItem
                        member={i}
                        props={{ supplier_station_id, showFormDetails, form, index }}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </KFormList>
          </Col>
        </If>
      </Row>
    </>
  )
}

export default ProductNewRequestFormItem
