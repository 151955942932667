/* eslint-disable @typescript-eslint/ban-ts-comment */
import { decimalNormalizer } from '@2wunder/klarx-tool'
import * as React from 'react'
import CurrencyInput from 'react-currency-input-field'

interface Props extends Partial<Omit<React.ComponentProps<typeof CurrencyInput>, 'onChange'>> {
  onChange?: (text: string) => void
}

const PriceInput = (props: Props) => {
  const { disabled, required, placeholder, onChange, ...others } = props

  return (
    <CurrencyInput
      placeholder={placeholder}
      decimalsLimit={2}
      suffix=" €"
      decimalSeparator=","
      groupSeparator="."
      disabled={disabled}
      required={required}
      onValueChange={(e) => onChange(decimalNormalizer(e))}
      allowNegativeValue={false}
      {...others}
    />
  )
}

export default PriceInput
