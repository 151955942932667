import { InfoCircleOutlined } from '@ant-design/icons'
import { Card, Tooltip } from 'antd'
import { CardProps } from 'antd/es/card'
import * as React from 'react'

interface Props extends CardProps {
  title?: React.ReactNode
  className?: string
  infoText?: string | React.ReactNode
  bodyPadding?: number
}

const KCard: React.FC<Props> = ({
  bordered = true,
  children,
  hoverable,
  title,
  infoText,
  className = '',
  bodyPadding,
  ...others
}) => {
  return (
    <Card
      bordered={bordered}
      hoverable={hoverable}
      bodyStyle={{ padding: bodyPadding }}
      title={title ? <h2 className="mb-0 white-space-normal">{title}</h2> : null}
      className={`custom-card-wrapper ${className} ${title ? 'has-title' : ''}`}
      {...others}>
      {children}
      {infoText && (
        <Tooltip placement="leftTop" title={infoText}>
          <InfoCircleOutlined className="info-icon" />
        </Tooltip>
      )}
    </Card>
  )
}

export default KCard
