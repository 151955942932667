import * as React from 'react'

import Spinner from './Spinner'

const PageLoading: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: '90vh'
      }}>
      <Spinner />
    </div>
  )
}

export default PageLoading
