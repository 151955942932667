import { RightOutlined } from '@ant-design/icons'
import { Col, Row, Space } from 'antd'
import Link from 'app/components/Core/DataSheet/Link'
import KIcon from 'app/components/UI-Elements/General/icons/KIcon'
import * as React from 'react'

const ProductName: React.FC<MemberProps<IProductRequestItem>> = ({ member }) => {
  const name = member.product_name || member.item.name
  const { category_segment_name } = member.item

  const getHint = (): React.ReactNode => {
    if (name !== category_segment_name) {
      return <span className="text-gray">({category_segment_name})</span>
    }
  }

  return (
    <Space direction="horizontal" align="start">
      <KIcon icon={<RightOutlined />} className="text-gray kicon-box-md" />
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <h6 className="text-black mb-0">
            <span>{name}</span>
          </h6>
        </Col>
        <Col span={24}>{getHint()}</Col>
        <Col span={24}>
          <Link url={member.data_sheet_url} title={member.product_name} />
        </Col>
      </Row>
    </Space>
  )
}

export default ProductName
