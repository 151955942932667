/* eslint-disable @typescript-eslint/ban-ts-comment */
import PriceInput from 'app/components/Helpers/PriceInput'
import FormItem from 'app/components/UI-Elements/Forms/_FormItem'
import * as React from 'react'
import { useIntl } from 'react-intl'

interface Props extends Partial<React.ComponentProps<typeof FormItem>> {
  required?: boolean
  disabled?: boolean
}

const Price: React.FC<Props> = ({ required, disabled, ...others }) => {
  const { formatMessage } = useIntl()

  const getHelpText = (): string => {
    return disabled ? 'Dieser Preis wird von Ihrer Preisliste abgerufen' : null
  }

  return (
    <FormItem
      hasFeedback={true}
      required={required}
      help={getHelpText()}
      getValueFromEvent={(value) => value}
      label={formatMessage({ id: 'rental.request.product.price' })}
      valuePropName="defaultValue"
      name="unit_price"
      {...others}>
      <PriceInput
        placeholder={formatMessage({ id: 'rental.request.product.price' })}
        className="full-width ant-input ant-input-lg"
        disabled={disabled}
      />
    </FormItem>
  )
}

export default Price
