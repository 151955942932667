class BaseNormalizer {
  static mapAttributesOfCollection = <T>(data: SerializerResponseData<T>[]): T[] =>
    data.map((item) => item.attributes)

  static mapAttributesOfMember = <T>(data: SerializerResponseData<T>) => data.attributes

  static arrayToObject = <T>(array: SerializerResponseData<T>[]): { [key: string]: any } =>
    array.reduce((obj: INormalizedIncluded, item) => {
      obj[item.id] = item.attributes
      return obj
    }, {})

  static normalizeIncluded = <T>(
    included: SerializerResponseData<T>[],
    types: string[] = []
  ): INormalizedIncluded => {
    const { arrayToObject } = BaseNormalizer
    return types.reduce((obj: INormalizedIncluded, type: string) => {
      obj[type] = arrayToObject(included.filter((i) => i.type === type))
      return obj
    }, {})
  }
}

export default BaseNormalizer
