import * as React from 'react'
import { BankOutlined } from '@ant-design/icons'
import { Avatar, Col, List, Row, Typography } from 'antd'

interface Props {
  contact: IContact
  supplierStations: ISupplierStation[]
}

const UserInfo: React.FC<Props> = ({ contact, supplierStations }) => {
  return (
    <Row>
      <Col span={24} hidden={!contact}>
        <Typography.Title level={4}>
          {contact.first_name} {contact.last_name}
        </Typography.Title>
        <p className="text-gray">{contact.email}</p>
      </Col>
      <Col span={24}>
        <List
          itemLayout="horizontal"
          dataSource={supplierStations}
          rowKey="id"
          renderItem={(station) => (
            <List.Item extra={station.id}>
              <List.Item.Meta
                avatar={
                  <Avatar>
                    <BankOutlined />
                  </Avatar>
                }
                title={station.name}
                description={station.email}
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  )
}

export default UserInfo
