import { message as Toast, Modal } from 'antd'
import Message from 'app/components/UI-Elements/Feedback/messages/Message'
import React from 'react'
import { useContext, useState } from 'react'

import { AlertConfig, BoxConfig, NotifyContextType, ToastConfig } from './types'

const NotifyContext = React.createContext<NotifyContextType>(null)

export function useNotifier() {
  const { toastify, alertify, boxefy, boxHolder } = useContext(NotifyContext)
  return { toastify, alertify, boxefy, boxHolder }
}

type Props = {
  children: React.ReactNode
}

const FeedbackProvider: React.FC<Props> = ({ children }) => {
  const [boxHolder, setBoxHolder] = useState<React.ReactNode>(null)

  const toastify = (config: ToastConfig) => {
    Toast.config({
      duration: config.duration,
      maxCount: config.maxCount
    })
    Toast[config.type](config.content)
  }

  const alertify = (config: AlertConfig) => {
    Modal[config.type]({
      title: config.title,
      content: config.content,
      onOk: config.onOk && config.onOk()
    })
  }

  const boxefy = (config: BoxConfig) => {
    const Box = (
      <Message
        showIcon
        onClose={() => setBoxHolder(null)}
        message={config.content}
        closable={config.closable}
        type={config.type}
      />
    )
    setBoxHolder(Box)
  }

  const contextValue: NotifyContextType = {
    toastify,
    alertify,
    boxefy,
    boxHolder
  }

  return <NotifyContext.Provider value={contextValue}>{children}</NotifyContext.Provider>
}

export default FeedbackProvider
