/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from 'react'

export const retry = (
  fn: () => Promise<any>,
  retriesLeft = 2,
  interval = 1000
): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            window.location.reload()
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export function flatMapLineItems(lineItem: ILineItem): ILineItem[] {
  return lineItem.sub_items.length > 0 ? lineItem.sub_items.flatMap(flatMapLineItems) : [lineItem]
}

export const getBaseRoutePath = (path: string) => `${path}/*`
