import { Col, Space } from 'antd'
import ChangeRequestForm from 'app/components/Core/ChangeRequest/Form'
import ChangeRequestAnsweredList from 'app/components/Core/ChangeRequest/List/Answered'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import Spinner from 'app/components/UI-Elements/Feedback/loading/Spinner'
import { RecordType } from 'app/constants/GlobalTypes'
import { useShow } from 'app/lib/hooks/useApi'
import IntlHtmlMessages from 'app/util/IntlHtmlMessages'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const ChangeRequest: React.FC<MemberProps<IRental>> = ({ member }) => {
  const { order_request_id } = useParams<{ order_request_id: string }>()
  const {
    response,
    loading,
    execute: showChangeRequest
  } = useShow<IChangeRequest>(RecordType.change_request)

  useEffect(() => {
    fetch()
  }, [])

  const fetch = () => {
    showChangeRequest({ id: Number(order_request_id) })
  }

  const pendingProducts =
    response?.product_request_items.filter((item) => item.response === 'pending') || []

  const pendingDeliveries =
    response?.delivery_request_items.filter((item) => item.response === 'pending') || []

  const hasPendingRequests = pendingProducts.length > 0 || pendingDeliveries.length > 0
  const hasProductRequestItems = (response?.product_request_items || []).length > 0

  if (loading) {
    return <Spinner />
  }

  return (
    <If condition={hasProductRequestItems || hasPendingRequests}>
      <Col span={24}>
        <KCard
          title={
            <div>
              <IntlHtmlMessages
                id="rental-show-change-requests-title"
                values={{
                  rentalCode: member.rental_code
                }}
              />
            </div>
          }>
          <Space className="full-width" direction="vertical" size="large">
            <Choose>
              <When condition={hasPendingRequests}>
                <ChangeRequestForm member={response} props={{ onSuccess: fetch }} />
              </When>
              <When condition={hasProductRequestItems}>
                <ChangeRequestAnsweredList collection={[response]} />
              </When>
            </Choose>
          </Space>
        </KCard>
      </Col>
    </If>
  )
}

export default ChangeRequest
