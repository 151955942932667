import 'antd/dist/antd.less'
import '../styles/app.scss'

import { ConfigProvider } from 'antd'
import { lazy, Suspense, useEffect, useState } from 'react'
import * as React from 'react'
import { isIE } from 'react-device-detect'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes as Switch } from 'react-router-dom'

import AppLocale from '../lngProvider/index'
import PageLoadingView from './components/UI-Elements/Feedback/loading/PageLoading'
import LoginPageLayout from './components/UI-Elements/Layout/LoginPageLayout'
import { Dispatchable } from './constants/GlobalTypes'
import { Routes } from './constants/Routes'
import PageScaffold from './pages/HOC/PageScaffold'
import { authenticateUser, authenticateUserWithLoginToken } from './redux/actions/User'
import MainRoutes from './routes/MainRoutes'
import PrivateRoute from './routes/PrivateRoutes'
import IntlService from './services/IntlService'
import LoginService from './services/LoginService'
import { getBaseRoutePath, retry } from './util/Utilities'

const LoginPage = lazy(() => retry(() => import('./pages/Login/Views/LoginPage')))
const ResetPasswordPage = lazy(() =>
  retry(() => import('./pages/ResetPassword/Views/ResetPasswordPage'))
)
const AccountsPage = lazy(() => retry(() => import('./pages/AccountsPage')))

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const { langCode } = useSelector<AppState, UserReducerState>((state) => state.user)
  const dispatch = useDispatch<Dispatchable>()

  useEffect(() => {
    const url_token: string = LoginService.getLoginTokenFromUrl()
    if (url_token) {
      dispatch(authenticateUserWithLoginToken(url_token))
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      dispatch(authenticateUser())
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [authenticateUser, authenticateUserWithLoginToken])

  // revert this change on IE 11 support
  // if (isIE && parseInt(browserVersion) < 11) {
  //   return <h1 className="center-content mt-5">Bitte aktualisieren Sie den Browser</h1>
  // }

  if (isIE) {
    return (
      <h1 className="center-content mt-5">
        Bitte aktualisieren Sie den Browser. <br />
        Bitte verwenden Sie Microsoft Edge anstelle des Internet Explorers
      </h1>
    )
  }

  if (loading) {
    return <PageLoadingView />
  }

  // TODO: Change this with locale info from backend
  const { locale, messages, antLocale } =
    AppLocale[IntlService.isLanguageValid(langCode) ? langCode : 'de']

  return (
    <IntlProvider
      textComponent={React.Fragment}
      locale={locale}
      messages={messages}
      defaultLocale="de">
      <ConfigProvider form={{ validateMessages: messages.formValidation }} locale={antLocale}>
        <Switch>
          <Route
            key="login-page"
            path={Routes.Authentication.collection().login}
            element={
              <Suspense fallback={null}>
                <LoginPageLayout>
                  <LoginPage />
                </LoginPageLayout>
              </Suspense>
            }
          />
          <Route
            key="accounts-page"
            path={Routes.Authentication.collection().account}
            element={
              <PageScaffold>
                <AccountsPage />
              </PageScaffold>
            }
          />
          <Route
            key="reset-password-page"
            path={Routes.Authentication.collection().reset_password}
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <ResetPasswordPage />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            key="main-pages"
            path={getBaseRoutePath('')}
            element={
              <PrivateRoute>
                <PageScaffold>
                  <MainRoutes />
                </PageScaffold>
              </PrivateRoute>
            }
          />
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

export default App
