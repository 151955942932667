import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import * as React from 'react'

import ChangesList from '../PurchasingRequestItem/ChangesList'

const ProductRequestItemChangesPopover: React.FC<MemberProps<IProductRequestItem>> = ({
  member
}) => {
  return (
    <Popover content={<ChangesList member={member} />}>
      <InfoCircleOutlined />
    </Popover>
  )
}

export default ProductRequestItemChangesPopover
