import { dayjs } from '@2wunder/klarx-tool'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import * as React from 'react'

type Props = {
  changes: Partial<PurchasingRequestItemChanges>
  attribute: keyof PurchasingRequestItemChanges
}

const ProductRequestItemChange: React.FC<Props> = ({ changes, attribute }) => {
  const [oldValue, newValue] = changes[attribute]

  const valueRenderer = (attribute: keyof PurchasingRequestItemChanges, value: string) => {
    switch (attribute) {
      case 'delivery_date':
      case 'pick_up_date':
        return dayjs(value).toLocaleDate()
      default:
        return value
    }
  }

  return (
    <Space direction="horizontal">
      <span>{valueRenderer(attribute, oldValue)}</span>
      <ArrowRightOutlined />
      <span>{valueRenderer(attribute, newValue)}</span>
    </Space>
  )
}

export default ProductRequestItemChange
