import { LogoutOutlined } from '@ant-design/icons/lib'
import { Col, Divider, Row } from 'antd'
import Button from 'app/components/UI-Elements/Controls/buttons/Button'
import SimpleSelect from 'app/components/UI-Elements/Controls/inputs/Selects/_SimpleSelect'
import Drawer from 'app/components/UI-Elements/Layout/Drawer/Drawer'
import { allLocales } from 'app/constants/Enums'
import { Dispatchable, LanguageSelectOptionType } from 'app/constants/GlobalTypes'
import { Routes } from 'app/constants/Routes'
import { toggleProfileSidebar } from 'app/redux/actions/AppActions'
import { updateLocale } from 'app/redux/actions/User'
import { logoutUser } from 'app/redux/actions/User'
import CommonService from 'app/services/CommonService'
import IntlStringMessages from 'app/util/IntlStringMessages'
import { useState } from 'react'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import UserInfo from './UserInfo'

const LanguageLabel: React.FC<{ langProps: LanguageSelectOptionType }> = ({ langProps }) => {
  return (
    <span className="d-flex align-items-center">
      <i className={`mr-2 flag flag-24 flag-${langProps.icon}`} />
      <span>{langProps.label}</span>
    </span>
  )
}

const ProfileSidebar: React.FC = () => {
  const [loggingOut, setLoggingOut] = useState(false)
  const [updatingLocale, setUpdatingLocale] = useState(false)
  const dispatch = useDispatch<Dispatchable>()
  const { profileSidebarOpen } = useSelector<AppState, AppReducerState>((state) => state.appReducer)
  const { contact, supplierStations, langCode } = useSelector<AppState, UserReducerState>(
    (state) => state.user
  )
  const { formatMessage } = useIntl()

  const handleLanguageChange = (lang: LanguageSelectOptionType) => {
    setUpdatingLocale(true)
    dispatch(updateLocale(contact.id, lang.language)).finally(() => {
      dispatch(toggleProfileSidebar(false))
      setUpdatingLocale(false)
    })
  }

  const handleLogOut = () => {
    setLoggingOut(true)
    logoutUser().finally(() =>
      CommonService.redirectToLink(Routes.Authentication.collection().login)
    )
  }

  return (
    <Drawer
      padding={10}
      className="profile-sidebar-drawer-container"
      header={<IntlStringMessages id="profile.menu.header" />}
      onClose={() => dispatch(toggleProfileSidebar(false))}
      open={profileSidebarOpen}>
      <Row>
        <Col span={24}>
          <UserInfo contact={contact} supplierStations={supplierStations} />
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Divider className="my-0" />
        </Col>
        <Col span={24}>
          <SimpleSelect<LanguageSelectOptionType>
            loading={updatingLocale}
            className="full-width"
            onChange={handleLanguageChange}
            entries={allLocales}
            value={allLocales.find((locale) => locale.language === langCode)}
            customOptionRenderer={(record) => <LanguageLabel langProps={record} />}
          />
        </Col>
        <Col span={24}>
          <Button
            title={formatMessage({ id: 'header.menu.list.logout' })}
            icon={<LogoutOutlined />}
            onClick={handleLogOut}
            loading={loggingOut}
            type="default"
          />
        </Col>
      </Row>
    </Drawer>
  )
}

export default ProfileSidebar
