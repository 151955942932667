import { Col, Form, Row } from 'antd'
import Button from 'app/components/UI-Elements/Controls/buttons/Button'
import KFormList from 'app/components/UI-Elements/Forms/_KFormList'
import { RecordType } from 'app/constants/GlobalTypes'
import { useUpdate } from 'app/lib/hooks/useApi'
import { pick } from 'lodash'
import * as React from 'react'
import { useIntl } from 'react-intl'

import DeliveryRequestItemFormItem from './DeliveryRequestItem/FormItem'
import ProductNewRequestFormItem from './ProductRequestItem/RequestFormItem'

type Props = {
  onSuccess?: () => void
}
const ChangeRequestForm: React.FC<MemberProps<IChangeRequest, Props>> = ({
  member,
  props: { onSuccess }
}) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const { execute, loading } = useUpdate(RecordType.change_request)

  const productItems = member?.product_request_items?.filter((i) => i.response === 'pending') || []
  const deliveryItems =
    member?.delivery_request_items?.filter((i) => i.response === 'pending') || []

  const onSubmit = (
    values: IPurchasingRequest & { product_request_items: IProductRequestItem[] }
  ) => {
    const product_request_items = [
      ...(values?.delivery_request_items?.map((item) => ({ ...item, response: 'confirmed' })) ||
        []),
      ...(values?.product_request_items || []),
      ...values?.product_request_items.flatMap((i) =>
        i.sub_items.map((item) => ({
          ...item,
          ...(item.included ? { response: i.response } : {})
        }))
      )
    ]

    const payload = {
      id: member.id,
      purchasing_request: {
        request_items_attributes: product_request_items
          .map((i: IProductRequestItem) => ({
            ...i,
            ...(i.response === 'declined' ? { decline_reason: 'no_info' } : {})
          }))
          .map((i) => pick(i, 'id', 'response', 'unit_id', 'product_id', 'net_price', 'annotation'))
      }
    }
    execute(payload)
      .then(onSuccess)
      .catch((err: string) => console.warn({ err }))
  }

  const getSubItems = (id: number) => productItems.filter((item) => item.parent_id === id)

  const mainItems = productItems
    .filter((item) => !item.parent_id)
    .map((item) => ({ ...item, sub_items: getSubItems(item.id) }))

  const initialValues = {
    product_request_items: mainItems,
    delivery_request_items: deliveryItems.map((item) => ({
      id: item.id,
      net_price: item.net_price
    }))
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      size="large">
      <Row gutter={[0, 8]}>
        <Col span={24}>
          {formatMessage({ id: 'rental-show-change-request-form-product-description' })}
        </Col>
        <Col span={24}>
          {formatMessage({ id: 'rental-show-change-request-new-product-description' })}
        </Col>
        <Col span={24}>
          <Row gutter={[0, 24]}>
            <KFormList name="product_request_items">
              {() =>
                mainItems.map((item, index) => (
                  <Col span={24} key={index}>
                    <ProductNewRequestFormItem
                      member={item}
                      props={{
                        index: index,
                        supplier_station_id: member?.supplier_station_id,
                        form,
                        showFormDetails: !member?.has_started
                      }}
                    />
                  </Col>
                ))
              }
            </KFormList>
          </Row>
        </Col>
        <If condition={deliveryItems.length > 0}>
          <Col span={24}>
            <h3>{formatMessage({ id: 'rental-show-change-request-change-delivery-title' })}</h3>
            <p>{formatMessage({ id: 'rental-show-change-request-form-delivery-description' })}</p>
            <Row gutter={[0, 12]}>
              <KFormList name="delivery_request_items">
                {() =>
                  deliveryItems.map((item, index) => (
                    <DeliveryRequestItemFormItem key={index} member={item} props={{ index }} />
                  ))
                }
              </KFormList>
            </Row>
          </Col>
        </If>
        <Col span={24} className="text-right mt-2">
          <Button
            loading={loading}
            type="default"
            onClick={() => form.submit()}
            title={'Antwort absenden'}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default ChangeRequestForm
