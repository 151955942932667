import { Row } from 'antd'
import DeliveryRequestItemList from 'app/components/Core/DeliveryRequestItem/List'
import ProductRequestItemList from 'app/components/Core/ProductRequestItem/List'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import * as React from 'react'
import { useIntl } from 'react-intl'

const mapSubItems = (requestItems: IProductRequestItem[]) => {
  const itemIds = requestItems.map((i) => i.id)
  return (
    requestItems
      // do not filter sub items without parent item (in case parent is accepted but sub item is declined)
      .filter((item) => !item.parent_id || !itemIds.includes(item.parent_id))
      .map((item) => ({
        ...item,
        sub_items: requestItems.filter((subItem) => subItem.parent_id === item.id)
      }))
  )
}

type Props = {
  purchasingRequest: IPurchasingRequest
  responseType: IProductRequestItem['response']
}

const PurchasingRequestItems: React.FC<Props> = ({
  purchasingRequest,
  responseType,
  children = null
}) => {
  const { formatMessage } = useIntl()

  const productRequestItems = purchasingRequest.product_request_items.filter(
    (item) => item.response === responseType
  )
  const deliveryRequestItems = purchasingRequest.delivery_request_items.filter(
    (item) => item.response === responseType
  )
  const hasItems = productRequestItems.length > 0 || deliveryRequestItems.length > 0

  return (
    <KCol span={24} render={hasItems}>
      <KCard
        title={formatMessage(
          { id: `${responseType}-request-items-title` },
          { code: purchasingRequest.code }
        )}>
        <Row gutter={[0, 24]}>
          {children}
          <KCol span={24} render={productRequestItems.length > 0}>
            <ProductRequestItemList collection={mapSubItems(productRequestItems)} />
          </KCol>
          <KCol span={24} render={deliveryRequestItems.length > 0}>
            <DeliveryRequestItemList collection={deliveryRequestItems} />
          </KCol>
        </Row>
      </KCard>
    </KCol>
  )
}

export default PurchasingRequestItems
