import { message } from 'antd'
import { getQueryString } from 'app/lib/utils/search-params'
import { History } from 'history'
import { ReactNode } from 'react'

class CommonService {
  static redirectToLink = (redirectionLink: string) => {
    window.location.href = redirectionLink
  }

  static removeParamFromRoute = (param = '') => {
    const search = window.location.search || ''
    const searchObject = new URLSearchParams(search.slice(1))
    searchObject.delete(param)
    return searchObject.toString()
  }

  static openSnackbar = (
    content: string | string[] | ReactNode,
    type: 'error' | 'success' | 'warning' | 'info' = 'error'
  ) => {
    if (content) {
      message.config({
        duration: 5, // as seconds
        maxCount: 3
      })
      content = Array.isArray(content) ? content[0] : content
      message[type](content)
    }
  }

  static scrollToElementTop = (id: string, extraOffset = 0) => {
    setTimeout(() => {
      const element = document.getElementById(id)
      if (!element) return
      try {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: element.offsetTop + extraOffset
        })
      } catch (err) {
        console.warn(err)
      }
    }, 200)
  }

  static getQueryParamAsString = (
    location: History['location'],
    param: string
  ): string | undefined => {
    const query = getQueryString(location.search, param)
    return Array.isArray(query) ? query[0] : query
  }
}

export default CommonService
