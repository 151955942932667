import { Col, Row } from 'antd'
import * as React from 'react'
import { useIntl } from 'react-intl'

const Specifications: React.FC<MemberProps<IPurchasingRequestItem>> = ({ member }) => {
  const { formatMessage } = useIntl()
  const { specifications } = member

  return (
    <Row className="attribute-values-panel" justify="space-between" gutter={[0, 8]}>
      <Col span={24}>
        <span>{formatMessage({ id: 'rental.request.customer.specifications' })}</span>
      </Col>
      {specifications.map((specification) => (
        <Col key={specification.name} span={11}>
          <Row>
            <Col span={24}>
              <span className="text-primary-light text-sm">{specification.name}</span>
            </Col>
            <Col span={24}>
              <span>{specification.value}</span>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  )
}

export default Specifications
