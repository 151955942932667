import { Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { DocumentUploadProps } from 'app/constants/GlobalTypes'
import * as React from 'react'

const { Dragger } = Upload

interface Props<T = IDocument> {
  handleRemove: (file: UploadFile<T>) => void
  fileList: T[]
  handleUpload: (file: File) => void
  children: string | React.ReactNode
  showRemoveIcon?: boolean
}

function FileUploadDragger<T extends DocumentUploadProps>({
  handleRemove,
  fileList = [],
  handleUpload,
  children,
  showRemoveIcon = true
}: Props<T>) {
  const mapFiles = (): UploadFile<T>[] => {
    return fileList.map((file) => {
      return {
        uid: file.id.toString(),
        type: 'document',
        originFileObj: null,
        ...file
      }
    })
  }

  const beforeUpload = (file: File): boolean => {
    handleUpload(file)
    return false
  }

  return (
    <Dragger
      multiple={true}
      action={null}
      onRemove={handleRemove}
      beforeUpload={beforeUpload}
      fileList={mapFiles()}
      showUploadList={{ showRemoveIcon: showRemoveIcon }}>
      {children}
    </Dragger>
  )
}

export default FileUploadDragger
