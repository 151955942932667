import { Col, Row } from 'antd'
import DeliveryRequestItemSummary from 'app/components/Core/DeliveryRequestItem/Summary'
import InvoiceUpload from 'app/components/Core/Invoice/Upload'
import ProductRequestItemPrices from 'app/components/Core/ProductRequestItem/Prices'
import RentalInfoCard from 'app/components/Core/Rental/InfoCard'
import MultipleUploadsCard from 'app/components/Core/Rental/MultipleUploadsCard'
import Status from 'app/components/Core/Rental/Status/Message'
import RentalRequestError from 'app/components/Core/RentalRequest/Error'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import { RecordType } from 'app/constants/GlobalTypes'
import { useShow } from 'app/lib/hooks/useApi'
import Loading from 'app/pages/AvailabilityRequest/Pages/Loading'
import ChangeRequest from 'app/pages/PurchasingRequest/Components/ChangeRequest'
import ChangeRequestSelected from 'app/pages/PurchasingRequest/Components/ChangeRequestSelected'
import Header from 'app/pages/PurchasingRequest/Components/Header'
import IntlHtmlMessages from 'app/util/IntlHtmlMessages'
import IntlStringMessages from 'app/util/IntlStringMessages'
import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const confirmedAndSelected = ({ status, response }: IProductRequestItem | IDeliveryRequestItem) =>
  status == 'selected' && response === 'confirmed'

const PurchasingRequestShowPage: React.FC = () => {
  const { order_request_id } = useParams<{ order_request_id: string }>()
  const purchasing_request_id = order_request_id
  const { execute, response: purchasingRequest } = useShow<IPurchasingRequest>(
    RecordType.purchasing_request
  )

  useEffect(() => {
    execute({
      id: Number(purchasing_request_id)
    })
  }, [purchasing_request_id])

  if (!purchasingRequest) {
    return <Loading />
  }

  const selectedProducts = purchasingRequest.product_request_items.filter(confirmedAndSelected)

  const selectedDeliveries = purchasingRequest.delivery_request_items.filter(confirmedAndSelected)

  const referenceNumbers = [...selectedProducts, ...selectedDeliveries].map(
    ({ item }) => item.reference_number
  )

  const additionalCosts = (purchasingRequest.rental?.line_items || []).filter(
    (line_item) => !referenceNumbers.includes(line_item.id.toString())
  )

  return (
    <Choose>
      <When
        condition={selectedProducts.length > 0 || purchasingRequest.rental.processed_with_supplier}>
        <Row gutter={[16, 16]} className="availability-request-container">
          <Col span={16}>
            <Row gutter={[16, 16]}>
              <If condition={purchasingRequest.rental}>
                <ChangeRequest member={purchasingRequest.rental} />
              </If>
              <Col span={24}>
                <KCard
                  title={
                    <Row justify={'space-between'}>
                      <Col>
                        <IntlHtmlMessages
                          id="rental-show-rental-detail"
                          values={{
                            rentalCode: purchasingRequest.rental.rental_code
                          }}
                        />
                      </Col>
                      <Col className={'text-right'}>
                        <Status member={purchasingRequest.rental} />{' '}
                      </Col>
                    </Row>
                  }>
                  <Header member={purchasingRequest} />
                </KCard>
              </Col>
              <Col span={24}>
                <MultipleUploadsCard
                  member={purchasingRequest.rental}
                  props={{ supplier_station_id: purchasingRequest.supplier_station_id }}
                />
              </Col>
              <Col span={24}>
                <KCard title={<IntlStringMessages id="rental-show-machine-and-prices" />}>
                  <ProductRequestItemPrices
                    collection={[
                      ...selectedProducts,
                      ...selectedDeliveries.map((i) => ({ ...i, total_cost_price: i.net_price }))
                    ]}
                    props={{ additionalCosts }}
                  />
                </KCard>
              </Col>
              <Col span={24}>
                <KCard title={<IntlStringMessages id="rental-show-transport" />}>
                  <DeliveryRequestItemSummary collection={selectedDeliveries} />
                </KCard>
              </Col>
              <ChangeRequestSelected />
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <InvoiceUpload
                  rental_id={purchasingRequest.rental.id}
                  supplier_station_id={purchasingRequest.supplier_station_id}
                />
              </Col>
              <Col span={24}>
                <RentalInfoCard member={purchasingRequest.rental} />
              </Col>
            </Row>
          </Col>
        </Row>
      </When>
      <Otherwise>
        <RentalRequestError />
      </Otherwise>
    </Choose>
  )
}

export default PurchasingRequestShowPage
