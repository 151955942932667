/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch } from 'index'
import { FormEvent, MouseEvent as ReactMouseEvent } from 'react'
import { ThunkDispatch } from 'redux-thunk'

export type FormSubmissionEvent =
  | ReactMouseEvent<HTMLElement, MouseEvent>
  | FormEvent<HTMLFormElement>
export type RequestPayload<T> = { [key: string]: string | number | T | RequestPayload<any> } | T
export type PromisedDispatch<T> = (dispatch: AppDispatch) => Promise<T>
export type DispatchWithState = (dispatch: AppDispatch, getState: () => AppState) => void
export type PromisedDispatchWithState<T> = (
  dispatch: AppDispatch,
  getState: () => AppState
) => Promise<T>
export type Dispatchable = ThunkDispatch<AppState, any, ActionTypes>
export type RoleSelectOptionType = { id: number; value: string; label: string; description: string }
export type GenderSelectOptionType = { id: string; value: string; label: string }
export type LanguageSelectOptionType = { id: number; language: string; label: string; icon: string }
export type SupplierResponseSelectOptionType = { id: number; value: string; label: string }
export type PeriodEnumSelectOptionType = { id: number; value: string; label: string }
export type SubItemTypeEnumSelectOptionType = {
  id: number
  value: string
  name?: string
  label: string
}
export type DamageResponseEnumSelectOptionType = { id: number; value: string; label: string }

export interface DocumentUploadProps {
  id: number
  name: string
  size: number
  url: string
}

export type PaginatedResponse<T> = {
  data: T[]
  pagination?: IPaginationMeta
}

export interface GenericObject<T> {
  [key: string]: T
}

export const RecordType = {
  product_instance_booking: 'product_instance_booking',
  product_instance: 'product_instance',
  rental: 'rental',
  rental_line_item: 'rental_line_item',
  order_request: 'order_request',
  purchasing_request: 'purchasing_request',
  change_request: 'change_request',
  product_request_item: 'product_request_item',
  delivery_request_item: 'delivery_request_item',
  line_item: 'line_item',
  damage_item: 'damage_item',
  purchasing_request_item: 'purchasing_request_item',
  pluralize: () => {
    let types = { ...RecordType }
    Object.keys(RecordType).map((key: keyof typeof RecordType) => {
      return (types = {
        ...types,
        [key]: `${types[key]}s`
      })
    })
    return types
  }
}
