import * as React from 'react'
import { FormattedMessage } from 'react-intl'

declare interface IntlParams {
  id?: string
  defaultMessage?: string
  values?: Record<string, unknown>
}

const IntlStringMessages: React.FC<IntlParams> = ({
  id = 'no.context',
  defaultMessage = null,
  values
}) => {
  return <FormattedMessage id={id} defaultMessage={defaultMessage || id} values={values} />
}

export default IntlStringMessages
