import {
  AppstoreOutlined,
  BranchesOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined
} from '@ant-design/icons/lib'
import { Menu } from 'antd'
import { Routes } from 'app/constants/Routes'
import IntlStringMessages from 'app/util/IntlStringMessages'
import { MenuInfo } from 'rc-menu/lib/interface'
import * as React from 'react'

import CustomIcon from '../../General/icons/CustomIcon'
import MenuItem from '../menuItem/MenuItem'
import MenuItemWithBadge from '../menuItem/MenuItemWithBadge'

declare interface Props {
  onSelect?: (param: MenuInfo) => void
  defaultValue: string
  requestedCount?: number
  sidebarOpen: boolean
  rentalParkOptIn?: boolean
}

const Items: React.FC<Props> = ({
  onSelect,
  defaultValue,
  requestedCount,
  sidebarOpen,
  rentalParkOptIn
}) => (
  <Menu onClick={onSelect} defaultSelectedKeys={[defaultValue]} mode="inline">
    <MenuItem key={Routes.Dashboard.collection().index} icon={<AppstoreOutlined />}>
      <IntlStringMessages id="header.dashboard" />
    </MenuItem>
    <MenuItemWithBadge
      key={Routes.PurchasingRequest.collection().index}
      icon={<UnorderedListOutlined />}
      sidebarOpen={sidebarOpen}
      badgeCount={requestedCount}>
      <IntlStringMessages id="header.rentals" />
    </MenuItemWithBadge>
    <MenuItem key={Routes.Fleet.collection().index} icon={<BranchesOutlined />}>
      <IntlStringMessages id="header.fleet-review" />
    </MenuItem>
    <If condition={rentalParkOptIn}>
      <MenuItem
        key={Routes.RentalPark.collection().timeline}
        icon={<CustomIcon type="rental_park" className="anticon" />}>
        <IntlStringMessages id="header.rental_park" />
      </MenuItem>
    </If>
    <MenuItem key={Routes.Role.collection().index} icon={<UsergroupAddOutlined />}>
      <IntlStringMessages id="header.roles" />
    </MenuItem>
    <MenuItem key={Routes.User.collection().index} icon={<UserOutlined />}>
      <IntlStringMessages id="header.users" />
    </MenuItem>
    <MenuItem key={Routes.FAQ.collection().index} icon={<QuestionCircleOutlined />}>
      <IntlStringMessages id="header.faq" />
    </MenuItem>
  </Menu>
)

export default Items
