import { priceFormatter } from '@2wunder/klarx-tool'
import { Col, List, Row, Space, Typography } from 'antd'
import IntlStringMessages from 'app/util/IntlStringMessages'
import { isEmpty } from 'lodash'
import * as React from 'react'

import ProductRequestItemTag from '../ProductRequestItem/Tag'
import ProductRequestItemChangesPopover from './ChangesPopover'

type ItemType = Partial<IProductRequestItem>

type Props = {
  additionalCosts: ILineItem[]
}

const Header: React.FC = () => {
  return (
    <Row>
      <Col span={8}>
        <b>
          <IntlStringMessages id="rental-show-invoice-items" />
        </b>
      </Col>
      <Col span={5} className="text-right">
        <b>
          <IntlStringMessages id="rental-show-line-item-dates" />
        </b>
      </Col>
      <Col span={5} className="text-right">
        <b>
          <IntlStringMessages id="rental-show-invoices-rental-duration" />
        </b>
      </Col>
      <Col span={3} className="text-right">
        <b>
          <IntlStringMessages id="rental-show-invoices-rental-unit-price" />
        </b>
      </Col>
      <Col span={3} className="text-right">
        <b>
          <IntlStringMessages id="rental-show-rental-item-price" />
        </b>
      </Col>
    </Row>
  )
}

const ListItemRow = ({
  requestItem,
  isSubItem
}: {
  requestItem: ItemType
  isSubItem?: boolean
}) => {
  const isProduct = Boolean(requestItem.product_id)

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={8} className={isSubItem ? 'pl-3' : ''}>
            <Space direction="horizontal">
              {requestItem.product_name || requestItem.item.name}
              <ProductRequestItemTag member={requestItem as IProductRequestItem} />
            </Space>
          </Col>
          <Col span={5} className="text-right">
            <Space direction="horizontal">
              <If condition={isProduct}>{requestItem.item.display_dates}</If>
              <If condition={!isEmpty(requestItem.requested_changes)}>
                <ProductRequestItemChangesPopover member={requestItem as IProductRequestItem} />
              </If>
            </Space>
          </Col>
          <Col span={5} className="text-right">
            {requestItem.display_duration}
          </Col>
          <Col span={3} className="text-right">
            {priceFormatter(requestItem.net_price)}
          </Col>
          <Col span={3} className="text-right">
            {requestItem.included ? (
              <Typography.Text disabled>
                <IntlStringMessages id="rental-show-invoices-rental-free" />
              </Typography.Text>
            ) : (
              priceFormatter(requestItem.total_cost_price)
            )}
          </Col>
        </Row>
      </Col>
      {requestItem.sub_items?.map((subItem) => (
        <Col key={subItem.id} span={24}>
          <ListItemRow requestItem={subItem} isSubItem />
        </Col>
      ))}
    </Row>
  )
}

const ProductRequestItemPrices: React.FC<CollectionProps<ItemType, Props>> = ({
  collection: requestItems,
  props: { additionalCosts = [] } = {}
}) => {
  const getSum = React.useMemo(() => {
    return requestItems.reduce((sum, requestItem) => {
      const price = parseFloat(requestItem.total_cost_price || '0')
      return sum + price
    }, 0)
  }, [requestItems])

  const mainItems = requestItems
    .filter((requestItem) => !requestItem.parent_id)
    .map((requestItem) => ({
      ...requestItem,
      sub_items: requestItems.filter((subItem) => subItem.parent_id === requestItem.id)
    }))

  return (
    <Row>
      <Col span={24}>
        <List<ItemType>
          bordered={true}
          header={<Header />}
          dataSource={
            [
              ...mainItems,
              ...additionalCosts.map((item) => ({ ...item, item, net_price: item.cost_price }))
            ] as ItemType[]
          }
          renderItem={(requestItem: ItemType) => (
            <List.Item className="d-block" key={requestItem.id}>
              <ListItemRow requestItem={requestItem} />
            </List.Item>
          )}
          footer={
            <Row>
              <Col span={20}>
                <b>
                  <IntlStringMessages id="rental-show-net-price" />
                </b>
              </Col>
              <Col span={4} className="text-right">
                <b>{priceFormatter(getSum)}</b>
              </Col>
            </Row>
          }
        />
      </Col>
    </Row>
  )
}
export default ProductRequestItemPrices
