import * as React from 'react'
import { Tag as AntTag } from 'antd'
import { TagProps } from 'antd/es/tag'

interface Props extends TagProps {
  text: string | React.ReactNode
  color?: string
}

const Tag: React.FC<Props> = ({ text = '-', color = '', ...others }) => {
  return (
    <AntTag color={color} {...others}>
      {text}
    </AntTag>
  )
}

export default Tag
