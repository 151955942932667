import * as React from 'react'
import { Tooltip as AntTooltip } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'

type Props = {
  placement?: TooltipPlacement
  title: React.ReactNode
  children: React.ReactElement
  show?: boolean
}

const TooltipView: React.FC<Props> = ({ children, title, placement = 'top', show = true }) => {
  if (!show) {
    return <span>{children}</span>
  }
  return (
    <AntTooltip
      getPopupContainer={(_target: HTMLElement) => document.body}
      placement={placement}
      title={title}>
      {children}
    </AntTooltip>
  )
}

export default TooltipView
