import BaseNormalizer from './BaseNormalizer'

class RentalRequestItemDeliveryNormalizer {
  cargos: Array<ICargo>

  static normalizeRentalRequestItemDelivery(
    collectionResponse: CollectionSerializerResponse<IRentalRequestItemDelivery>
  ): IRentalRequestItemDelivery[] {
    const { data, included } = collectionResponse
    const normalizeIncluded: INormalizedIncluded = BaseNormalizer.normalizeIncluded(included, [
      'cargo'
    ])

    return data.map((item: any) => {
      const cargos = item.relationships.cargos.data.map(
        (cargo: Relationship) => normalizeIncluded.cargo[cargo.id]
      )
      return {
        ...item.attributes,
        cargos
      }
    })
  }
}

export default RentalRequestItemDeliveryNormalizer
