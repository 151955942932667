import { MouseEventHandler } from 'react'
import * as React from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'

type Props = {
  contact?: IContact
  className?: string
  onClick?: MouseEventHandler<HTMLSpanElement>
  size?: number | 'small' | 'default' | 'large' | undefined
  plainString?: string
}

const UserIconCircle: React.FC<Props> = ({
  plainString,
  contact,
  className,
  onClick,
  size = 'default',
  ...others
}) => {
  const getInitials = (name: string) => {
    let initials = name?.match(/\b\w/g) || []
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }

  return (
    <span onClick={onClick}>
      <Avatar className={className} size={size} {...others}>
        {getInitials(contact?.name || plainString) || <UserOutlined />}
      </Avatar>
    </span>
  )
}

export default UserIconCircle
