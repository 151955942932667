import { Col } from 'antd'
import ChangeRequestSelectedList from 'app/components/Core/ChangeRequest/List/Selected'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import Spinner from 'app/components/UI-Elements/Feedback/loading/Spinner'
import { RecordType } from 'app/constants/GlobalTypes'
import { useIndex } from 'app/lib/hooks/useApi'
import IntlStringMessages from 'app/util/IntlStringMessages'
import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const ChangeRequestSelected: React.FC = () => {
  const { supplier_station_id, rental_code } = useParams<{
    rental_code: string
    supplier_station_id: string
  }>()
  const { execute, response, loading } = useIndex<IChangeRequest>(RecordType.change_request)

  useEffect(() => {
    execute({
      filters: {
        code: rental_code,
        supplier_station_id: supplier_station_id,
        status: ['selected']
      }
    })
  }, [rental_code])

  if (loading) {
    return <Spinner />
  }

  return (
    <If condition={response.data?.length}>
      <Col span={24}>
        <KCard title={<IntlStringMessages id="rental-show-change-requests-history-title" />}>
          <ChangeRequestSelectedList collection={response.data} />
        </KCard>
      </Col>
    </If>
  )
}

export default ChangeRequestSelected
