import { valueExist } from 'app/util/Validation'

class ReducerService {
  static updateItemInArray = (data: any[] = [], itemToBeUpdated: any = {}): any => {
    if (valueExist(data)) {
      const index = data.findIndex((d) => parseInt(d.id) === parseInt(itemToBeUpdated.id))
      return [...data.slice(0, index), itemToBeUpdated, ...data.slice(index + 1)]
    } else {
      return []
    }
  }

  static removeItemFromArray = (data: any[], itemToBeRemoved: any = {}) => {
    const indexToRemove = data.findIndex((d) => parseInt(d.id) === parseInt(itemToBeRemoved.id))
    return [...data.slice(0, indexToRemove), ...data.slice(indexToRemove + 1)]
  }

  static removeIndexedItem = (data: any[], indexToRemove: number): any[] => {
    return [...data.slice(0, indexToRemove), ...data.slice(indexToRemove + 1)]
  }
}

export default ReducerService
