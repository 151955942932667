import { Layout } from 'antd'
import * as React from 'react'
import AppHeader from 'app/components/UI-Elements/Layout/AppHeader'

const LoginPageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Layout>
      <AppHeader toggleProfileSidebar={null} currentContact={null} />
      <Layout
        className="bg-color-secondary bg-content-secondary-head-shape"
        data-testid="login-page">
        <Layout.Content className="page-content center-content">{children}</Layout.Content>
      </Layout>
    </Layout>
  )
}

export default LoginPageLayout
