import { valueExist } from 'app/util/Validation'
import BaseNormalizer from './BaseNormalizer'

class RentalRequestNormalizer {
  static normalizeRentalRequest = (response: MemberSerializerResponse<IRentalRequest>) => {
    const { data, included } = response
    const normalizeIncluded: INormalizedIncluded = BaseNormalizer.normalizeIncluded(included, [
      'rental'
    ])
    return {
      ...data.attributes,
      rental: normalizeIncluded.rental[data.relationships.rental.data.id]
    }
  }

  static normalizeRentalRequestWithItems = (response: MemberSerializerResponse<IRentalRequest>) => {
    const { data, included } = response
    const normalizeIncluded: INormalizedIncluded = BaseNormalizer.normalizeIncluded(included, [
      'rental',
      'rental_request_item'
    ])
    return {
      ...data.attributes,
      rental: normalizeIncluded.rental[data.relationships.rental.data.id],
      rental_request_items: data.relationships.rental_request_items.data.map((i: any) => {
        const rri = normalizeIncluded.rental_request_item[i.id]
        return RentalRequestNormalizer.normalizeRentalRequestItemIncludedAttributes(rri)
      })
    }
  }

  static normalizeRentalRequestItemIncludedAttributes = (rri: any): IRentalRequestItem => {
    const { attributes } = rri.line_item
    const subItems = valueExist(rri.sub_rental_request_items)
      ? rri.sub_rental_request_items.map((item: any) =>
          RentalRequestNormalizer.normalizeRentalRequestItemIncludedAttributes(item.attributes)
        )
      : []

    return {
      ...rri,
      line_item: attributes,
      sub_rental_request_items: subItems
    }
  }
}

export default RentalRequestNormalizer
