import { Col, Row } from 'antd'
import UploadDocument from 'app/components/Core/Document/Upload'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import { DOCUMENT_TYPES_ENUM } from 'app/constants/Enums'
import {
  deleteRentalDocument,
  fetchRentalDocuments,
  getFormDataInfo,
  uploadRentalDocument
} from 'app/redux/actions/Document'
import IntlHtmlMessages from 'app/util/IntlHtmlMessages'
import IntlStringMessages from 'app/util/IntlStringMessages'
import * as React from 'react'

type Props = {
  supplier_station_id: number
}

const MultipleUploadsCard: React.FC<MemberProps<IRental, Props>> = ({
  member: rental,
  props: { supplier_station_id }
}) => {
  const { id } = rental

  const handleUpload = (file: File, document_type: IDocumentType) => {
    const formData = getFormDataInfo({ file, rental_id: id, supplier_station_id, document_type })
    return uploadRentalDocument(formData)
  }

  const fetch = (documet_type: IDocumentType) => {
    return fetchRentalDocuments(id, supplier_station_id, null, documet_type)
  }

  return (
    <KCard title={<IntlStringMessages id="finalize.rental.request.upload.title" />}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <h5 className="text-black mb-0">
                <IntlStringMessages id={'finalize.rental.request.delivery.upload.title'} />
              </h5>
            </Col>
            <Col span={12}>
              <span className="text-primary">
                <IntlStringMessages id="finalize.rental.request.delivery.upload.description.1" />
              </span>
            </Col>
            <Col span={12}>
              <span className="text-primary">
                <IntlStringMessages id="finalize.rental.request.delivery.upload.description.2" />
              </span>
            </Col>
            <Col span={12}>
              <UploadDocument
                fetchDocuments={() => fetch(DOCUMENT_TYPES_ENUM.CHECKOUT_DOCUMENT)}
                deleteDocument={(file: IDocument) => deleteRentalDocument(file.id)}
                uploadDocument={(file) =>
                  handleUpload(file, DOCUMENT_TYPES_ENUM.CHECKOUT_DOCUMENT)
                }>
                <IntlHtmlMessages id="upload-default-description" />
              </UploadDocument>
            </Col>
            <Col span={12}>
              <UploadDocument
                fetchDocuments={() => fetch(DOCUMENT_TYPES_ENUM.CHECKOUT_IMAGE)}
                deleteDocument={(file: IDocument) => deleteRentalDocument(file.id)}
                uploadDocument={(file) => handleUpload(file, DOCUMENT_TYPES_ENUM.CHECKOUT_IMAGE)}>
                <IntlHtmlMessages id="upload-default-description" />
              </UploadDocument>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <h5 className="text-black mb-0">
                <IntlStringMessages id={'finalize.rental.request.return.upload.title'} />
              </h5>
            </Col>
            <Col span={12}>
              <span className="text-primary">
                <IntlStringMessages id="finalize.rental.request.return.upload.description.1" />
              </span>
            </Col>
            <Col span={12}>
              <span className="text-primary">
                <IntlStringMessages id="finalize.rental.request.return.upload.description.2" />
              </span>
            </Col>
            <Col span={12}>
              <UploadDocument
                fetchDocuments={() => fetch(DOCUMENT_TYPES_ENUM.CHECKIN_DOCUMENT)}
                deleteDocument={(file: IDocument) => deleteRentalDocument(file.id)}
                uploadDocument={(file) => handleUpload(file, DOCUMENT_TYPES_ENUM.CHECKIN_DOCUMENT)}>
                <IntlHtmlMessages id="upload-default-description" />
              </UploadDocument>
            </Col>
            <Col span={12}>
              <UploadDocument
                fetchDocuments={() => fetch(DOCUMENT_TYPES_ENUM.CHECKIN_IMAGE)}
                deleteDocument={(file: IDocument) => deleteRentalDocument(file.id)}
                uploadDocument={(file) => handleUpload(file, DOCUMENT_TYPES_ENUM.CHECKIN_IMAGE)}>
                <IntlHtmlMessages id="upload-default-description" />
              </UploadDocument>
            </Col>
          </Row>
        </Col>
      </Row>
    </KCard>
  )
}

export default MultipleUploadsCard
