import { Modal } from 'antd'
import { NativeButtonProps } from 'antd/es/button/button'
import { ModalProps } from 'antd/es/modal'
import * as React from 'react'
import Button from 'app/components/UI-Elements/Controls/buttons/Button'
import './Modal.scss'

interface Props extends ModalProps {
  children: React.ReactNode
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onSave?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  open: boolean
  title?: React.ReactNode
  okText?: string
  cancelText?: string
  loading?: boolean
  okButtonProps?: NativeButtonProps
}

const ModalView: React.FC<Props> = ({
  children,
  open,
  onClose,
  onSave,
  title,
  okText = 'save',
  cancelText = 'cancel',
  loading,
  okButtonProps,
  ...others
}) => {
  return (
    <Modal
      title={title}
      visible={open}
      onCancel={onClose}
      okButtonProps={{ disabled: true }}
      footer={
        <div className="modal-footer">
          {onSave && (
            <div className="ml-2">
              <Button
                type={'default'}
                onClick={onSave}
                title={okText}
                loading={loading}
                disabled={okButtonProps?.disabled}
              />
            </div>
          )}
          <div>
            <Button
              onClick={onClose}
              title={cancelText}
              className="ml-3 ant-btn-secondary-border"
            />
          </div>
        </div>
      }
      {...others}>
      {children}
    </Modal>
  )
}

export default ModalView
