import { priceFormatter } from '@2wunder/klarx-tool'
import { Col, Row } from 'antd'
import * as React from 'react'

import Link from '../DataSheet/Link'
import Unit from './Unit'

const titleClassName = 'text-black mb-0'

type Props = {
  title?: React.ReactNode
  textClassName?: string
}

const ProductItemName: React.FC<MemberProps<IProductRequestItem, Props>> = ({
  member,
  props: { title, textClassName } = {}
}) => {
  const className = textClassName || titleClassName
  const name = member.product_name || member.item.name
  const { category_segment_name } = member.item

  return (
    <Row gutter={[8, 8]}>
      <Col flex="auto" className="d-flex">
        <Row className="full-width">
          <Col span={24}>
            {title ? title : <h3 className={className}>{category_segment_name}</h3>}
          </Col>
          <Col span={24} className="d-flex justify-content-between">
            <h6 className={className}>
              <span>{name}</span>
            </h6>
            <If condition={member.response === 'confirmed'}>
              <h6 className={className}>
                {priceFormatter(member.net_price)} / <Unit value={member.price_unit} />
              </h6>
            </If>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Link url={member.data_sheet_url} title={member.product_name} />
      </Col>
    </Row>
  )
}

export default ProductItemName
