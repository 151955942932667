import { openModal } from 'app/components/Helpers/Dialog'
import { Dispatchable } from 'app/constants/GlobalTypes'
import { fetchPurchasingRequest } from 'app/redux/actions/PurchasingRequest'
import { destroyRentalRequestState, respondRentalRequest } from 'app/redux/actions/RentalRequest'
import CommonService from 'app/services/CommonService'
import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { useOrderRequest } from './useOrderRequest'

export const useRentalRequest = () => {
  const [loading, setLoading] = useState(false)
  const { rentalRequest, purchasingRequest } = useSelector<AppState, RentalRequestReducerState>(
    (state) => state.rentalRequest
  )
  const dispatch = useDispatch<Dispatchable>()
  const { order_request_id } = useParams<{ order_request_id: string }>()
  const { formatMessage } = useIntl()
  const location = useLocation()
  const { isLegacy } = useOrderRequest()

  const fetch = useCallback(() => {
    const onError = (error: string[]) => {
      openModal(
        formatMessage({ id: 'error.default' }),
        error,
        formatMessage({ id: 'cancel' }),
        'error'
      )
    }
    if (isLegacy()) {
      const response = CommonService.getQueryParamAsString(location, 'supplier_response')

      dispatch(respondRentalRequest(Number(order_request_id), { response }))
        .catch(onError)
        .finally(() => setLoading(false))
    } else {
      dispatch(fetchPurchasingRequest(Number(order_request_id)))
        .catch(onError)
        .finally(() => setLoading(false))
    }
  }, [order_request_id])

  const destroyState = () => {
    dispatch(destroyRentalRequestState())
  }

  return {
    rentalRequest: rentalRequest || purchasingRequest,
    loading,
    fetch,
    isLegacy,
    destroyState
  }
}
