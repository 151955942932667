import { useState } from 'react'
import * as React from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { FilePdfOutlined } from '@ant-design/icons/lib'
import DataSheetModal from './DataSheetModal'

const Link: React.FC<{ url: string; title: string }> = ({ url, title }) => {
  const [openDataSheetModal, setOpenDataSheetModal] = useState<boolean>(false)
  return (
    <If condition={url}>
      <Row>
        <Col span={24}>
          <Button
            type="link"
            onClick={() => setOpenDataSheetModal(true)}
            icon={<FilePdfOutlined />}>
            <Typography.Text underline>Spec Sheet: {title}</Typography.Text>
          </Button>
        </Col>
        <DataSheetModal
          destroyOnClose={true}
          url={url}
          visible={openDataSheetModal}
          title={title}
          onCancel={() => setOpenDataSheetModal(false)}
        />
      </Row>
    </If>
  )
}

export default Link
