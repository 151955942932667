import { Typography } from 'antd'
import * as React from 'react'

import { validUrl } from '../../../util/Validation'

type Props = {
  deliveryAddress: IDeliveryAddress
  className?: string
  separator?: string
  link?: string
}

const AddressSummary: React.FC<Props> = ({ link, deliveryAddress, separator = ',', className }) => {
  const { address, zip, city } = deliveryAddress
  const overallAddress = `${address}${!zip && !city ? '' : `${separator} ${zip} ${city}`}`

  return validUrl(link) ? (
    <a href={link} target="_blank" className={className} rel="noopener noreferrer">
      <Typography.Text className={`text-link underline ${className}`}>
        {overallAddress}
      </Typography.Text>
    </a>
  ) : (
    <div className={className}>
      <Typography.Text ellipsis className={className}>
        {overallAddress}
      </Typography.Text>
    </div>
  )
}

export default AddressSummary
