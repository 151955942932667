import { Col, Divider, Row } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import Link from 'app/components/Core/DataSheet/Link'
import Button from 'app/components/UI-Elements/Controls/buttons/Button'
import SimpleSelect from 'app/components/UI-Elements/Controls/inputs/Selects/_SimpleSelect'
import FormItem from 'app/components/UI-Elements/Forms/_FormItem'
import { fetchProducts } from 'app/redux/actions/Product'
import IntlStringMessages from 'app/util/IntlStringMessages'
import { debounce, uniqBy } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import * as React from 'react'
import { useIntl } from 'react-intl'

type Props = {
  supplierStationId?: number
  categoryId?: number
  extraOptions?: IProduct[]
  openNewProductModal?: () => void
  required?: boolean
  product?: IProduct
  name?: FormItemProps['name']
  onChange?: (product?: IProduct) => void
}

const Products: React.FC<Props> = ({
  required,
  categoryId,
  product: respondedProduct,
  supplierStationId,
  extraOptions = [],
  name,
  openNewProductModal,
  onChange: onChangeProp,
  ...other
}) => {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const [products, setProducts] = useState<IProduct[]>([respondedProduct].filter(Boolean))
  const [product, setProduct] = useState<IProduct>(respondedProduct)
  const debouncedSearch = useCallback(debounce(searchProducts, 500), [extraOptions])

  useEffect(() => {
    if (!respondedProduct?.id) {
      searchProducts()
    }
  }, [])

  useEffect(() => {
    setProducts(uniqBy([...products, ...extraOptions], (product) => product.id))
  }, [extraOptions])

  const onTextChange = (q: string) => {
    setLoading(true)
    debouncedSearch(q.trim())
  }

  function searchProducts(q = '') {
    fetchProducts(q, categoryId, supplierStationId)
      .then((result) => {
        if (q !== product?.name && result.length > 0) {
          setProducts(result)
        } else {
          setProducts(uniqBy([...result, ...extraOptions], (product) => product.id))
        }
      })
      .finally(() => setLoading(false))
  }

  const dropDownFooter = (): React.ReactNode => {
    return (
      <div className="px-5 py-3">
        <div>
          <Divider className="mt-0">
            <IntlStringMessages id="or" />
          </Divider>
        </div>
        <div className="text-center">
          <Button
            onClick={openNewProductModal}
            title={formatMessage({ id: 'rental.request.new.product.search.add' })}
            className="ant-btn-secondary-border"
          />
        </div>
      </div>
    )
  }

  const notFoundContent = () => (
    <div className="text-center text-black">
      <IntlStringMessages id="rental.request.new.product.search.empty" />
    </div>
  )

  const onBlur = () => {
    setProducts(uniqBy([...products, ...extraOptions], (product) => product.id))
  }

  const getValueFromEvent = (product: IProduct) => product?.id

  const onChange = (product: IProduct) => {
    setProduct(product)
    onChangeProp?.(product)
  }

  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <FormItem
          getValueFromEvent={getValueFromEvent}
          hasFeedback={true}
          required={required}
          label={formatMessage({ id: 'rental.request.product.machine_type' })}
          name={name}>
          <SimpleSelect<IProduct>
            defaultActiveFirstOption={false}
            remote={true}
            onBlur={onBlur}
            loading={loading}
            notFoundContent={notFoundContent()}
            showSearch={true}
            onSearch={onTextChange}
            selectValueBy="id"
            placeholder={formatMessage({ id: 'rental.request.product.machine_type.placeholder' })}
            optionLabelProp="name"
            entries={products}
            onChange={onChange}
            dropDownFooter={dropDownFooter()}
            {...other}
          />
        </FormItem>
      </Col>
      <If condition={product}>
        <Col span={24}>
          <Link title={product?.name} url={product?.data_sheet_url} />
        </Col>
      </If>
    </Row>
  )
}

export default Products
