import Tag from 'app/components/UI-Elements/DataDisplay/Tag'
import * as React from 'react'

const StatusLabel: React.FC<MemberProps<Pick<IRental, 'status'>>> = ({ member: rental }) => {
  const tagProps = (): { [key: string]: string } => {
    switch (rental.status) {
      case 'missing_invoice':
        return {
          color: 'red',
          text: 'Rechnung fehlt'
        }
      case 'invoice_processing':
        return {
          color: 'blue',
          text: 'Rechnung in Bearbeitung'
        }
      case 'invoice_uploaded':
        return {
          color: 'green',
          text: 'Rechnung hochgeladen'
        }
      case 'responded': {
        return {
          color: 'green',
          text: 'Von dir bereits beantwortet'
        }
      }
      case 'waiting_for_customer':
        return {
          color: 'yellow',
          text: 'Warte auf Kunden'
        }
      case 'waiting_for_supplier' || 'waiting_for_finalized':
        return {
          color: 'yellow',
          text: 'Warte auf Vermieter'
        }
      case 'canceled':
        return {
          color: 'default',
          text: 'Storniert'
        }
      case 'unanswered' || 'waiting_for_finalized':
        return {
          color: 'yellow',
          text: 'Warte auf Vermieter'
        }
      case 'declined_by_supplier':
        return {
          color: 'red',
          displayText: 'Auftrag abgelehnt'
        }
      case 'successful':
        return {
          color: 'green',
          text: 'Auftrag bestätigt'
        }
      case 'finalized':
        return {
          color: 'green',
          text: 'Auftrag abgeschlossen'
        }
      case 'lost':
        return {
          color: 'default',
          text: 'Auftrag verloren'
        }
      default:
        return {
          color: 'blue',
          text: 'Auftrag in Bearbeitung'
        }
    }
  }

  return <Tag color={tagProps().color} text={tagProps().text} />
}

export default StatusLabel
