import { APP_ACTIONS } from '../../constants/ActionTypes'

export const toggleSidebar = (val: boolean): ActionTypes => {
  return {
    type: APP_ACTIONS.SIDEBAR_TOGGLE,
    payload: val
  }
}

export const toggleProfileSidebar = (val: boolean): ActionTypes => {
  return {
    type: APP_ACTIONS.PROFILE_SIDEBAR_TOGGLE,
    payload: val
  }
}
