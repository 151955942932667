import * as React from 'react'

type BoxProps = {
  children: React.ReactNode
  className?: string
}
const IconBox: React.FC<BoxProps> = ({ children, className }) => {
  return (
    <span className={`kicon-box kicon-box-md text-primary-light ${className}`}>{children}</span>
  )
}

type KIconProps = {
  icon: React.ReactNode
  className?: string
}

const KIcon: React.FC<KIconProps> = ({ icon, className }) => {
  return <IconBox className={className}>{icon}</IconBox>
}

export default KIcon
