import { APP_ACTIONS } from '../../constants/ActionTypes'

const initialState: AppReducerState = {
  sidebarOpen: true,
  profileSidebarOpen: false
}

const appReducer = (state: AppReducerState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case APP_ACTIONS.SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarOpen: action.payload
      }

    case APP_ACTIONS.PROFILE_SIDEBAR_TOGGLE:
      return {
        ...state,
        profileSidebarOpen: action.payload
      }
    default:
      return state
  }
}

export default appReducer
