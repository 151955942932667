import { useEffect, useState } from 'react'
import * as React from 'react'

type Props = {
  value: number
  onExpire?: () => void
}

const Timer: React.FC<Props> = ({ value = 0, onExpire }) => {
  const [remaining, setRemaining] = useState<number>(value)
  const [expired, setExpired] = useState<boolean>(value === 0)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()

  useEffect(() => {
    if (remaining > 0) {
      const intervalId = setInterval(timerOn, 1000)
      setIntervalId(intervalId)
    }
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (remaining <= 0 && !expired) {
      clearInterval(intervalId)
      setExpired(true)
      onExpire && onExpire()
    }
  }, [remaining])

  const timerOn = () => {
    setRemaining((remaining) => remaining - 1000)
  }

  const getMinutes = (): string => {
    const delta = Math.abs(Date.now() + remaining - Date.now()) / 1000
    const minutes = Math.floor(delta / 60) % 60
    return minutes > 9 ? minutes.toString() : `0${minutes}`
  }

  const getSeconds = (): string => {
    const delta = Math.abs(Date.now() + remaining - Date.now()) / 1000
    const seconds = delta % 60
    return seconds > 9 ? seconds.toString() : `0${seconds}`
  }

  return (
    <div className="k-timer">
      <span className="minutes">
        <span>{getMinutes()}</span>
      </span>
      <span className="separator">:</span>
      <span className="seconds">
        <span>{getSeconds()}</span>
      </span>
    </div>
  )
}

export default Timer
