import { Spin } from 'antd'
import * as React from 'react'

const Spinner: React.FC = () => (
  <div className="center-content full-width p-5" role="progressbar" data-testid="spinner-svg">
    <Spin spinning />
  </div>
)

export default Spinner
