import { Col, Modal, Row } from 'antd'

export const openModal = (
  title: string,
  content: string[],
  okText: string,
  type: 'error' | 'success' | 'warning' | 'info' = 'error',
  onOk?: () => void
) => {
  if (content) {
    Modal[type]({
      width: 500,
      maskClosable: true,
      okText,
      onOk: () => onOk && onOk(),
      title,
      content: (
        <Row gutter={[0, 16]}>
          {content.map((err) => (
            <Col key={err}>
              <p>{err}</p>
            </Col>
          ))}
        </Row>
      )
    })
  }
}
