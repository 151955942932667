import { Layout } from 'antd'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { Routes } from '../../../constants/Routes'
import UserIconCircle from '../General/icons/UserIconCircle'

interface Props {
  toggleProfileSidebar: (val: boolean) => void
  currentContact: IContact
  loggedIn?: boolean
}

const AppHeader: React.FC<Props> = ({ toggleProfileSidebar, currentContact, loggedIn = false }) => (
  <Layout.Header className="custom-header app-header">
    <div className="d-flex justify-content-between">
      <div className="header-item">
        <Link className="app-logo" to={Routes.Dashboard.collection().index}>
          <img src={'/logo.svg'} alt="klarx" data-testid="logo" />
        </Link>
      </div>
      <div className="header-item">
        {loggedIn && (
          <span onClick={() => toggleProfileSidebar(true)}>
            <UserIconCircle
              contact={currentContact}
              className="profile-button pointer"
              size="large"
            />
          </span>
        )}
      </div>
    </div>
  </Layout.Header>
)

export default AppHeader
