import {
  BankOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  CompassOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
  UserOutlined
} from '@ant-design/icons/lib'
import { Col, List, Row } from 'antd'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import IntlStringMessages from 'app/util/IntlStringMessages'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import KInfoRow from '../../UI-Elements/DataDisplay/KInfoRow'
import AddressSummary from '../Address/AddressSummary'

type Props = {
  showSupplyOwner?: boolean
  showDeliveryContact?: boolean
}

const RentalInfoCard: React.FC<MemberProps<IRental, Props>> = ({
  member: rental,
  props = { showSupplyOwner: true, showDeliveryContact: true }
}) => {
  const intl = useIntl()
  const { showSupplyOwner, showDeliveryContact } = props

  const displayDeliveryMethod = () => {
    if (rental.delivery_method === 'pick_up') {
      return intl.formatMessage({
        id: 'delivery_type_pick_up_text',
        defaultMessage: 'Pickup by the customer'
      })
    } else if (rental.delivery_method === 'delivery') {
      return intl.formatMessage({
        id: 'delivery_type_delivery_text',
        defaultMessage: 'Delivery to the customer'
      })
    } else {
      return 'Unknown'
    }
  }

  const data = useMemo(() => {
    return [
      showDeliveryContact && {
        value: `${rental.delivery_contact.name || ''} ${rental.delivery_contact.phone || ''}`,
        title: intl.formatMessage({ id: 'rental-show-delivery-contact' }),
        icon: <UserOutlined />
      },
      {
        value: rental.rental_code,
        title: intl.formatMessage({ id: 'rental.request.rental.code' }),
        icon: <InfoCircleOutlined />
      },
      showSupplyOwner &&
        rental.supply_owner && {
          value: `${rental.supply_owner.name || ''} ${rental.supply_owner.phone || ''}`,
          title: intl.formatMessage({ id: 'rental-show-supply-owner' }),
          icon: <BankOutlined />
        },
      {
        value: `${rental.start_date} - ${rental.return_date}`,
        title: intl.formatMessage({ id: 'rental.request.summary.period' }),
        icon: <CalendarOutlined />
      },
      {
        value: `${rental.duration} ${intl.formatMessage({ id: 'days' })}`,
        title: intl.formatMessage({ id: 'rental.request.summary.duration' }),
        icon: <ClockCircleOutlined />
      },
      {
        value: displayDeliveryMethod(),
        title: intl.formatMessage({ id: 'rental.request.summary.delivery' }),
        icon: <CompassOutlined />
      },
      {
        value: (
          <AddressSummary
            link={rental.google_maps_direction}
            deliveryAddress={rental.delivery_address}
          />
        ),
        title: intl.formatMessage({ id: 'rental.request.summary.meeting.point' }),
        icon: <EnvironmentOutlined />
      },
      rental.delivery_address.address2 && {
        value: rental.delivery_address.address2,
        title: intl.formatMessage({ id: 'rental.request.summary.meeting.additional.address' }),
        icon: <InfoCircleOutlined />
      }
    ].filter(Boolean)
  }, [rental])

  return (
    <KCard title={<IntlStringMessages id="rental.request.summary.title" />}>
      <Row>
        <Col span={24}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            split={false}
            renderItem={(item) => (
              <List.Item>
                <KInfoRow title={item.title} icon={item.icon} info={item.value} />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </KCard>
  )
}

export default RentalInfoCard
