import { Col, Row } from 'antd'
import UploadDocument, { UploadProps } from 'app/components/Core/Document/Upload'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import { DOCUMENT_TYPES_ENUM } from 'app/constants/Enums'
import {
  deleteRentalDocument,
  fetchRentalDocuments,
  getFormDataInfo,
  uploadRentalDocument
} from 'app/redux/actions/Document'
import * as React from 'react'

interface Props extends UploadProps {
  rentalRequest: IRentalRequest
  document_type?: IDocumentType
  loading?: boolean
  title?: React.ReactNode
  description?: React.ReactNode
}

const Upload: React.FC<Props> = ({
  rentalRequest,
  document_type = DOCUMENT_TYPES_ENUM.RENTAL_QUOTE,
  children,
  loading,
  title,
  description
}) => {
  const handleUpload = (file: File) => {
    const { rental_id, supplier_station_id } = rentalRequest
    const formData = getFormDataInfo({ file, rental_id, supplier_station_id, document_type })
    return uploadRentalDocument(formData)
  }

  return (
    <KCard title={title}>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <span className="mb-1">{description}</span>
        </Col>
        <Col span={24}>
          <UploadDocument
            loading={loading}
            fetchDocuments={() =>
              fetchRentalDocuments(
                rentalRequest?.rental_id,
                rentalRequest?.supplier_station_id,
                null,
                document_type
              )
            }
            deleteDocument={(file: IDocument) => deleteRentalDocument(file.id)}
            uploadDocument={handleUpload}>
            {children}
          </UploadDocument>
        </Col>
      </Row>
    </KCard>
  )
}

export default Upload
