import * as React from 'react'
import { useIntl } from 'react-intl'

type Props = {
  value?: string
}

const Unit: React.FC<Props> = ({ value }) => {
  const { formatMessage } = useIntl()

  return <If condition={value}>{formatMessage({ id: `period.${value}` })}</If>
}

export default Unit
