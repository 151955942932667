import { Result } from 'antd'
import { useIntl } from 'react-intl'

import LinkButton from '../../components/UI-Elements/Controls/buttons/LinkButton'
import { Routes } from '../../constants/Routes'

const ErrorNotFound = () => {
  const { formatMessage } = useIntl()

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: '90vh'
      }}>
      <div className="text-center">
        <Result
          status="404"
          title="404"
          subTitle={formatMessage({ id: '404.description' })}
          extra={
            <LinkButton
              type="default"
              url={Routes.Dashboard.collection().index}
              title={formatMessage({ id: '404.button.text' })}
            />
          }
        />
      </div>
    </div>
  )
}

export default ErrorNotFound
