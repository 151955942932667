import BrowserService from './BrowserService'

export const KLARX_CONNECT_URL_PREFIX = 'supply.'
class EnvironmentService {
  static Prod = 'production'
  static Staging = 'staging'
  static Dev = 'development'
  static Test = 'test'

  static isProduction = () => {
    return process.env.REACT_APP_ENV === EnvironmentService.Prod
  }

  static isStaging = () => {
    return process.env.REACT_APP_ENV === EnvironmentService.Staging
  }

  static isDevelopment = () => {
    return process.env.REACT_APP_ENV === EnvironmentService.Dev
  }

  static isTest = () => {
    return process.env.REACT_APP_ENV === EnvironmentService.Test
  }

  static isDevOrStaging = () => {
    return EnvironmentService.isDevelopment() || EnvironmentService.isStaging()
  }

  static getEnvValue = (attr: string) => {
    return process.env[`REACT_APP_${attr}`]
  }

  static getGermanHostUrlValue = (attr: string) => {
    return process.env[`REACT_APP_${attr}`] + '.de'
  }

  static getHostUrlValue = () => {
    switch (process.env.REACT_APP_ENV) {
      case EnvironmentService.Prod:
        return EnvironmentService.getEnvValue('HOST_URL') + '.' + BrowserService.getDomainName()
      case EnvironmentService.Dev:
        return EnvironmentService.getEnvValue('HOST_URL')
      default:
        return window.location.origin.replace(KLARX_CONNECT_URL_PREFIX, '')
    }
  }
}

export default EnvironmentService
