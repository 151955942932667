import { Button as AntButton } from 'antd'
import * as React from 'react'
import { ButtonProps as AntButtonProps, ButtonType } from 'antd/es/button/button'

type CustomButtonType =
  | 'default'
  | 'link'
  | 'ghost'
  | 'primary'
  | 'dashed'
  | 'secondary'
  | 'secondary-outlined'
export interface ButtonProps extends Omit<AntButtonProps, 'type'> {
  title?: string
  icon?: React.ReactNode
  htmlType?: 'button' | 'reset' | 'submit'
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  loading?: boolean
  style?: any
  type?: CustomButtonType
  className?: string
  disabled?: boolean
  children?: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({
  title,
  icon,
  onClick,
  loading,
  htmlType = 'button',
  type = 'default',
  size,
  className = '',
  children,
  disabled = false,
  ...others
}) => {
  const getButtonType = (): ButtonType => {
    const types: { [key: string]: ButtonType } = {
      secondary: 'default',
      'secondary-outlined': 'default',
      default: 'default',
      primary: 'primary',
      ghost: 'ghost',
      dashed: 'dashed',
      link: 'link'
    }
    return types[type]
  }

  const getClassName = () => {
    let extraClasses = ''
    if (type === 'secondary') {
      extraClasses = 'ant-btn-secondary'
    } else if (type === 'secondary-outlined') {
      extraClasses = 'ant-btn-secondary-border'
    }
    return `${className} ${extraClasses}`
  }

  return (
    <AntButton
      size={size}
      htmlType={htmlType}
      disabled={disabled}
      icon={icon}
      onClick={onClick}
      loading={loading}
      type={getButtonType()}
      className={getClassName()}
      {...others}>
      {title ? title : children}
    </AntButton>
  )
}

Button.defaultProps = {
  title: '',
  icon: '',
  onClick: () => console.warn('not implemented.'),
  loading: false,
  children: null
}

export default Button
