import { composeWithDevTools } from '@redux-devtools/extension'
import createRootReducer from 'app/redux/reducers/index'
import EnvironmentService from 'app/services/EnvironmentService'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux'
import thunk from 'redux-thunk'

const history = createBrowserHistory()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers: any = EnvironmentService.isDevelopment() ? composeWithDevTools : compose

export default function configureStore(initialState?: unknown) {
  return createStore(createRootReducer(), initialState, composeEnhancers(applyMiddleware(thunk)))
}

export { history }
