import { Col, Row, Skeleton } from 'antd'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'

const Loading = () => (
  <Row gutter={[16, 16]}>
    <Col xs={{ span: 22 }} md={{ span: 16 }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <KCard title={<Skeleton paragraph={{ rows: 0 }} />}>
            <Skeleton paragraph={{ rows: 1 }} />
          </KCard>
        </Col>
        <Col span={24}>
          <KCard title={<Skeleton paragraph={{ rows: 0 }} />}>
            <Skeleton paragraph={{ rows: 10 }} />
          </KCard>
        </Col>
      </Row>
    </Col>
    <Col xs={{ span: 22 }} md={{ span: 8 }} className="d-md-none">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <KCard title={<Skeleton paragraph={{ rows: 0 }} />}>
            <Skeleton paragraph={{ rows: 2 }} />
          </KCard>
        </Col>
        <Col span={24}>
          <KCard title={<Skeleton paragraph={{ rows: 0 }} />}>
            <Skeleton paragraph={{ rows: 3 }} />
          </KCard>
        </Col>
      </Row>
    </Col>
  </Row>
)

export default Loading
