import { Space } from 'antd'
import Tag from 'app/components/UI-Elements/DataDisplay/Tag'
import Message from 'app/components/UI-Elements/Feedback/messages/Message'
import * as React from 'react'
import { useIntl } from 'react-intl'

type Props = {
  type: 'negative' | 'positive'
}

const ChangeRequestStatus: React.FC<Props> = ({ type }) => {
  const { formatMessage } = useIntl()
  return (
    <Message type="default" className="p-3">
      <Space direction="horizontal" className="justify-content-between full-width">
        <span>
          {formatMessage(
            {
              id: `change-request-status-message-${type}`
            },
            {
              strong: (val: string) => <strong>{val}</strong>
            }
          )}
        </span>
        <If condition={type === 'positive'}>
          <Tag color="blue" text={formatMessage({ id: 'change-request-status-tag-positive' })} />
        </If>
      </Space>
    </Message>
  )
}

export default ChangeRequestStatus
