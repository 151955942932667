import { List } from 'antd'
import KList from 'app/components/UI-Elements/DataDisplay/KList'
import * as React from 'react'

import ListItem from './ListItem'

const DeliveryRequestItemList: React.FC<CollectionProps<IDeliveryRequestItem>> = ({
  collection
}) => {
  return (
    <KList
      hideIfEmpty
      dataSource={collection}
      bordered={false}
      renderItem={(item) => (
        <List.Item>
          <ListItem member={item} />
        </List.Item>
      )}
    />
  )
}

export default DeliveryRequestItemList
