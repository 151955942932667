import { Space, Tag } from 'antd'
import * as React from 'react'
import { useIntl } from 'react-intl'

import ProductItemName from '../Name'

const ProductSubItemName: React.FC<MemberProps<IProductRequestItem>> = ({ member }) => {
  const { formatMessage } = useIntl()

  const getTitle = () => (
    <div className="d-flex full-width justify-content-between">
      <Space direction="horizontal">
        <Choose>
          <When condition={member.included}>
            <p className="mb-0">{member.item.category_segment_name}</p>
            <div>
              <Tag color="green">{formatMessage({ id: 'rental.request.free.sub_item' })}</Tag>
            </div>
          </When>
          <Otherwise>
            <h4 className="text-black mb-0">{member.item.category_segment_name}</h4>
          </Otherwise>
        </Choose>
      </Space>
      <span className="text-gray">
        ({formatMessage({ id: 'rental.request.product.sub_item' })})
      </span>
    </div>
  )

  return (
    <Choose>
      <When condition={member.included}>{getTitle()}</When>
      <Otherwise>
        <ProductItemName
          member={member}
          props={{
            title: getTitle()
          }}
        />
      </Otherwise>
    </Choose>
  )
}

export default ProductSubItemName
