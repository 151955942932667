import { USER, CONTACT } from '../../constants/ActionTypes'

const initialState: UserReducerState = {
  langCode: 'de',
  loggedIn: false,
  user: null,
  contact: null,
  contactOrganizations: [],
  supplierStations: []
}

const user = (state: UserReducerState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case USER.LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: true,
        user: action.payload.user,
        contact: action.payload.contact,
        contactOrganizations: action.payload.contactOrganizations,
        supplierStations: action.payload.supplierStations,
        langCode: action.payload.contact.language
      }
    case CONTACT.UPDATE_LOCALE:
      return {
        ...state,
        langCode: action.payload.language
      }
    default:
      return state
  }
}

export default user
