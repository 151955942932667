import Tag from 'app/components/UI-Elements/DataDisplay/Tag'
import { RecordType } from 'app/constants/GlobalTypes'
import useTranslation from 'app/lib/hooks/useTranslation'
import * as React from 'react'

import ChangeRequestTag from './ChangeRequest'

const RequestItemTag: React.FC<
  MemberProps<Pick<IProductRequestItem, 'changed_since_last_response' | 'response'>>
> = ({ member }) => {
  const { tRecordType } = useTranslation({ recordType: RecordType.product_request_item })

  if (member?.changed_since_last_response) {
    return <ChangeRequestTag member={member} />
  }

  if (member?.response === 'pending') {
    return <Tag color="yellow" text={tRecordType({ id: 'pending' })} />
  }

  return null
}

export default RequestItemTag
