import RentalRequestItemNormalizer from './RentalRequestItemNormalizer'

export default class RentalRequestNormalizer {
  rentals: Array<IRental>
  rentalRequestItems: Array<IRentalRequestItem>

  normalizeRentalRequests(
    collectionResponse: CollectionSerializerResponse<IRentalRequest>
  ): IRentalRequest[] {
    const data = collectionResponse.data
    const included = collectionResponse.included
    this.rentals = included
      .filter((entry) => entry.type === 'rental')
      .map((rental: { attributes: IRental }) => rental.attributes)
    return data.map((row: { attributes: IRentalRequest; relationships: any }) =>
      this.matchRentalRequestsWithRental(row.attributes, row.relationships)
    )
  }

  normalizeRentalRequest(memberResponse: MemberSerializerResponse<IRentalRequest>): IRentalRequest {
    const { data, included } = memberResponse

    this.rentalRequestItems = included
      .filter((entry) => entry.type === 'rental_request_item')
      .map((co) => co.attributes)
    const rentalRequest: IRentalRequest = data.attributes
    rentalRequest.rental = included.find((entry) => entry.type === 'rental').attributes
    return this.matchRentalRequestWithRentalRequestItems(rentalRequest, data.relationships)
  }

  normalizeRentalRequestWithLineItem(
    memberResponse: MemberSerializerResponse<IRentalRequest>
  ): IRentalRequest {
    const normalizedRentalRequest = this.normalizeRentalRequest(memberResponse)

    normalizedRentalRequest.rental_request_items.forEach((item: any) => {
      new RentalRequestItemNormalizer().normalizeRentalRequestItem(item)
    })

    return normalizedRentalRequest
  }

  matchRentalRequestsWithRental(
    rentalRequestAttributes: IRentalRequest,
    relationships: any
  ): IRentalRequest {
    const rentalRequest = rentalRequestAttributes
    // eslint-disable-next-line
    rentalRequest.rental = this.rentals.find(
      (rental: IRental) => relationships['rental'].data.id == rental.id
    )
    return rentalRequest
  }

  matchRentalRequestWithRentalRequestItems(
    rentalRequestAttributes: IRentalRequest,
    relationships: any
  ): IRentalRequest {
    const rentalRequest = rentalRequestAttributes
    rentalRequest.rental_request_items = this.rentalRequestItems.filter(
      (rental_request_item: IRentalRequestItem) =>
        relationships['rental_request_items'].data
          .map((re: any) => re.id)
          .includes(String(rental_request_item.id))
    )
    return rentalRequest
  }

  matchRentalRequestItemsWithLineItems(
    rentalRequest: IRentalRequest,
    relationships: any
  ): IRentalRequest {
    rentalRequest.rental_request_items = this.rentalRequestItems.filter(
      (rental_request_item: IRentalRequestItem) =>
        relationships['rental_request_items'].data
          .map((re: any) => re.id)
          .includes(String(rental_request_item.id))
    )
    return rentalRequest
  }
}
