import * as React from 'react'
import { Row, Col } from 'antd'
import KIcon from '../General/icons/KIcon'

type InfoProps = {
  icon?: React.ReactNode
  title: React.ReactNode
  info: React.ReactNode
}

const KInfo: React.FC<InfoProps> = ({ icon, title, info }) => (
  <Row gutter={[16, 0]} align="middle">
    <If condition={Boolean(icon)}>
      <Col>
        <KIcon icon={icon} />
      </Col>
    </If>
    <Col>
      <p className="text-primary-light mb-1 text-sm d-block">{title}</p>
      <p className="mb-1">{info || '-'}</p>
    </Col>
  </Row>
)

export default KInfo
