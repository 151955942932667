import * as React from 'react'
import { Col, Modal, Row } from 'antd'
import { ModalProps } from 'antd/es/modal'
import Button from '../../UI-Elements/Controls/buttons/Button'
import { useIntl } from 'react-intl'

const DataSheetModal: React.FC<ModalProps & { url: string }> = ({
  visible,
  title,
  url,
  ...others
}) => {
  const { formatMessage } = useIntl()
  return (
    <Modal visible={visible} title={title} footer={null} {...others}>
      <Row>
        <Col span={24}>
          <embed src={url} style={{ width: '100%', height: 600 }} />
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            title={formatMessage({ id: 'download' })}
            onClick={() => window.open(url)}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default DataSheetModal
