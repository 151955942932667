import { AppDispatch } from '../../../index'
import { CONTACT, USER } from '../../constants/ActionTypes'
import { PromisedDispatch } from '../../constants/GlobalTypes'
import { Routes } from '../../constants/Routes'
import {
  LOGIN_WITH_PAYLOAD_URL,
  LOGIN_WITH_SESSION_URL,
  LOGIN_WITH_URL_TOKEN,
  LOGOUT_USER_URL,
  REQUEST_PASSWORD_EMAIL_URL,
  UPDATE_CONTACT_URL,
  USER_PASSWORD_URL
} from '../../constants/Url'
import Api from '../../lib/api'
import { parseError } from '../../lib/handler'
import CommonService from '../../services/CommonService'
import IntlService from '../../services/IntlService'
import LoginService from '../../services/LoginService'

export const authenticateUser = (): PromisedDispatch<void> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.get<MemberSerializerResponse<IUser>>(`${LOGIN_WITH_SESSION_URL}`)
        .then((response) => {
          dispatch({
            type: USER.LOGIN_REQUEST,
            payload: new LoginService(response.data).startSession()
          })
          resolve()
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

export const authenticateUserWithLoginToken = (login_token: string): PromisedDispatch<void> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.post<IUser>(`${LOGIN_WITH_URL_TOKEN}`, { login_token })
        .then((response: any) => {
          dispatch({
            type: USER.LOGIN_REQUEST,
            payload: new LoginService(response.data).startSession()
          })
          resolve()
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

export const loginUser = (email: string, password: string): PromisedDispatch<void> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.post<IUser>(`${LOGIN_WITH_PAYLOAD_URL}`, { email, password })
        .then((response) => {
          dispatch({
            type: USER.LOGIN_REQUEST,
            payload: new LoginService(response.data).startSession()
          })
          resolve()
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

export const logoutUser = (): Promise<void> => {
  return new Promise((_resolve, reject) => {
    Api.get(`${LOGOUT_USER_URL}`)
      .then((response) => {
        if (response.status > 204) {
          window.heap && window.heap.resetIdentity()
          reject(parseError(response))
        } else {
          CommonService.redirectToLink(Routes.Authentication.collection().login)
        }
      })
      .catch((response) => {
        reject(parseError(response))
      })
  })
}

export const resetPassword = (payload: {
  password: string
  password_confirmation: string
}): Promise<void> => {
  return new Promise((resolve, reject) => {
    Api.post<IUser>(`${USER_PASSWORD_URL}`, payload)
      .then(() => {
        resolve()
      })
      .catch((response) => {
        reject(parseError(response))
      })
  })
}

export const requestResetPasswordEmail = (payload: { email: string }): Promise<void> => {
  return new Promise((resolve, reject) => {
    Api.post<IUser>(`${REQUEST_PASSWORD_EMAIL_URL}`, payload)
      .then(() => {
        resolve()
      })
      .catch((response) => {
        reject(parseError(response))
      })
  })
}

export const updateLocale = (
  contactId: string | number,
  locale: string
): PromisedDispatch<void> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.put<IContact>(UPDATE_CONTACT_URL(contactId), {
        contact: { locale: IntlService.getLocaleWithHost(locale) }
      })
        .then((response) => {
          dispatch({
            type: CONTACT.UPDATE_LOCALE,
            payload: response.data.data.attributes
          })
          resolve()
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}
