import { Col, Row, Space } from 'antd'
import ProductRequestItemChange from 'app/components/Core/ChangeRequest/ProductRequestItem/Change'
import * as React from 'react'
import { useIntl } from 'react-intl'

const ProductRequestItemListItem: React.FC<MemberProps<IProductRequestItem>> = ({ member }) => {
  const { formatMessage } = useIntl()
  const { item, requested_changes } = member

  const attributes = Object.keys(requested_changes) as (keyof PurchasingRequestItemChanges)[]

  return (
    <Row>
      <Col span={24}>
        <h6 className="text-black">
          {formatMessage(
            { id: 'rental-show-change-request-change-title' },
            { product_name: item.name }
          )}
          <span className="text-normal text-gray pl-2">({item.category_segment_name})</span>
        </h6>
        {attributes.map((attribute, index) => (
          <Space key={index} direction="vertical" className="d-block">
            <p className="text-primary mb-0">
              {formatMessage({ id: `change_request.change_attribute.${attribute}` })}
            </p>
            <ProductRequestItemChange attribute={attribute} changes={requested_changes} />
          </Space>
        ))}
      </Col>
    </Row>
  )
}

export default ProductRequestItemListItem
