import { combineReducers } from 'redux'

import AppReducer from './AppReducer'
import ContactReducer from './ContactReducer'
import dashboard from './DashboardReducer'
import FleetReview from './FleetReviewReducer'
import RentalRequest from './RentalRequestReducer'
import User from './UserReducer'

const rootReducer = () =>
  combineReducers({
    user: User,
    appReducer: AppReducer,
    contact: ContactReducer,
    rentalRequest: RentalRequest,
    dashboard: dashboard,
    fleetReview: FleetReview
  })
export default rootReducer
