import convertVapidKey from 'convert-vapid-public-key'

import openNotification from '../components/Helpers/Notification'
import { SUBSCRIPTIONS_URL } from '../constants/Url'
import Api from '../lib/api'
import EnvironmentService from './EnvironmentService'

class PushNotificationService {
  static init = (userId: number) => {
    if (window.Cypress) return

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((serviceWorkerRegistration) => {
          serviceWorkerRegistration.pushManager
            .getSubscription()
            .then((subscription) => {
              PushNotificationService.updateSubscription(userId, subscription)
            })
            .catch((error: any) => {
              console.log({ error })
            })
        })
        .catch((error: any) => {
          console.log({ error })
        })
    }

    if (!('Notification' in window)) {
      console.error('This browser does not support desktop notification')
    } else if (Notification.permission === 'granted') {
      console.info('Permission to receive notifications has been granted')
    } else if (Notification.permission !== 'denied') {
      openNotification(
        'Benachrichtigungen',
        'Nie wieder Verfügbarkeitsanfragen von klarx verpassen. Klicke einfach auf "Zulassen" und erhalte Benachrichtigungen für neue Mietanfragen von uns.',
        'Zulassen',
        () => {
          if ('serviceWorker' in navigator) {
            PushNotificationService.subscribe(userId)
          }
        }
      )
    }
  }

  static subscribe = (userId: number) => {
    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration) => {
        serviceWorkerRegistration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: PushNotificationService.getApplicationServerKey()
          })
          .then((subscription) => PushNotificationService.updateSubscription(userId, subscription))
          .catch((error: any) => console.log({ error }))
      })
      .catch((error: any) => {
        console.log({ error })
      })
  }

  static getApplicationServerKey = (): Uint8Array => {
    try {
      return convertVapidKey(EnvironmentService.getEnvValue('VAPID_PUBLIC_KEY'))
    } catch (e) {
      console.log('Cannot convert key')
      console.log(e.message)
    }
  }

  static updateSubscription = (userId: number, subscription: PushSubscription) => {
    if (subscription) {
      const {
        endpoint,
        keys: { auth, p256dh }
      } = subscription.toJSON()
      Api.post(SUBSCRIPTIONS_URL, { id: userId, endpoint, auth, p256dh })
    }
  }
}

export default PushNotificationService
