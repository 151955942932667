import { AppDispatch } from 'app/../index'
import { RENTAL_REQUEST } from 'app/constants/ActionTypes'
import { PromisedDispatch, RequestPayload } from 'app/constants/GlobalTypes'
import {
  FETCH_RENTAL_REQUEST_URL,
  FETCH_RENTAL_REQUESTS_URL,
  FETCH_REQUEST_RENTAL_URL,
  RENTAL_REQUEST_ITEM_DELIVERY_URL,
  RESPOND_RENTAL_REQUEST,
  UPDATE_RENTAL_REQUEST_URL
} from 'app/constants/Url'
import Api from 'app/lib/api'
import { parseError } from 'app/lib/handler'
import RentalRequestNormalizer from 'app/redux/reducers/normalizers/RentalRequest'
import RentalRequestItemDeliveryNormalizer from 'app/redux/reducers/normalizers/RentalRequestItemDeliveryNormalizer'

export const fetchRentalRequest = (rentalRequestId: number): PromisedDispatch<IRentalRequest> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.get<MemberSerializerResponse<IRentalRequest>>(FETCH_RENTAL_REQUEST_URL(rentalRequestId))
        .then((response) => {
          dispatch({
            type: RENTAL_REQUEST.FETCH_RENTAL_REQUEST,
            payload: RentalRequestNormalizer.normalizeRentalRequest(response.data)
          })
          resolve(RentalRequestNormalizer.normalizeRentalRequest(response.data))
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

export const respondRentalRequest = (
  rentalRequestId: number,
  payload: { [key: string]: string }
): PromisedDispatch<IRentalRequest> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.post<IRentalRequest>(RESPOND_RENTAL_REQUEST(rentalRequestId), payload)
        .then((response) => {
          const payload = RentalRequestNormalizer.normalizeRentalRequestWithItems(response.data)
          dispatch({
            type: RENTAL_REQUEST.RESPOND_TO_RENTAL_REQUEST,
            payload
          })
          resolve(payload)
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

export const updateRentalRequest = (
  rentalRequestId: number,
  payload: RequestPayload<IRentalRequest>
): PromisedDispatch<IRentalRequest> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.put<IRentalRequest>(UPDATE_RENTAL_REQUEST_URL(rentalRequestId), {
        rental_request: payload
      })
        .then((response) => {
          dispatch({
            type: RENTAL_REQUEST.UPDATE_RENTAL_REQUEST,
            payload: RentalRequestNormalizer.normalizeRentalRequestWithItems(response.data)
          })
          resolve(RentalRequestNormalizer.normalizeRentalRequestWithItems(response.data))
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

export const fetchRentalRequests = (
  actionType: string,
  rentalRequestsType: string,
  q = '',
  supplier_station_id: '',
  page = 1,
  per_page = 10,
  sortKey = ''
): PromisedDispatch<void> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.get<CollectionSerializerResponse<IRentalRequest>>(
        FETCH_RENTAL_REQUESTS_URL(
          rentalRequestsType,
          q,
          supplier_station_id,
          page,
          per_page,
          sortKey
        )
      )
        .then((response) => {
          dispatch({ type: actionType, payload: response.data })
          resolve()
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

export const toggleRentalRequestForPreview = (
  open: boolean,
  rentalRequest: IRentalRequest = null
): ActionTypes => {
  return {
    type: RENTAL_REQUEST.TOGGLE_RENTAL_REQUEST_FOR_PREVIEW,
    payload: { open: open, rentalRequest: rentalRequest }
  }
}

export const setSelectedTab = (selectedTab: string): ActionTypes => ({
  type: RENTAL_REQUEST.SET_SELECTED_TAB,
  payload: { selectedTab }
})

export const fetchRentalRequestItemDeliveries = (
  rentalRequestId: number
): PromisedDispatch<void> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.get<CollectionSerializerResponse<IRentalRequestItemDelivery>>(
        RENTAL_REQUEST_ITEM_DELIVERY_URL(rentalRequestId)
      )
        .then((response) => {
          dispatch({
            type: RENTAL_REQUEST.FETCH_DELIVERY_ITEMS,
            payload: RentalRequestItemDeliveryNormalizer.normalizeRentalRequestItemDelivery(
              response.data
            )
          })
          resolve()
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

export const fetchRequestRental = (rentalRequestId: number): Promise<IRentalRequest> => {
  return new Promise((resolve, reject) => {
    Api.get<MemberSerializerResponse<IRentalRequest>>(FETCH_REQUEST_RENTAL_URL(rentalRequestId))
      .then((response) => {
        resolve(RentalRequestNormalizer.normalizeRentalRequestWithItems(response.data))
      })
      .catch((error) => {
        reject(parseError(error))
      })
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const liftForm = (name: string, form: any): ActionTypes => {
  return {
    type: RENTAL_REQUEST.LIFT_FORMS,
    payload: { name, form }
  }
}

export const destroyRentalRequestState = (): ActionTypes => {
  return {
    type: RENTAL_REQUEST.DESTROY_STATE
  }
}
