import { Alert, Col, Row } from 'antd'
import GeneralTermsCard from 'app/components/Core/GeneralTermsCard'
import RentalInfoCard from 'app/components/Core/Rental/InfoCard'
import Request from 'app/components/Core/RentalRequest/Premium/Request'
import Upload from 'app/components/Core/RentalRequest/Upload'
import IntlHtmlMessages from 'app/util/IntlHtmlMessages'
import React, { useEffect } from 'react'
import { FormattedHTMLMessage, useIntl } from 'react-intl'

import { useRentalRequest } from '../Hooks/useRentalRequest'
import Loading from '../Pages/Loading'

const Layout: React.FC = ({ children }) => {
  const { rentalRequest, loading, fetch, isLegacy, destroyState } = useRentalRequest()
  const { formatMessage, formatHTMLMessage } = useIntl()

  useEffect(() => {
    fetch()
    return () => destroyState()
  }, [])

  const getUploadCard = (): React.ReactNode => {
    if (rentalRequest)
      return (
        <Upload
          title={formatMessage({ id: 'rental.request.upload.title' })}
          description={formatHTMLMessage({ id: 'rental.request.upload.description' })}
          rentalRequest={rentalRequest}>
          <IntlHtmlMessages id="dragger.description" />
        </Upload>
      )
  }

  const getSummaryCard = (): React.ReactNode => {
    if (rentalRequest?.rental)
      return <RentalInfoCard props={{ showDeliveryContact: false }} member={rentalRequest.rental} />
  }

  const getPremiumRequestCard = (): React.ReactNode => {
    if (rentalRequest) return <Request rentalRequest={rentalRequest} />
  }

  return (
    <Row>
      {loading ? (
        <Col span={24}>
          <Loading />
        </Col>
      ) : (
        <Col span={24}>
          <Row align="top" gutter={[24, 24]}>
            <Col hidden={!rentalRequest?.is_premium} span={24}>
              {getPremiumRequestCard()}
            </Col>
            <Col sm={24} md={16}>
              <Row gutter={[0, 24]}>
                <Col hidden={!rentalRequest?.form_disabled} span={24}>
                  <Alert
                    message={<FormattedHTMLMessage id="rental.request.disabled.description" />}
                    type="error"
                  />
                </Col>
                <Col span={24}>
                  <fieldset disabled={rentalRequest?.form_disabled}>
                    {rentalRequest && children}
                  </fieldset>
                </Col>
              </Row>
            </Col>
            <Col sm={24} md={8}>
              <Row gutter={[24, 24]}>
                <Col span={24}>{getSummaryCard()}</Col>
                <Col span={24} hidden={!isLegacy()}>
                  {getUploadCard()}
                </Col>
                <Col span={24}>
                  <GeneralTermsCard />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  )
}

export default Layout
