import { dayjs } from '@2wunder/klarx-tool'
import { Col } from 'antd'
import PriceInput from 'app/components/Helpers/PriceInput'
import FormItem from 'app/components/UI-Elements/Forms/_FormItem'
import * as React from 'react'
import { useIntl } from 'react-intl'

type Props = {
  index: number
}

const DeliveryRequestItemFormItem: React.FC<MemberProps<IDeliveryRequestItem, Props>> = ({
  member,
  props: { index }
}) => {
  const { formatMessage } = useIntl()
  const { item } = member

  const transportDate = item.delivery_type === 'delivery' ? item.delivery_date : item.pick_up_date
  const cargos = member.cargos.map((cargo) => cargo.name).join(', ')

  return (
    <Col span={13}>
      <h5 className="text-black">
        {formatMessage(
          { id: 'rental-show-change-request-change-delivery-item-title' },
          { name: item.name, date: dayjs(transportDate).toLocaleDate() }
        )}
      </h5>
      <p>{formatMessage({ id: 'rental.request.delivery.cargo-names' }, { cargos })}</p>
      <FormItem
        hasFeedback={true}
        required
        getValueFromEvent={(value) => value}
        label={formatMessage({ id: 'rental.request.delivery.price' })}
        valuePropName="defaultValue"
        name={[index, 'net_price']}>
        <PriceInput
          placeholder={formatMessage({ id: 'rental.request.product.price' })}
          className="ant-input ant-input-lg"
        />
      </FormItem>
    </Col>
  )
}

export default DeliveryRequestItemFormItem
