import { Col, List, Row, Typography } from 'antd'
import IntlStringMessages from 'app/util/IntlStringMessages'
import * as React from 'react'

interface Props {
  cargos: ICargo[]
}

const Cargos: React.FC<Props> = ({ cargos }) => {
  return (
    <List
      bordered={true}
      header={
        <Row>
          <Col span={18}>
            <b>
              <IntlStringMessages id="rental-show-machine" />
            </b>
          </Col>
          <Col span={6} className="text-right">
            <b>
              <IntlStringMessages id="rental-show-weight" />
            </b>
          </Col>
        </Row>
      }
      dataSource={cargos}
      renderItem={(cargo_item: ICargo) => (
        <List.Item className="d-block" key={cargo_item.id}>
          <Row>
            <Col span={18}>{cargo_item.name}</Col>
            <Col span={6} className="text-right">
              {cargo_item.transport_weight ? (
                `${cargo_item.transport_weight} kg`
              ) : (
                <Typography.Text disabled>N/D</Typography.Text>
              )}
            </Col>
          </Row>
        </List.Item>
      )}
    />
  )
}

export default Cargos
