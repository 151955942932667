import SimpleSelect from 'app/components/UI-Elements/Controls/inputs/Selects/_SimpleSelect'
import FormItem from 'app/components/UI-Elements/Forms/_FormItem'
import { UNIT_ENUM } from 'app/constants/Enums'
import { PeriodEnumSelectOptionType } from 'app/constants/GlobalTypes'
import * as React from 'react'
import { useIntl } from 'react-intl'

interface Props extends Partial<React.ComponentProps<typeof FormItem>> {
  required?: boolean
  lineItemType?: string
}
const Unit: React.FC<Props> = ({ required, lineItemType, name = 'unit_id', ...others }) => {
  const { formatMessage } = useIntl()

  const getOptions = () => {
    if (lineItemType === 'service') {
      return UNIT_ENUM.service.map((option) => {
        return { ...option, label: formatMessage({ id: `period.${option.label}` }) }
      })
    } else if (lineItemType === 'consumables') {
      return UNIT_ENUM.consumables.map((option) => {
        return { ...option, label: formatMessage({ id: `period.${option.label}` }) }
      })
    } else {
      return UNIT_ENUM.other.map((option) => {
        return { ...option, label: formatMessage({ id: `period.${option.label}` }) }
      })
    }
  }

  return (
    <FormItem
      hasFeedback={true}
      required={required}
      getValueFromEvent={(unit: PeriodEnumSelectOptionType) => unit.id}
      label={formatMessage({ id: 'rental.request.product.per' })}
      name={name}
      {...others}>
      <SimpleSelect<PeriodEnumSelectOptionType>
        selectValueBy="id"
        placeholder={formatMessage({ id: 'rental.request.product.per' })}
        optionLabelProp="label"
        entries={getOptions()}
      />
    </FormItem>
  )
}

export default Unit
