import BrowserService from '../services/BrowserService'
import EnvironmentService from '../services/EnvironmentService'

const domain = EnvironmentService.isDevelopment ? '' : '.' + BrowserService.getDomainName()

export const baseCustomerDashboardRoute = (customerId: string | number): string =>
  `${EnvironmentService.getEnvValue('CUSTOMER_DASHBOARD_URL')}${domain}/kunden/${customerId}`

export const Routes = {
  Default: '/',
  Dashboard: {
    Base: '/dashboard',
    Key: 'dashboard',
    collection: () => ({
      index: Routes.Dashboard.Base
    })
  },
  Authentication: {
    Base: '',
    Key: 'authentication',
    collection: () => ({
      register: `${Routes.Authentication.Base}/registrieren`,
      login: `${Routes.Authentication.Base}/anmelden`,
      account: `${Routes.Authentication.Base}/konto`,
      reset_password: `${Routes.Authentication.Base}/passwort_zuruecksetzen`
    })
  },
  FAQ: {
    Base: '/faq',
    Key: 'faq',
    collection: () => ({
      index: Routes.FAQ.Base
    })
  },
  RentalPark: {
    Base: '/mietpark',
    Key: 'rental_park',
    collection: () => ({
      liste: `${Routes.RentalPark.Base}/liste`,
      timeline: `${Routes.RentalPark.Base}/timeline`
    })
  },
  User: {
    Base: '/nutzer',
    Key: 'user',
    collection: () => ({
      index: Routes.User.Base
    })
  },
  Role: {
    Base: '/rollen',
    Key: 'role',
    collection: () => ({
      index: Routes.Role.Base
    })
  },
  Fleet: {
    Base: '/flottenuberprufung',
    Key: 'fleet',
    collection: () => ({
      index: Routes.Fleet.Base,
      all_categories: (withBase = true) => {
        const base = withBase ? Routes.Fleet.Base : ''
        return {
          Base: `${base}/alle_kategorien`,
          Key: 'all_categories',
          collection: (withBase = true) => {
            const base = withBase ? Routes.Fleet.collection().all_categories().Base : ''
            return {
              index: base,
              start: `${base}/start`,
              station: `${base}/mietstationen`,
              categories: `${base}/kategorien`,
              category_segments: `${base}/kategoriesegmente`,
              finish: `${base}/finish`
            }
          }
        }
      },
      single_category: (withBase = true) => {
        const base = withBase ? Routes.Fleet.Base : ''
        return {
          Base: `${base}/einzelne_kategorie`,
          Key: 'single_category',
          collection: (withBase = true) => {
            const base = withBase ? Routes.Fleet.collection().single_category().Base : ''
            return {
              index: base,
              start: `${base}/start`,
              station: `${base}/mietstationen`,
              categories: `${base}/kategorien`,
              category: (category_id = ':category_id') =>
                `${base}/kategorien/${category_id}/kategoriesegment`,
              finish: `${base}/finish`
            }
          }
        }
      }
    })
  },
  OrderRequest: {
    Base: '/mietauftraege',
    Key: 'order_request',
    member: function (id = ':order_request_id', legacy = false, withBase = true) {
      const base = `${withBase ? Routes.OrderRequest.Base : ''}${
        legacy ? '/rental_request' : ''
      }/${id}`
      return {
        show: `${base}/auftrag`,
        availability: `${base}/anfrage`,
        availability_confirmation: `${base}/anfrage/bestaetigung`,
        finalize: `${base}/abschliessen`,
        overview: `${base}/uebersicht`
      }
    }
  },
  PurchasingRequest: {
    Base: '/mietauftraege',
    Key: 'purchasing_request',
    collection: () => {
      return {
        index: Routes.PurchasingRequest.Base
      }
    },
    member: function (id = ':purchasing_request_id', withBase = true) {
      const base = `${withBase ? Routes.PurchasingRequest.Base : ''}/${id}`
      return {
        response: `${base}/antwort`
      }
    }
  }
}
