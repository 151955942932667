import { AppDispatch } from '../../../index'
import { RENTAL_REQUEST } from '../../constants/ActionTypes'
import { PromisedDispatch } from '../../constants/GlobalTypes'
import {
  CREATE_PRODUCT_URL,
  FETCH_LAST_PRODUCT_SUGGESTIONS_URL,
  SEARCH_PRODUCTS_URL
} from '../../constants/Url'
import Api from '../../lib/api'
import { parseError } from '../../lib/handler'
import Base from '../reducers/normalizers/BaseNormalizer'

export const fetchProducts = (
  q: string,
  categoryId: number,
  supplier_station_id: number = null
): Promise<IProduct[]> => {
  return new Promise((resolve, reject) => {
    Api.get<CollectionSerializerResponse<IProduct>>(
      SEARCH_PRODUCTS_URL(categoryId, supplier_station_id, q)
    )
      .then((response) => {
        resolve(Base.mapAttributesOfCollection(response.data.data))
      })
      .catch((error) => {
        reject(parseError(error))
      })
  })
}

export const getLastProductSuggestions = (
  supplierStationId: number,
  categorySegmentId: number,
  rentalRequestItemId: number,
  per_page = 5,
  page = 1
): PromisedDispatch<void> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.get<CollectionSerializerResponse<IProduct>>(
        FETCH_LAST_PRODUCT_SUGGESTIONS_URL(supplierStationId, categorySegmentId, per_page, page)
      )
        .then((response) => {
          dispatch({
            type: RENTAL_REQUEST.FETCH_LAST_PRODUCTS,
            payload: {
              rental_request_item_id: rentalRequestItemId,
              lastProducts: Base.mapAttributesOfCollection<IProduct>(response.data.data)
            }
          })
          resolve()
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

export const createProduct = (supplierStationId: number, payload: IProduct): Promise<IProduct> => {
  return new Promise((resolve, reject) => {
    Api.post<IProduct>(CREATE_PRODUCT_URL(supplierStationId), { product: payload })
      .then((response) => {
        resolve(Base.mapAttributesOfMember(response.data.data))
      })
      .catch((error) => {
        reject(parseError(error))
      })
  })
}
