/* eslint-disable @typescript-eslint/no-explicit-any */
import { GenericObject } from 'app/constants/GlobalTypes'

export const flattenPayload = (obj: GenericObject<unknown>): unknown => {
  const result: GenericObject<unknown> = {}

  const flat = (o: GenericObject<any>, key?: string) => {
    Object.keys(o).forEach((k) => {
      if (typeof o[k] === 'object') {
        flat(o[k], key ? `${key}.${k}` : k)
      } else {
        result[`${key ? `${key}.${k}` : k}`] = o[k]
      }
    })
  }

  flat(obj)

  return result
}
