import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import { BUGSNAG } from 'app/constants/Bugsnag'
import { allLocales } from 'app/constants/Enums'
import areIntlLocalesSupported from 'intl-locales-supported'
import * as React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from './app/App'
import initHeapAnalytics from './app/config/HeapAnalytics'
import NotifierProvider from './app/lib/contexts/Notifier'
import configureStore from './app/redux/store'
import PushNotificationService from './app/services/PushNotificationService'
import ScriptService from './app/services/ScriptService'

const rootEl = document.getElementById('app-site') || document.createElement('div')

export const store = configureStore()
export type AppDispatch = typeof store.dispatch

const unsubscribe = store.subscribe(subscription)

initHeapAnalytics()

if (global.Intl) {
  if (!areIntlLocalesSupported(allLocales.map((i) => i.language))) {
    const IntlPolyfill = require('intl')
    Intl.NumberFormat = IntlPolyfill.NumberFormat
    Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat
    import('@formatjs/intl-relativetimeformat/polyfill')
    import('@formatjs/intl-relativetimeformat/locale-data/en')
    import('@formatjs/intl-relativetimeformat/locale-data/de')
  }
} else {
  global.Intl = require('intl')
  window.Intl = require('intl')
}

function subscription() {
  const { user, contact, contactOrganizations } = store.getState().user
  if (user) {
    const { hubspot_sync, email, id } = user
    PushNotificationService.init(id)
    ScriptService.initServiceWorker()
    ScriptService.initMouseflow(email)
    ScriptService.initGoogleTagManager()
    ScriptService.embedHubspotScript(email, hubspot_sync)
    BUGSNAG.setUser(id, email)

    window.heap &&
      window.heap.addUserProperties({
        ...contact,
        ...user,
        customer_organization_name: contactOrganizations
          .map((org: IContactOrganization) => org.name)
          .join(', '),
        role: contactOrganizations.map((org: IContactOrganization) => org.role).join(', ')
      })

    unsubscribe()
  }
}

export const ErrorBoundary = BUGSNAG.getPlugin('react').createErrorBoundary(React)

render(
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter>
        <NotifierProvider>
          <App />
        </NotifierProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>,
  rootEl
)
