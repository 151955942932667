import { Form } from 'antd'
import { FormItemProps } from 'antd/es/form'
import * as React from 'react'

const FormItem: React.FC<FormItemProps> = ({
  label,
  className,
  rules = [],
  required,
  ...others
}) => {
  return (
    <Form.Item
      rules={[...[{ required: required }], ...rules]}
      className={label ? `${className} mb-0 mt-1` : className}
      label={label}
      {...others}>
      {others.children}
    </Form.Item>
  )
}

export default FormItem
