import { RightOutlined } from '@ant-design/icons'
import { Col, Form, Row, Space } from 'antd'
import KIcon from 'app/components/UI-Elements/General/icons/KIcon'
import * as React from 'react'

import Specifications from '../PurchasingRequestItem/Specifications'
import ItemName from './Name'
import ProductSubItemName from './SubItem/Name'

const ProductRequestItemListItem: React.FC<MemberProps<IProductRequestItem>> = ({ member }) => {
  return (
    <Row wrap={false}>
      <Col flex="none">
        <KIcon icon={<RightOutlined />} className="text-gray mt-1 mr-2" />
      </Col>
      <Col flex="auto">
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <ItemName member={member} />
          </Col>
          <Col span={24}>
            <Space direction="vertical" size={0}>
              <Form.Item label="Anmerkung" className="mb-0" />
              <p>{member.annotation || '-'}</p>
            </Space>
          </Col>
          <If condition={member.item.specifications.length}>
            <Col span={24}>
              <Specifications member={member.item} />
            </Col>
          </If>
          {member.sub_items.map((item) => (
            <Col span={24} key={item.id}>
              <ProductSubItemName member={item} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default ProductRequestItemListItem
