import { FormattedHTMLMessage } from 'react-intl'
import * as React from 'react'

interface IntlParams {
  id: string
  defaultMessage?: string
  tagName?: React.ElementType
  values?: { [key: string]: string }
}

const IntlHtmlMessages: React.FC<IntlParams> = ({ id, defaultMessage, values, tagName }) => {
  return (
    <FormattedHTMLMessage
      tagName={tagName}
      id={id}
      defaultMessage={defaultMessage || id}
      values={values}
    />
  )
}

export default IntlHtmlMessages
