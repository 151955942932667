import { PaginatedResponse } from 'app/constants/GlobalTypes'
import Api from 'app/lib/api'
import { parseError } from 'app/lib/handler'

export function BaseActions<T extends { id: number }>({
  normalizer,
  resource,
  extraParams = {
    create: { includes: [] },
    update: { includes: [] }
  }
}: {
  normalizer: Partial<INormalizer<T>>
  resource: Partial<IResource>
  extraParams?: {
    create?: CollectionArgs
    update?: CollectionArgs
  }
}) {
  return {
    index: (params: CollectionArgs): Promise<PaginatedResponse<T>> => {
      return new Promise((resolve, reject) => {
        Api.get<ICollectionResponse<T>>(resource.collection(params).index)
          .then((response) => {
            const data = normalizer.index(response.data)
            const pagination = response.data.meta
            resolve({ data, pagination })
          })
          .catch((error) => {
            reject(parseError(error))
          })
      })
    },
    show: (params: MemberArgs): Promise<T> => {
      return new Promise((resolve, reject) => {
        Api.get<IMemberResponse<T>>(resource.member(params).show)
          .then((response) => {
            const data = normalizer.show(response.data)
            resolve(data)
          })
          .catch((error) => {
            reject(parseError(error))
          })
      })
    },
    update: (payload: T, params: CollectionArgs): Promise<T> => {
      return new Promise((resolve, reject) => {
        Api.put<T>(
          resource.member({ id: payload.id, params: params || extraParams.update }).update,
          payload
        )
          .then((response) => {
            const data = normalizer.update(response.data)
            resolve(data)
          })
          .catch((error) => {
            reject(parseError(error))
          })
      })
    },
    create: (payload: T, params: CollectionArgs): Promise<T> => {
      return new Promise((resolve, reject) => {
        Api.post<T>(resource.member({ params: params || extraParams.create }).create, payload)
          .then((response) => {
            const data = normalizer.create(response.data)
            resolve(data)
          })
          .catch((error) => {
            reject(parseError(error))
          })
      })
    },
    destroy: (params: MemberArgs): Promise<T> => {
      return new Promise((resolve, reject) => {
        Api.delete<T>(resource.member(params).destroy)
          .then((response) => {
            const data = normalizer.destroy(response.data)
            resolve(data)
          })
          .catch((error) => {
            reject(parseError(error))
          })
      })
    }
  }
}
