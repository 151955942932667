import { INCOMING_INVOICES_URL, FETCH_INCOMING_INVOICES_URL } from '../../constants/Url'
import Api from '../../lib/api'
import { parseError } from '../../lib/handler'
import BaseNormalizer from '../reducers/normalizers/BaseNormalizer'

const createIncomingInvoiceFormData = ({
  file,
  rental_id,
  supplier_station_id,
  has_damages = false
}: IDocumentUpload) => {
  let formData = new FormData()

  formData.append('incoming_invoice[file]', file)
  formData.append('incoming_invoice[rental_id]', rental_id.toString())
  formData.append('incoming_invoice[supplier_station_id]', supplier_station_id.toString())
  formData.append('incoming_invoice[has_damages]', has_damages.toString())
  return formData
}

export const uploadIncomingInvoice = (payload: IDocumentUpload): Promise<IIncomingInvoice> => {
  return new Promise((resolve, reject) => {
    Api.postWithMultipart<IIncomingInvoice>(
      INCOMING_INVOICES_URL,
      createIncomingInvoiceFormData(payload)
    )
      .then((response) => {
        resolve(BaseNormalizer.mapAttributesOfMember<IIncomingInvoice>(response.data.data))
      })
      .catch((error) => {
        reject(parseError(error))
      })
  })
}

export const fetchIncomingInvoices = (
  rentalId: number,
  has_damages: boolean = false
): Promise<IIncomingInvoice[]> => {
  return new Promise((resolve, reject) => {
    Api.get<CollectionSerializerResponse<IIncomingInvoice>>(
      FETCH_INCOMING_INVOICES_URL(rentalId, has_damages)
    )
      .then((response) => {
        resolve(BaseNormalizer.mapAttributesOfCollection<IIncomingInvoice>(response.data.data))
      })
      .catch((error) => {
        reject(parseError(error))
      })
  })
}
