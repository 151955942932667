import { List } from 'antd'
import { ListProps } from 'antd/lib/list'

interface Props<T> extends ListProps<T> {
  hideIfEmpty?: boolean
}

function KList<T extends { id: number }>({
  size = 'default',
  dataSource = [],
  rowKey = (item: T) => item?.id,
  hideIfEmpty,
  loading,
  ...others
}: Props<T>) {
  const empty = (): boolean => dataSource.length === 0 && !loading

  return (
    <List
      className={hideIfEmpty && empty() && 'd-none'}
      rowKey={rowKey}
      size={size}
      dataSource={dataSource}
      loading={loading}
      {...others}
    />
  )
}

export default KList
