import StatusTag from 'app/components/Core/Rental/Status/Tag'
import * as React from 'react'

const statuses: RentalStatusType[] = ['missing_invoice', 'invoice_processing', 'canceled']

const StatusMessage: React.FC<MemberProps<Pick<IRental, 'status'>>> = ({ member: rental }) => {
  return (
    <If condition={statuses.includes(rental?.status)}>
      <StatusTag member={rental} />
    </If>
  )
}

export default StatusMessage
