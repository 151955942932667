import { Drawer as AntDrawer } from 'antd'
import { ColorsPalette } from 'app/constants/GlobalStyles'
import * as React from 'react'

interface Props {
  open: boolean
  header?: React.ReactNode
  children: React.ReactNode
  onClose: (
    e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => void
  padding?: number
  backgroundColor?: string
  className?: string
  showCloseButton?: boolean
  destroyOnClose?: boolean
}

const Drawer: React.FC<Props> = ({
  showCloseButton = true,
  className,
  open,
  children,
  header,
  onClose,
  padding = 24,
  backgroundColor = ColorsPalette.white,
  ...others
}) => {
  return (
    <AntDrawer
      closable={showCloseButton}
      drawerStyle={{ backgroundColor: backgroundColor }}
      title={header && <h3 className="mb-0">{header}</h3>}
      placement={'right'}
      visible={open}
      bodyStyle={{ padding: padding }}
      className={`extra-info-drawer-container ${className}`}
      style={{ padding: 0 }}
      onClose={onClose}
      {...others}>
      {children}
    </AntDrawer>
  )
}

export default Drawer
