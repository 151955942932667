import Tag from 'app/components/UI-Elements/DataDisplay/Tag'
import { RecordType } from 'app/constants/GlobalTypes'
import useTranslation from 'app/lib/hooks/useTranslation'

const ChangeRequestTag = ({
  member: item
}: MemberProps<Partial<Pick<IProductRequestItem, 'item' | 'changed_since_last_response'>>>) => {
  const { tRecordType } = useTranslation({ recordType: RecordType.product_request_item })

  if (item.item?.deleted) return <Tag color="red" text={tRecordType({ id: 'deleted' })} />

  if (item.changed_since_last_response)
    <Tag color="blue" text={tRecordType({ id: 'change_request' })} />

  return null
}

export default ChangeRequestTag
