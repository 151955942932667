/* eslint-disable max-lines */
import { RecordType } from 'app/constants/GlobalTypes'
import { ToolBox } from 'app/lib/hooks/toolBox'

const AUTHENTICATION = 'authentication'
const LOGIN_SESSION = 'login_session'
const LOGIN = 'login'
const TOKEN_LOGIN = 'token_login'
const LOGOUT = 'logout'
const CONTACT_ORGANIZATIONS = 'contact_organizations'
const SUPPLIER_STATIONS = 'supplier_stations'
const SUPPLIER_STATION = 'supplier_station'
const DELIVERY_ITEMS = 'delivery_items'
const INCLUDES = 'includes[]='
const CONTACTS = 'contacts'
const CONTACT = 'contact'
const RENTAL = 'rental'
const RENTALS = 'rentals'
const DOCUMENTS = 'documents'
const RENTAL_REQUEST_ITEMS = 'rental_request_items'
const RENTAL_REQUESTS = 'rental_requests'
const PRODUCTS = 'products'
const SEARCH = 'search'
const RESPOND = 'respond'
const SUGGESTIONS = 'suggestions'
const LINE_ITEMS = 'line_items'
const CATEGORY_SEGMENTS = 'category_segments'
const CATEGORY_ATTRIBUTES = 'category_attributes'
const CATEGORIES = 'categories'
const DELIVERIES = 'deliveries'
const CARGOS = 'cargos'
const DASHBOARD = 'dashboard'
const STATION_REVENUE = 'station_revenue'
const STATIONS_REVENUE = 'stations_revenue'
const STATION_REVIEWS = 'station_reviews'
const STATIONS_REVIEWS = 'stations_reviews'
const FINALIZE_RENTAL = 'finalize_rental'
const TOP_PRODUCTS = 'top_products'
const MOST_RENTED_PRODUCTS = 'most_rented_products'
const STATION_REQUESTS = 'station_requests'
const STATIONS_REQUESTS = 'stations_requests'
const STATION_INVOICES = 'station_invoices'
const STATIONS_INVOICES = 'stations_invoices'
const ACTIVE_NODES = 'active_nodes'
const SUPPLIER_STATION_CATEGORY_SEGMENTS = 'supplier_station_category_segments'
const REMOVE_CATEGORY = 'remove_category'
const ADD_CATEGORY = 'add_category'
const SUPPLIER_STATION_CATEGORIES = 'supplier_station_categories'
const DEFAULT_CONTACTS = 'default_contacts'
const USER = 'user'
const SET_PASSWORD = 'set_password'
const REQUEST_PASSWORD_EMAIL = 'request_password_email'
const SUBSCRIPTIONS = 'notification_subscriptions/create_or_update'
const INCOMING_INVOICES = 'incoming_invoices'

// const MULTIPLE_FILTER = (prop: string, value: string | number) => `filter[${prop}][]=${value || ''}`;
const SINGLE_FILTER = (prop: string, value: string | number) => `filter[${prop}]=${value || ''}`

// SESSION - USER
export const LOGIN_WITH_SESSION_URL = `${AUTHENTICATION}/${LOGIN_SESSION}?${INCLUDES}${CONTACT}&${INCLUDES}${CONTACT}.${SUPPLIER_STATIONS}&${INCLUDES}${CONTACT}.${CONTACT_ORGANIZATIONS}`
export const LOGIN_WITH_PAYLOAD_URL = `${AUTHENTICATION}/${LOGIN}?${INCLUDES}${CONTACT}&${INCLUDES}${CONTACT}.${SUPPLIER_STATIONS}&${INCLUDES}${CONTACT}.${CONTACT_ORGANIZATIONS}`
export const LOGIN_WITH_URL_TOKEN = `${AUTHENTICATION}/${TOKEN_LOGIN}?${INCLUDES}${CONTACT}&${INCLUDES}${CONTACT}.${SUPPLIER_STATIONS}&${INCLUDES}${CONTACT}.${CONTACT_ORGANIZATIONS}`
export const LOGOUT_USER_URL = `${AUTHENTICATION}/${LOGOUT}`
export const USER_PASSWORD_URL = `${USER}/${SET_PASSWORD}`
export const REQUEST_PASSWORD_EMAIL_URL = `${USER}/${REQUEST_PASSWORD_EMAIL}`

// ORGANIZATION
export const CONTACT_ORGANIZATION_DETAIL_URL = (organization_id: string | number) =>
  `${CONTACT_ORGANIZATION_URL}/${organization_id}`
export const CONTACT_ORGANIZATION_URL = `${CONTACT_ORGANIZATIONS}`

// CONTACT
export const CONTACTS_URL = `${CONTACTS}?${INCLUDES}${SUPPLIER_STATIONS}&${INCLUDES}${CONTACT_ORGANIZATIONS}`
export const UPDATE_CONTACT_URL = (contactId: string | number) =>
  `${CONTACTS}/${contactId}?${INCLUDES}${SUPPLIER_STATIONS}&${INCLUDES}${CONTACT_ORGANIZATIONS}`
export const SEARCH_CONTACTS_URL = (q = '') => `${CONTACTS}?q=${q}`

// SUPPLIER_STATION
export const UPDATE_SUPPLIER_STATION_URL = (supplierStationId: number) =>
  `${SUPPLIER_STATIONS}/${supplierStationId}`
export const UPDATE_DEFAULT_CONTACTS_URL = (supplierStationId: number) =>
  `${SUPPLIER_STATIONS}/${supplierStationId}/update_default_contacts`
export const FETCH_SUPPLIER_STATION_CATEGORIES_URL = (supplierStationId: number) =>
  `${SUPPLIER_STATIONS}/${supplierStationId}/${CATEGORIES}/${SUPPLIER_STATION_CATEGORIES}`
export const FETCH_SUPPLIER_STATION_CONTACT_ORGANIZATIONS = (supplierStationId: number) =>
  `${SUPPLIER_STATIONS}/${supplierStationId}/${CONTACT_ORGANIZATIONS}`
export const FETCH_SUPPLIER_STATION_DEFAULT_CONTACTS = (supplierStationId: number) =>
  `${SUPPLIER_STATIONS}/${supplierStationId}/${DEFAULT_CONTACTS}`

// PRODUCT
export const SEARCH_PRODUCTS_URL = (
  category_id: string | number = '',
  supplier_station_id: string | number = '',
  q = ''
) =>
  `${PRODUCTS}/${SEARCH}?supplier_station_id=${supplier_station_id}&category_id=${category_id}&q=${q}`
export const FETCH_LAST_PRODUCT_SUGGESTIONS_URL = (
  supplier_station_id: string | number = '',
  category_segment_id: string | number = '',
  per_page: number,
  page: number
) =>
  `${PRODUCTS}/${SUGGESTIONS}?page=${page}&per_page=${per_page}&${SINGLE_FILTER(
    'supplier_station_id',
    supplier_station_id
  )}&${SINGLE_FILTER(
    'category_segment_id',
    category_segment_id
  )}&${INCLUDES}${RENTALS}&sort=-rentals.start_date`
export const CREATE_PRODUCT_URL = (supplier_station_id: string | number = '') =>
  `${PRODUCTS}?${SINGLE_FILTER('supplier_station_id', supplier_station_id)}`

// RENTAL_REQUEST
export const FETCH_RENTAL_REQUESTS_URL = (
  type: string,
  q: string,
  supplier_station_id = '',
  page: number,
  per_page: number,
  sortKey = ''
) =>
  `${RENTAL_REQUESTS}?${SINGLE_FILTER('type', type)}&${SINGLE_FILTER('q', q)}&${SINGLE_FILTER(
    'supplier_station_id',
    supplier_station_id
  )}&${INCLUDES}${RENTAL}&${INCLUDES}${SUPPLIER_STATION}&page=${page}&per_page=${per_page}&sort=${sortKey}`
export const FETCH_RENTAL_REQUEST_URL = (rentalRequestId: number) =>
  `${RENTAL_REQUESTS}/${rentalRequestId}?&${INCLUDES}${RENTAL}`
export const UPDATE_RENTAL_REQUEST_URL = (rentalRequestId: number) =>
  `${RENTAL_REQUESTS}/${rentalRequestId}?${INCLUDES}${RENTAL}&${INCLUDES}${RENTAL_REQUEST_ITEMS}`
export const RESPOND_RENTAL_REQUEST = (rentalRequestId: number) =>
  `${RENTAL_REQUESTS}/${rentalRequestId}/${RESPOND}?${INCLUDES}${RENTAL_REQUEST_ITEMS}&${INCLUDES}${RENTAL}`
export const FETCH_REQUEST_RENTAL_URL = (rentalRequestId: number) =>
  `${RENTAL_REQUESTS}/${rentalRequestId}/request_rental_show?${INCLUDES}${RENTAL}&${INCLUDES}${RENTAL_REQUEST_ITEMS}`
export const FETCH_PURCHASING_REQUEST = (purchasingRequestId: number) =>
  `purchasing_requests/${purchasingRequestId}?${INCLUDES}product_request_items&${INCLUDES}delivery_request_items&${INCLUDES}rental`
export const UPDATE_PURCHASING_REQUEST_URL = (purchasingRequestId: number) =>
  `purchasing_requests/${purchasingRequestId}?${INCLUDES}product_request_items&${INCLUDES}delivery_request_items`

// RENTAL_REQUEST ITEM
export const RENTAL_REQUEST_ITEM_DELIVERY_URL = (rentalRequestId: number) =>
  `${RENTAL_REQUESTS}/${rentalRequestId}/${DELIVERIES}?${INCLUDES}${CARGOS}`

//RENTAL
export const FINALIZE_RENTAL_URL = (rentalCode: string) =>
  `${RENTALS}/${rentalCode}/${FINALIZE_RENTAL}`

// DELIVERY ITEMS
export const FETCH_DELIVERY_ITEMS = (rentalCode: string, rental_request_id: number) =>
  `${RENTALS}/${rentalCode}/${DELIVERY_ITEMS}?rental_request_id=${rental_request_id}&${INCLUDES}${CARGOS}`

// LINE_ITEM
export const FETCH_LINE_ITEMS = (rentalCode: string, rental_request_id: number) =>
  `${RENTALS}/${rentalCode}/${LINE_ITEMS}?rental_request_id=${rental_request_id}`

// DOCUMENT
export const DOCUMENTS_URL = `${DOCUMENTS}`
export const FETCH_DOCUMENTS_URL = (
  rental_id: string | number,
  supplier_station_id: number,
  document_type?: string,
  line_item_id?: number
) => {
  return `${DOCUMENTS}?${SINGLE_FILTER('rental_id', rental_id)}&${SINGLE_FILTER(
    'supplier_station_id',
    supplier_station_id
  )}&${SINGLE_FILTER('document_type', document_type)}&${SINGLE_FILTER(
    'line_item_id',
    line_item_id
  )}`
}

// INCOMING INVOICE
export const INCOMING_INVOICES_URL = `${INCOMING_INVOICES}`
export const FETCH_INCOMING_INVOICES_URL = (rental_id: number, has_damages = false) =>
  `${INCOMING_INVOICES}?${SINGLE_FILTER('rental_id', rental_id)}&${SINGLE_FILTER(
    'has_damages',
    String(has_damages)
  )}`

export const DOCUMENT_DETAILS_URL = (document_id: string | number) => `${DOCUMENTS}/${document_id}`

// CATEGORY SEGMENTS
export const CATEGORY_SEGMENTS_URL = (categoryId: number, orderBy: string) =>
  `${CATEGORY_SEGMENTS}?${SINGLE_FILTER('category_id', categoryId)}&sort=${orderBy}`

// CATEGORY ATTRIBUTES
export const CATEGORY_ATTRIBUTES_URL = (categoryId: number) =>
  `${CATEGORY_ATTRIBUTES}?category_id=${categoryId}`

// CATEGORY
export const FETCH_CATEGORIES_URL = `${CATEGORIES}`
export const FETCH_SUB_CATEGORIES_URL = (id: number) => `${CATEGORIES}/${id}/children`

// DASHBOARD
export const FETCH_REVENUE = (supplierStationId: number) =>
  `${DASHBOARD}/${supplierStationId}/${STATION_REVENUE}`
export const FETCH_STATIONS_REVENUE = (supplierStationId: number, limit: number) =>
  `${DASHBOARD}/${supplierStationId}/${STATIONS_REVENUE}?limit=${limit}`
export const FETCH_REVIEWS = (supplierStationId: number) =>
  `${DASHBOARD}/${supplierStationId}/${STATION_REVIEWS}`
export const FETCH_STATIONS_REVIEWS = (supplierStationId: number) =>
  `${DASHBOARD}/${supplierStationId}/${STATIONS_REVIEWS}`
export const FETCH_TOP_PRODUCTS = (supplierStationId: number) =>
  `${DASHBOARD}/${supplierStationId}/${TOP_PRODUCTS}`
export const FETCH_MOST_RENTED_PRODUCTS = (supplierStationId: number) =>
  `${DASHBOARD}/${supplierStationId}/${MOST_RENTED_PRODUCTS}`
export const FETCH_REQUESTS = (supplierStationId: number) =>
  `${DASHBOARD}/${supplierStationId}/${STATION_REQUESTS}`
export const FETCH_STATIONS_REQUESTS = (supplierStationId: number) =>
  `${DASHBOARD}/${supplierStationId}/${STATIONS_REQUESTS}`
export const FETCH_INVOICES = (supplierStationId: number) =>
  `${DASHBOARD}/${supplierStationId}/${STATION_INVOICES}`
export const FETCH_STATIONS_INVOICES = (supplierStationId: number) =>
  `${DASHBOARD}/${supplierStationId}/${STATIONS_INVOICES}`

//SUPPLIER_STATION_CATEGORY_SEGMENTS
export const FETCH_SUPPLIER_STATION_CATEGORY_SEGMENTS_ACTIVE_NODES = (
  supplierStationId: number,
  categoryId: number
) =>
  `${SUPPLIER_STATIONS}/${supplierStationId}/${CATEGORIES}/${categoryId}/${SUPPLIER_STATION_CATEGORY_SEGMENTS}/${ACTIVE_NODES}`
export const REMOVE_CATEGORY_FROM_STATION = (supplierStationId: number, categoryId: number) =>
  `${SUPPLIER_STATIONS}/${supplierStationId}/${CATEGORIES}/${categoryId}/${SUPPLIER_STATION_CATEGORY_SEGMENTS}/${REMOVE_CATEGORY}`
export const ADD_CATEGORY_TO_STATION = (supplierStationId: number, categoryId: number) =>
  `${SUPPLIER_STATIONS}/${supplierStationId}/${CATEGORIES}/${categoryId}/${SUPPLIER_STATION_CATEGORY_SEGMENTS}/${ADD_CATEGORY}`
export const ADD_SUPPLIER_STATION_CATEGORY_SEGMENT = `${SUPPLIER_STATION_CATEGORY_SEGMENTS}`
export const REMOVE_SUPPLIER_STATION_CATEGORY_SEGMENT = (
  supplierStationCategorySegment: SupplierStationCategorySegment
) => `${SUPPLIER_STATION_CATEGORY_SEGMENTS}/${supplierStationCategorySegment.id}`

// SUBSCRIPTIONS
export const SUBSCRIPTIONS_URL = `${SUBSCRIPTIONS}`

export const ProductInstance = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${ProductInstance.base}${ToolBox.parameterize({
      ...ProductInstance.params,
      ...params
    })}`
  }),
  base: `${RecordType.pluralize().product_instance}`,
  params: {
    includes: [RecordType.pluralize().product_instance_booking]
  }
}

export const Rental = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${Rental.base}/${id}${ToolBox.parameterize({
        ...Rental.params,
        ...params
      })}`
    }
  },
  base: RecordType.pluralize().rental,
  params: {
    includes: ['line_items', 'delivery_items']
  }
}

export const Purchasing = {
  base: 'purchasing'
}

export const OrderRequest = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${OrderRequest.base}/${id}${ToolBox.parameterize({
        ...OrderRequest.params,
        ...params
      })}`
    }
  },
  collection: (params: CollectionArgs = {}) => ({
    index: `/${OrderRequest.base}${ToolBox.parameterize({
      ...OrderRequest.params,
      ...params
    })}`
  }),
  base: `${RecordType.pluralize().order_request}`,
  params: {
    includes: [RecordType.rental]
  }
}

export const ChangeRequest = {
  base: RecordType.pluralize().change_request,
  collection: (params: CollectionArgs = {}) => {
    return {
      index: `/${Purchasing.base}/${ChangeRequest.base}${ToolBox.parameterize({
        ...ChangeRequest.params,
        ...params
      })}`
    }
  },
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${Purchasing.base}/${ChangeRequest.base}/${id}${ToolBox.parameterize({
        ...ChangeRequest.params,
        ...params
      })}`,
      update: `/${Purchasing.base}/${ChangeRequest.base}/${id}${ToolBox.parameterize({
        ...ChangeRequest.params,
        ...params
      })}`
    }
  },
  params: {
    includes: ['product_request_items', 'delivery_request_items']
  }
}

export const RentalLineItem = {
  collection: (params: CollectionArgs & { rental_id?: number } = {}) => ({
    index: `/${RentalLineItem.base}/${params.rental_id}/line_items/${ToolBox.parameterize({
      ...RentalLineItem.params,
      ...params
    })}`
  }),
  base: `${RecordType.pluralize().rental}`,
  params: {}
}

export const PurchasingRequest = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${PurchasingRequest.base}/${id}${ToolBox.parameterize({
        ...PurchasingRequest.params,
        ...params
      })}`
    }
  },
  collection: (params: CollectionArgs = {}) => ({
    index: `/${PurchasingRequest.base}${ToolBox.parameterize({
      ...{
        includes: [RecordType.rental]
      },
      ...params
    })}`
  }),
  base: `${RecordType.pluralize().purchasing_request}`,
  params: {
    includes: [
      RecordType.rental,
      RecordType.pluralize().delivery_request_item,
      RecordType.pluralize().product_request_item,
      'rental.line_items'
    ]
  }
}
