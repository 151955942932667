import Base from 'app/redux/normalizers/Base'

interface IBaseNormalizer {
  includes?: string[]
}

export function BaseNormalizer<T extends { id: number }>({
  includes = []
}: IBaseNormalizer = {}): INormalizer<T> {
  return {
    index: ({ data, included }: ICollectionResponse<T>): T[] => {
      const { mapCollectionWithIncludes, normalizeIncluded } = Base
      return mapCollectionWithIncludes(data as never, normalizeIncluded(included, includes))
    },
    show: ({ data, included }: IMemberResponse<T>): T => {
      const { mapMemberWithIncludes, normalizeIncluded } = Base
      return mapMemberWithIncludes(data as never, normalizeIncluded(included, includes))
    },
    create: ({ data, included }: IMemberResponse<T>): T => {
      const { mapMemberWithIncludes, normalizeIncluded } = Base
      return mapMemberWithIncludes(data as never, normalizeIncluded(included, includes))
    },
    update: ({ data, included }: IMemberResponse<T>): T => {
      const { mapMemberWithIncludes, normalizeIncluded } = Base
      return mapMemberWithIncludes(data as never, normalizeIncluded(included, includes))
    },
    destroy: (data: IMemberResponse<T>): T => {
      return Base.mapAttributesOfMember(data.data as never)
    }
  }
}
