import * as React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { Routes } from '../constants/Routes'

interface Props {
  loggedIn?: boolean
}

const PrivateRoute: React.FC<Props> = ({ children, loggedIn }) => {
  return loggedIn ? <>{children}</> : <Navigate to={Routes.Authentication.collection().login} />
}

const mapStateToProps = (state: AppState) => {
  const { loggedIn } = state.user
  return {
    loggedIn
  }
}
export default connect<{ loggedIn: boolean }, unknown, unknown>(mapStateToProps)(PrivateRoute)
