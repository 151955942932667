import { Button, notification } from 'antd'

const openNotification = (
  message: string,
  description: string,
  confirmText: string,
  onConfirm: () => void
) => {
  return notification.open({
    className: 'notification-request',
    key: 'notification-request',
    placement: 'topRight',
    top: 84,
    duration: null,
    message: <h5 className="my-0">{message}</h5>,
    btn: (
      <Button
        onClick={() => {
          notification.close('notification-request')
          onConfirm()
        }}
        type="primary">
        {confirmText}
      </Button>
    ),
    description: <span>{description}</span>
  })
}

export default openNotification
