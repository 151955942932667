import { Layout } from 'antd'
import { Suspense } from 'react'
import * as React from 'react'
import Header from 'app/components/UI-Elements/Layout/AppHeader'
import ProfileSidebar from 'app/components/Core/User/ProfileSidebar'
import Sider from 'app/components/UI-Elements/Navigation/Sider/Sider'
import { toggleProfileSidebar } from 'app/redux/actions/AppActions'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatchable } from '../../constants/GlobalTypes'

interface Props {
  children: React.ReactNode
}

const PageScaffold: React.FC<Props> = ({ children }) => {
  const { loggedIn, contact } = useSelector<AppState, UserReducerState>((state) => state.user)
  const dispatch = useDispatch<Dispatchable>()

  const toggleProfileSideBar = (val: boolean): void => {
    dispatch(toggleProfileSidebar(val))
  }

  return (
    <Layout>
      <Header
        currentContact={contact}
        toggleProfileSidebar={toggleProfileSideBar}
        loggedIn={loggedIn}
      />
      <Layout hasSider={true}>
        <Sider />
        <Layout.Content className="page-content" id="main-wrapper">
          <Suspense fallback={null}>{children}</Suspense>
          <ProfileSidebar />
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default PageScaffold
