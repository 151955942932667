/* eslint-disable no-prototype-builtins */
import { PaginatedResponse } from 'app/constants/GlobalTypes'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { notify } from 'app/lib/Notify'
import {
  CreateQueryParams,
  DestroyQueryParams,
  IndexQueryParams,
  QueryOptions,
  ShowQueryParams,
  UpdateQueryParams
} from 'app/lib/typing'
import reducerData from 'app/redux/in_reducer.json'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const { in_reducer } = reducerData

const queryResolver = async (recordType: string, action: string) => {
  const resolver = await import(`app/redux/actions/${recordType}`)
  if (!resolver[recordType][action]) {
    throw Error(
      `Query resolver cannot find <<${action}>> method in app/redux/actions/${recordType}.ts, method <<${action}>> must be implemented.`
    )
  }
  return resolver[recordType][action]
}

function useQuery<T>(recordType: string, action: string, options?: QueryOptions<T>) {
  const { constantize } = ToolBox.useString()
  const state = useSelector<any, any>((app: any) => app?.[recordType])
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<T>(options?.initialResponse)
  const [errors, setErrors] = useState<T>(null)
  const { smartTranslation } = ToolBox.useString()
  const dispatch = useDispatch()

  useEffect(() => {
    if (state?.[action]) {
      if (response?.hasOwnProperty('data')) {
        setResponse((response) => ({ ...response, ...{ data: Object.values(state[action]) } }))
      } else {
        setResponse({ ...response, ...(Object.values(state[action])[0] as T) })
      }
    }
  }, [state?.hash])

  const execute = async (params: MemberArgs | CollectionArgs | T) => {
    try {
      setLoading(true)
      const call = await queryResolver(constantize(recordType), action)
      const response = await call(params)
      setResponse(response)
      if (in_reducer.includes(recordType))
        dispatch({
          data: response.data || [response],
          type: recordType,
          actionType: action
        })
      if (['create', 'update', 'destroy'].includes(action))
        notify({
          message: smartTranslation({
            id: `form.${options.visualRecordType}.message.successful.${action}`,
            recordType: options.visualRecordType
          }),
          intent: 'success'
        })
      return response
    } catch (e) {
      console.log(e)
      notify({ message: e, intent: 'danger' })
      setErrors(e)
      throw e
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, execute, errors }
}

export function useIndex<T>(
  recordType: string,
  visualRecordType: string = recordType
): IndexQueryParams<T> {
  const {
    loading,
    response,
    execute: call
  } = useQuery<PaginatedResponse<T>>(recordType, 'index', {
    initialResponse: { data: [] },
    visualRecordType
  })
  const execute = async (params?: CollectionArgs) => {
    await call(params)
  }
  return { loading, response, execute }
}

export function useCreate<T>(
  recordType: string,
  visualRecordType: string = recordType
): CreateQueryParams<T> {
  const { loading, execute: call, errors } = useQuery<T>(recordType, 'create', { visualRecordType })
  const execute = async (params: T) => {
    return await call(params)
  }
  return { loading, execute, errors }
}

export function useUpdate<T>(
  recordType: string,
  visualRecordType: string = recordType
): UpdateQueryParams<T> {
  const { loading, execute: call, errors } = useQuery<T>(recordType, 'update', { visualRecordType })
  const execute = async (params: T) => {
    return await call(params)
  }
  return { loading, execute, errors }
}

export function useShow<T>(
  recordType: string,
  visualRecordType: string = recordType
): ShowQueryParams<T> {
  const { loading, response, execute: call } = useQuery<T>(recordType, 'show', { visualRecordType })
  const execute = async (params: MemberArgs) => {
    return await call(params)
  }
  return { loading, response, execute }
}

export function useDestroy<T>(
  recordType: string,
  visualRecordType: string = recordType
): DestroyQueryParams<T> {
  const {
    loading,
    response,
    execute: call
  } = useQuery<T>(recordType, 'destroy', { visualRecordType })
  const execute = async (params: MemberArgs) => {
    return await call(params)
  }
  return { loading, response, execute }
}
