import { Button, Col, Result, Row } from 'antd'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import { Routes } from 'app/constants/Routes'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

const RentalRequestError: React.FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <KCard>
          <Result
            status="warning"
            title={formatMessage({ id: 'rental_request.error.title' })}
            extra={
              <Button
                type="primary"
                onClick={() =>
                  navigate(Routes.PurchasingRequest.collection().index, { replace: true })
                }>
                {formatMessage({ id: 'rental_request.error.button' })}
              </Button>
            }
          />
        </KCard>
      </Col>
    </Row>
  )
}

export default RentalRequestError
