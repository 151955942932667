import '@ant-design/compatible/assets/index.css'

import { Form } from '@ant-design/compatible'
import { WrappedFormUtils } from '@ant-design/compatible/es/form/Form'
import { ValidationRule } from '@ant-design/compatible/es/form/Form'
import { ColProps } from 'antd/es/col'
import { ReactNode } from 'react'
import * as React from 'react'

interface FormItemWithLabelProps {
  children: ReactNode
  hasFeedback?: boolean
  className?: string
  label?: string
  id: string
  required?: boolean
  formInstance?: WrappedFormUtils
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: string | number | null | undefined | Array<string> | any
  errorMessage?: string
  fieldType?: string
  typeErrorMessage?: string
  extra?: string
  wrapperCol?: ColProps
  labelCol?: ColProps
  extraRules?: ValidationRule[]
  disableWhiteSpace?: boolean
}

const FormItem: React.FC<FormItemWithLabelProps> = ({
  disableWhiteSpace = true,
  labelCol = { span: 24 },
  wrapperCol,
  typeErrorMessage,
  extra,
  extraRules = [],
  fieldType,
  className,
  hasFeedback = true,
  label,
  id,
  errorMessage,
  required = false,
  formInstance,
  initialValue,
  children,
  ...others
}) => {
  return (
    <Form.Item
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      hasFeedback={hasFeedback}
      className={label ? `${className} mb-0` : className}
      label={label}
      extra={extra}
      {...others}>
      {formInstance.getFieldDecorator(id, {
        rules: [
          {
            required: required,
            whitespace: disableWhiteSpace,
            message: errorMessage,
            type: fieldType
          },
          {
            type: fieldType,
            message: typeErrorMessage
          },
          ...extraRules
        ],
        initialValue: initialValue
      })(children)}
    </Form.Item>
  )
}

export default FormItem
