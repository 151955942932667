export const ColorsPalette = {
  secondary: '#fd6565',
  textColor: '#21393f',
  textColorLight: '#cfcfcf',
  whiteShade: '#f9f9f9',
  primary: '#2a5b66',
  secondaryTransparent: '#FD9393',
  primaryLight: '#36707a',
  primaryTransparent: 'rgba(54,112,122,0.76)',
  gray100: '#F9F9F9',
  gray120: '#F2F2F2',
  gray150: '#EFF1F1',
  grayLight: '#949495',
  white: '#ffffff',
  headingsSmall: '#95a2a8',
  RentalPreviewSidebarColor: '#e8e8e8',
  textColorGray: '#949495',
  borderColor: '#e8e8e8',
  info: '#91d5ff',
  infoTransparent: '#e6f7ff'
}
