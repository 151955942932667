import { WrappedFormUtils } from '@ant-design/compatible/es/form/Form'
import { Col, Row } from 'antd'
import Checkbox from 'antd/es/checkbox/Checkbox'
import Upload from 'app/components/Core/Document/Upload'
import KCard from 'app/components/UI-Elements/DataDisplay/KCard'
import FormItem from 'app/components/UI-Elements/Forms/FormItem'
import { fetchIncomingInvoices, uploadIncomingInvoice } from 'app/redux/actions/IncomingInvoice'
import IntlHtmlMessages from 'app/util/IntlHtmlMessages'
import IntlStringMessages from 'app/util/IntlStringMessages'
import { useState } from 'react'
import * as React from 'react'
import { useIntl } from 'react-intl'

import Message from '../../UI-Elements/Feedback/messages/Message'

interface Props {
  rental_id: number
  form?: WrappedFormUtils
  supplier_station_id: number
  has_damages?: boolean
  required?: boolean
}

const InvoiceUpload: React.FC<Props> = ({
  rental_id,
  supplier_station_id,
  form,
  required,
  has_damages = false
}) => {
  const { formatMessage } = useIntl()
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [atLeasOneFileUploaded, setAtLeasOneFileUploaded] = useState<boolean>(undefined)

  const handleUpload = (file: File) => {
    return uploadIncomingInvoice({
      file,
      rental_id,
      supplier_station_id,
      has_damages
    }).then((data) => {
      form?.setFieldsValue({ damage_invoice_uploaded: true })
      setAtLeasOneFileUploaded(true)
      return data
    })
  }

  const handleFetchDocuments = (): Promise<IIncomingInvoice[]> => {
    return fetchIncomingInvoices(rental_id, has_damages).then((data) => {
      setAtLeasOneFileUploaded(data.length > 0 ? true : undefined)
      return data
    })
  }

  const getTitle = (): React.ReactNode => {
    if (has_damages) {
      return <IntlStringMessages id="invoice.upload.with.damage.title" />
    } else {
      return <IntlStringMessages id="invoice.upload.title" />
    }
  }

  const getDescription = (): React.ReactNode => {
    if (has_damages) {
      return <IntlHtmlMessages id="invoice.damage.upload.description" />
    } else {
      return <IntlStringMessages id="invoice.upload.description" />
    }
  }

  const getConfirmationMessage = (): React.ReactNode => {
    return (
      <Message closable showIcon onClose={() => setShowSuccess(false)} type="success">
        <IntlHtmlMessages id="rental-show-invoice-upload-confirmation-message" />
      </Message>
    )
  }

  return (
    <KCard title={getTitle()}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <span>{getDescription()}</span>
        </Col>
        {showSuccess && !has_damages && <Col span={24}>{getConfirmationMessage()}</Col>}
        <Col span={24}>
          {required && (
            <FormItem
              id="damage_invoice_uploaded"
              className="mb-0"
              formInstance={form}
              fieldType="boolean"
              initialValue={atLeasOneFileUploaded}
              hasFeedback={false}
              required
              errorMessage={formatMessage({ id: 'input.validation.required' })}>
              <Checkbox className="d-none" />
            </FormItem>
          )}
          <Upload<IIncomingInvoice>
            fetchDocuments={handleFetchDocuments}
            uploadDocument={handleUpload}
            onUploadSuccess={() => setShowSuccess(true)}
            showRemoveIcon={false}>
            <IntlHtmlMessages id="upload-default-description" />
          </Upload>
        </Col>
      </Row>
    </KCard>
  )
}

export default InvoiceUpload
