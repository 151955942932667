import { Spin } from 'antd'
import CommonService from 'app/services/CommonService'
import ReducerService from 'app/services/ReducerService'
import { useEffect, useState } from 'react'
import * as React from 'react'
import { useIntl } from 'react-intl'
import FileUploadDragger from 'app/components/UI-Elements/Controls/inputs/FileUploadDragger'
import { UploadFile } from 'antd/lib/upload/interface'
import { DocumentUploadProps } from 'app/constants/GlobalTypes'

export interface UploadProps {
  children?: string | React.ReactNode
  loading?: boolean
}

interface Props<T = IDocument> extends UploadProps {
  fetchDocuments: () => Promise<T[]>
  uploadDocument: (file: File) => Promise<T>
  deleteDocument?: (file: T | UploadFile<T>) => Promise<void>
  onChange?: (documents: T[]) => void
  onUploadSuccess?: (documents: T[]) => void
  showRemoveIcon?: boolean
}

function Upload<T extends DocumentUploadProps>({
  fetchDocuments,
  onChange,
  onUploadSuccess,
  uploadDocument,
  children,
  deleteDocument,
  showRemoveIcon
}: Props<T>) {
  const intl = useIntl()

  const [documents, setDocuments] = useState<T[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    fetchDocuments()
      .then((response: T[]) => {
        setDocuments(response)
        onChange && onChange(response)
      })
      .catch(CommonService.openSnackbar)
      .finally(() => setLoading(false))
  }, [])

  const handleUpload = (file: File): void => {
    setLoading(true)
    uploadDocument(file)
      .then((response: T) => {
        const allDocuments = [...documents, response]
        setDocuments(allDocuments)
        onChange && onChange(allDocuments)
        if (onUploadSuccess) {
          onUploadSuccess(allDocuments)
        } else {
          CommonService.openSnackbar(
            intl.formatMessage({ id: 'document.upload.success' }),
            'success'
          )
        }
      })
      .catch(CommonService.openSnackbar)
      .finally(() => setLoading(false))
  }

  const handleRemove = (file: UploadFile<T>) => {
    setLoading(true)
    deleteDocument(file)
      .then(() => {
        CommonService.openSnackbar(intl.formatMessage({ id: 'document.delete.success' }), 'success')
        const allDocuments = ReducerService.removeItemFromArray(documents, file)
        setDocuments(allDocuments)
        onChange && onChange(allDocuments)
      })
      .catch(CommonService.openSnackbar)
      .finally(() => setLoading(false))
  }

  return (
    <Spin spinning={loading}>
      <FileUploadDragger<T>
        handleUpload={handleUpload}
        handleRemove={handleRemove}
        fileList={documents}
        showRemoveIcon={showRemoveIcon}>
        {children}
      </FileUploadDragger>
    </Spin>
  )
}

export default Upload
