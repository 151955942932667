/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AppDispatch } from 'app/../index'
import { RENTAL_REQUEST } from 'app/constants/ActionTypes'
import { PromisedDispatch } from 'app/constants/GlobalTypes'
import { FETCH_PURCHASING_REQUEST, UPDATE_PURCHASING_REQUEST_URL } from 'app/constants/Url'
import { PurchasingRequest as Resource } from 'app/constants/Url'
import Api from 'app/lib/api'
import { parseError } from 'app/lib/handler'
import Base from 'app/redux/normalizers/Base'
import { pick } from 'lodash'

import { BaseNormalizer } from '../normalizers/BaseNormalizer'
import PurchasingRequestNormalizer from '../reducers/normalizers/PurchasingRequest'
import { BaseActions } from './Base'

export const fetchPurchasingRequest = (
  purchasingRequestId: number
): PromisedDispatch<IRentalRequest> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.get<MemberSerializerResponse<IPurchasingRequest>>(
        FETCH_PURCHASING_REQUEST(purchasingRequestId)
      )
        .then((response) => {
          const payload = PurchasingRequestNormalizer.normalize(response.data)
          dispatch({
            type: RENTAL_REQUEST.FETCH_PURCHASING_REQUEST,
            payload
          })
          resolve(payload)
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

export const updatePurchasingRequest = (
  purchasingRequestId: number,
  payload: unknown
): PromisedDispatch<IPurchasingRequest> => {
  return (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      Api.put<IPurchasingRequest>(UPDATE_PURCHASING_REQUEST_URL(purchasingRequestId), payload)
        .then((response) => {
          const payload = PurchasingRequestNormalizer.normalize(response.data)
          dispatch({
            type: RENTAL_REQUEST.UPDATE_RENTAL_REQUEST,
            payload
          })
          resolve(payload)
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}

const includes = ['product_request_item', 'delivery_request_item', 'rental']

export const RequestNormalizer = {
  ...BaseNormalizer<IPurchasingRequest>({
    includes
  }),
  show: ({ data, included }: IMemberResponse<IPurchasingRequest>): IPurchasingRequest => {
    const { mapMemberWithIncludes, normalizeIncluded } = Base
    const normalizedData: IPurchasingRequest = mapMemberWithIncludes(
      data as never,
      normalizeIncluded(included, includes)
    )

    const deleted_product_request_items: IProductRequestItem[] = (
      data.relationships.deleted_product_request_items.data as Array<{ id: string }>
    )
      //@ts-ignore
      .map((item) => included.find((i) => i.id === item.id)?.attributes as IProductRequestItem)
      .filter(Boolean)

    return {
      ...normalizedData,
      deleted_product_request_items,
      rental: {
        ...normalizedData.rental,
        // @ts-ignore
        line_items: included
          .filter((response) => response.type === 'line_item')
          .map((i) => i.attributes)
      }
    }
  }
}

export const PurchasingRequest = pick(
  BaseActions({
    normalizer: RequestNormalizer,
    resource: Resource
  }),
  'show',
  'index'
)
