import { Menu } from 'antd'
import * as React from 'react'

const { Item } = Menu

type Props = {
  icon?: React.ReactNode
  children: React.ReactNode
  disabled?: boolean
}

const MenuItem: React.FC<Props> = ({ disabled = false, icon, children, ...others }) => {
  return (
    <Item disabled={disabled} {...others}>
      {icon}
      <span>{children}</span>
    </Item>
  )
}

export default MenuItem
