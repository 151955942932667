import { FLEET_REVIEW } from '../../constants/ActionTypes'

const initialState: FleetReviewReducerState = {
  selectedSupplierStation: null,
  categories: [],
  stationCategories: [],
  targetKeys: []
}

const dashboard = (state: FleetReviewReducerState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case FLEET_REVIEW.SET_SELECTED_SUPPLIER_STATION:
      return {
        ...initialState,
        selectedSupplierStation: action.payload.selectedSupplierStation
      }
    case FLEET_REVIEW.FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }
    case FLEET_REVIEW.FETCH_STATION_CATEGORIES:
      return {
        ...state,
        stationCategories: action.payload,
        categories: state.categories.sort(
          (category) => -action.payload.map((c: ICategory) => c.id).includes(category.id)
        ),
        targetKeys: action.payload.map((category: ICategory) => category.id.toString())
      }
    case FLEET_REVIEW.SET_TARGET_KEYS:
      return {
        ...state,
        targetKeys: action.payload.targetKeys
      }
    default:
      return state
  }
}

export default dashboard
