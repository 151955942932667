import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import EnvironmentService from '../services/EnvironmentService'

const DEV_ENVIRONMENT = EnvironmentService.isTest() || EnvironmentService.isDevelopment()

export const BUGSNAG = Bugsnag.start({
  apiKey: DEV_ENVIRONMENT
    ? '3bb1e67d88924ae57ef97f6ffc1e4715' // this is just a random id
    : EnvironmentService.getEnvValue('BUGSNAG_KEY'),
  releaseStage: process.env.REACT_APP_ENV,
  onError: () => {
    return !DEV_ENVIRONMENT
  },
  plugins: [new BugsnagPluginReact()]
})
